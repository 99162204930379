import { useMemo } from "react";
import InfoTooltip from "../../AnalyticsSections/AnalyticsInfoTooltip";
import Tooltip from "../../HelpTooltip";
import Icon from '../../../assets/icons/SvgComponent';
import { formatNumberWithCommas } from "../../../utils/common";
import AnalyticsTable from "../../AnalyticsSections/AnalyticsTable";
import moment from "moment";

interface IProps {
  data?: any;
  isLoading?: boolean;
}



const SemrushBacklinks = ({
  data,
  isLoading
}: IProps) => {
  const backlinksResponse = data ?? [];
  
  const backlinksReportData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Source Url',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Source Url' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[20vw] h-fit pl-4">
                <div>Source Url</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Source Url' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[20vw] h-fit pl-4">
                <div>Source Url</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Source Url' || headerId === 'Source Url') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[20vw] h-fit pl-4">
                <div>Source Url</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'source_url',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[180px] sm:w-[20vw] pl-4 h-fit">
            <Tooltip content={props.getValue()} position="right">
              <div className="truncate w-[150px] sm:max-w-[20vw] overflow-hidden dark:text-white">
                {props.getValue()}
              </div>
            </Tooltip>
          </div>
        ),
      },
      {
        id: 'Target Url',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Target Url' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[16vw] h-fit">
                <div>Target Url</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Target Url' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[16vw] h-fit">
                <div>Target Url</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Target Url' || headerId === 'Target Url') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[16vw] h-fit">
                <div>Target Url</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'target_url',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[180px] sm:w-[16vw] h-fit dark:text-white">
            <Tooltip content={props.getValue()} position="right">
              <div className="truncate w-[150px] sm:max-w-[16vw] overflow-hidden">
                {props.getValue()}
              </div>
            </Tooltip>
          </div>
        ),
      },
      {
        id: 'Anchor',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Anchor' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Anchor</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Anchor' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Anchor</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Anchor' || headerId === 'Anchor') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Anchor</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'anchor',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[12vw] h-fit dark:text-white">
            <Tooltip content={props.getValue()} position="right">
              <div className="truncate w-[150px] sm:max-w-[12vw] overflow-hidden">
                {props.getValue()}
              </div>
            </Tooltip>
          </div>
        ),
      },

      {
        id: 'Page Score',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Page Score' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Page Score</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Page Score' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Page Score</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Page Score' || headerId === 'Page Score') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Page Score</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'page_ascore',
        cell: (props: any) => {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit dark:text-white">
              <div className="w-fit">
                {formatNumberWithCommas(props.getValue())}
              </div>
            </div>
          );
        },
      },
      {
        id: 'Last Seen',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Last Seen' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Last Seen</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Last Seen' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Last Seen</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Last Seen' || headerId === 'Last Seen') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Last Seen</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'last_seen',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit dark:text-white">
            {moment(props.getValue()).fromNow() === 'Invalid date'
              ? 'N/A'
              : moment(props.getValue()).fromNow()}
          </div>
        ),
      },
    ];

    const data = (backlinksResponse || [])?.map((resource: any) => {
      return {
        source_url: resource?.source_url ? resource?.source_url : 'N/A',
        target_url: resource?.target_url ? resource?.target_url : 'N/A',
        anchor: resource?.anchor ? resource?.anchor : 'N/A',
        page_ascore: Number(resource?.page_ascore),
        last_seen: resource?.last_seen,
      };
    });

    return { tableHeader, data };
  }, [backlinksResponse]);

  return (
    <div className="relative">
      <AnalyticsTable
        data={backlinksReportData?.data ? backlinksReportData?.data : []}
        columns={backlinksReportData?.tableHeader}
        loading={isLoading}
        totalDocs={
          backlinksReportData?.data ? backlinksReportData?.data.length : 'N/A'
        }
        sortByColumnId="Page Score"
        pagination={true}
        // error={semrushLinks.error}
        // retryMethod={retryFetchASemrushLinks}
      />
    </div>
  )
}


export default SemrushBacklinks;