import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Responsive, WidthProvider } from 'react-grid-layout';
import _ from 'lodash';
import moment from 'moment';

import ToolTipV2 from '../../TooltipV2';
import Icon from '../../../assets/icons/SvgComponent';

import GridItem from './GridItem';
import CustomModal from '../../CustomModal';
import WidgetSettings from '../WidgetSettings';
import CategorySelection from '../Modals';
import { 
  addChart, 
  addNewColumn, 
  adjustResponsiveLayoutsTwo, 
  emptyLayoutCharts, 
  generateGridLayout, 
  generateLayout, 
  getInitialLayout, 
  itemsObject, 
  uniqueSecond 
} from '../constants';
import EmptyGridItem from './EmptyGridItem';
import useDashboardStore from '../../../store/useDashboardStore';

import useClientStore from '../../../store/useClientStore';
import useAuthStore from '../../../store/useAuthStore';
import Tooltip from '../../HelpTooltip';
import DeleteSectionConfirmation from './deleteSectionConfirmation';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface IProps {
  dashboardPageId: string;
  title: string;
  order: number;
  dashboard?: any;
  viewType?: string;
  startDate?: string;
  endDate?: string;
}
const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 320 }
const columns = {lg: 12, md: 12, sm: 12, xs: 12, xxs: 12}
// { lg: 4, md: 3, sm: 2, xs: 1 };

// Dashboard pages
const DynamicPage = ({
  dashboardPageId,
  title,
  order,
  dashboard,
  viewType = 'view',
  startDate,
  endDate,
}: IProps) => {
  const { selectedClient } = useClientStore((state) => state);
  const { user } = useAuthStore((state) => state);
  const {
    sections,
    graphData,
    dashboard_template_id,
    isGraphDataLoading,
    fetchDashboardGraph,
    createDashboardSection,
    createDashboardSectionViaItem,
    updateDashboard,
    updateDashboardLayout,
    updateDashboardPage,
    deleteSection,
    serviceLine
  } = useDashboardStore((state) => state);

  const [openWidget, setOpenWidget] = useState(false);
  const [error, setError] = useState({});
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);

  const [sectionTitle, setSectionTitle] = useState(title);
  const [isEditingSectionTitle, setIsEditingSectionTitle] = useState(false);

  const [dynamicDashboardData, setDynamicDashboardData] = useState([]);
  // const [dashboardId, setDashboardId] = useState('');

  const [layouts, setLayouts] = useState<any>(getInitialLayout());
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>('lg');

  const [isConfirmModal, setConfirmModal] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  // utility function to get formatted date from storage
  const getFormattedDateFromStorage = (key) => {
    const rawDate = localStorage.getItem(key);
    const json = rawDate ? JSON.parse(rawDate) : null;
    return rawDate ? moment(json).format('YYYY-MM-DD') : null;
  };

  const handleFetchGraphs = () => {
    const data = {
      clientId: selectedClient?.id,
      startDate: startDate 
        ? moment(startDate).subtract(1, 'year').format('YYYY-MM-DD') 
        : moment(getFormattedDateFromStorage('seoStartDate')).subtract(1, 'year').format('YYYY-MM-DD'),
      endDate: endDate ? endDate : getFormattedDateFromStorage('seoEndDate')
    };
    fetchDashboardGraph(dashboardPageId, data);
  };

  useEffect(() => {
    handleFetchGraphs();
  }, [sections, dashboardPageId, selectedClient?.id, startDate, endDate]);

  useEffect(() => {
    if (dashboard?.layout_charts?.length > 0 && dashboardPageId) {
      
      // layout from db
      const genLayout = dashboard?.layout && JSON.parse(dashboard?.layout);
      
      // Get last item key in layout charts and increment it
      const lastItem = dashboard?.layout_charts.at(-1);
      
      const trimChart = dashboard?.layout_charts?.filter( f => f.title);
      const emptyTitleCount = dashboard?.layout_charts.filter(item => item.title.trim() === "").length;

      let chartData = [...trimChart, addChart(Number(lastItem?.key) + 1)]
      // console.log(dashboard?.layout_group," - count : ", emptyTitleCount);
      if(emptyTitleCount > 4) {
        chartData = [...dashboard?.layout_charts]
      }

      setDynamicDashboardData(
        viewType === "edit" 
          ? [...chartData]
          : trimChart
      );
      
      if(viewType === "edit") {
        const sectionLayout = 
        Object.keys(genLayout)?.length > 0 
        ? genLayout
        : generateGridLayout();

        // Filter layout and remove duplicates
        const newData = uniqueSecond(dashboard?.layout_charts,sectionLayout.lg);
        // add new empty column
        const addedCol:any = addNewColumn(generateLayout(newData), 12, Number(lastItem?.key));
        const generatedLayout = adjustResponsiveLayoutsTwo(addedCol);

        // console.log(dashboard?.layout_group,": ", generatedLayout)
        // console.log(dashboard?.layout_group,": ", dynamicDashboardData)
        setLayouts(generatedLayout);

      } else {
        const sectionLayout = 
        Object.keys(genLayout)?.length > 0 
          ? genLayout 
          : generateLayout(generateGridLayout(dashboard?.layout_charts?.length));

        const generatedLayout = adjustResponsiveLayoutsTwo(sectionLayout);
        
        setLayouts(generatedLayout);
      }
      
      setSectionTitle(title);
    } else {
      setLayouts(generateGridLayout(5));
      setDynamicDashboardData(emptyLayoutCharts);
    }
  }, [dashboard]);

  const [toolbox, setToolbox] = useState<{ [index: string]: any[] }>({
    lg: [],
  });

  const onBreakpointChange = (breakpoint: any) => {
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };

  const handleDragStart = (
    layout,
    oldItem,
    newItem,
    placeholder,
    e,
    element
  ) => {
    setIsDragging(true);
  };

  // This will track when dragging stops
  const handleDragStop = (
    layout,
    oldItem,
    newItem,
    placeholder,
    e,
    element
  ) => {
    setLayouts((prevLayouts) => {
      const updatedLayouts = {
        ...prevLayouts,
        [currentBreakpoint]: layout,
      };
      // TODO: save this when save button at header is click
      debouncedUpdateLayout(dashboardPageId, dashboard?.id, {
        layout: JSON.stringify(updatedLayouts),
      });
      return updatedLayouts;
    });
    setIsDragging(false);
  };

  // This will track when resizing stops
  const onResizeStop = (layout) => {
    setLayouts((prevLayouts) => {
      const updatedLayouts = {
        ...prevLayouts,
        [currentBreakpoint]: layout,
      };
      // TODO: save this when save button at header is click
      debouncedUpdateLayout(dashboardPageId, dashboard?.id, {
        layout: JSON.stringify(updatedLayouts),
      });
      return updatedLayouts;            
    });
  };

  const debouncedUpdateLayout = useCallback(
    debounce((secitonId, dashboardId, data) => {
      updateDashboardLayout(secitonId, dashboardId, data);
    }, 1000),
    [updateDashboardLayout]
  );

  const onLayoutChange = useCallback((currentLayout: any, allLayouts?: any) => {
    const newLayouts = allLayouts;
    let changeItem
    if (changeItem) {
      ["lg", "md", "sm", "xs", "xxs"].forEach(size => {
        if (size !== currentBreakpoint) {
          const items = newLayouts[size] || [];
          const index = items.findIndex(
            ({ i }) => i === changeItem.i
          );
          if (index >= 0) {
            newLayouts[size][index] = { ...changeItem };
          } else {
            (newLayouts[size] || []).push({ ...changeItem });
          }
        }
      });
    }
    changeItem = null;
    setLayouts(newLayouts)
    debouncedUpdateLayout(dashboardPageId, dashboard?.id,{
      layout: JSON.stringify(newLayouts)
    });
  },[]);


  const renderGridItem = () => {
    const dataToObj = dynamicDashboardData?.length > 0 && itemsObject(dynamicDashboardData);
    
    // Render existing grid items
    const gridItems = layouts[currentBreakpoint]?.map((item,index) => {
      if(dataToObj[item.i] === undefined) return null;
      
      return (
        <div
          className={`${item.i} sample-class ${dataToObj[item.i]?.display_config?.chartType}`}
          key={item.i}
          onClick={(e) => e.stopPropagation()}
          style={{
            height: dataToObj[item.i]?.display_config?.chartType === "table" ? 500 : 'auto'
          }}
        >
          {!dataToObj[item.i]?.title ? (
            <EmptyGridItem
              layoutData={dataToObj[item.i]}
              setOpenWidget={setOpenWidget}
              setSelectedItem={setSelectedItem}
              viewType={viewType}
            />
          ) : (
            <GridItem
              key={`${item.i}-${index}`}
              layoutData={dataToObj[item.i]}
              // layoutData={graphData[dynamicDashboardData[item.i]?.id]}
              setOpenWidget={setOpenWidget}
              setSelectedItem={setSelectedItem}
              viewType={viewType}
              isGraphDataLoading={isGraphDataLoading}
            />
          )}
        </div>
      );
    });
    return gridItems;
  };

  const handleSaveItem = async (data) => {
    if (!data.data_config.metrics && !data.data_config.source) {
      setError({
        error: true,
        message: 'Metrics and Source are required.',
      });
      return false;
    }
    console.log('handleSaveItem: ', data);

    const updatedItems = dynamicDashboardData.map((item) =>
      item.id === data.id ? { ...item, ...data } : item
    );

    setDynamicDashboardData(updatedItems.filter(f => f.title));

    const validKeys = new Set(dynamicDashboardData.map(item => item.key));
    const updatedLayout = {
      lg: layouts.lg.filter(item => validKeys.has(item.i)),
      md: layouts.md.filter(item => validKeys.has(item.i)),
      sm: layouts.sm.filter(item => validKeys.has(item.i)),
      xs: layouts.xs.filter(item => validKeys.has(item.i)),
      xxs: layouts.xxs.filter(item => validKeys.has(item.i)),
    };
    
    // by default full width for the table
    if(data.display_config.chartType === "table") {
      Object.keys(updatedLayout).map(breakpoint => {
        updatedLayout[breakpoint].map((item: any ) => {
          if (data.key === item.i) {
            item.w = 12; // Update width
          }
        });
      });  
    }
    // update value chartype height
    if(data.display_config.chartType === "value") {
      Object.keys(updatedLayout).map(breakpoint => {
        updatedLayout[breakpoint].map((item: any ) => {
          if (data.key === item.i) {
            item.h = 1; // Update height
          }
        });
      });  
    }
    
    let dashboardData = {
      layout_group: title.replace(/\s+/g, '-').toLowerCase() || '',
      layout: JSON.stringify(updatedLayout),
      layout_charts: updatedItems.filter(f => f.data_config.metrics && f.data_config.source), // filter data that has source and metrics
    };

    const lastItem = updatedItems.at(-1);

    {/** Edit */}
    if (dashboard?.id) {
      const editData = {
        ...dashboardData,
        status: 'active',
        updated_by: user.id
      };
      
      updateDashboard(dashboard?.id, editData);
      
      const newDashboardData = [...updatedItems, addChart(Number(lastItem?.key) + 1)];    
      setDynamicDashboardData(newDashboardData);

      const newData = uniqueSecond(newDashboardData,updatedLayout.lg);
      const addedCol:any = addNewColumn(generateLayout(newData), 12, Number(lastItem?.key));
      
      const generatedLayout = adjustResponsiveLayoutsTwo(addedCol);
      setLayouts(generatedLayout);

    } else {
      {/** Create new */}
      const createNewSectionViaChart = {
        dashboard_template_id,
        title: sectionTitle,
        order: sections?.length + 1,
        created_by: user.id,
        contents: [
          {
            status: "active",
            layout_group: title.replace(/\s+/g, '-').toLowerCase() || '',
            layout: JSON.stringify(updatedLayout),
            layout_charts: updatedItems
          }
        ]
      };
      
      createDashboardSectionViaItem(createNewSectionViaChart);
    }

    setOpenWidget(false);
  };

  const handleDeleteItem = async (data) => {
    const remainingItems = dynamicDashboardData.filter(f => f.id !== data.id);
    const updatedLayout = {
      lg: layouts.lg.filter(item => item.i !== data.key),
      md: layouts.md.filter(item => item.i !== data.key),
      sm: layouts.md.filter(item => item.i !== data.key),
      xs: layouts.md.filter(item => item.i !== data.key),
      xxs: layouts.sm.filter(item => item.i !== data.key)
    };
    setDynamicDashboardData(remainingItems);
    if (dashboard?.id) {
      const deleteData = {
        layout_group: title.replace(/\s+/g, '-').toLowerCase() || '',
        layout: JSON.stringify(updatedLayout),
        layout_charts: remainingItems,
        status: 'active',
        updated_by: user.id
      };
      updateDashboard(dashboard?.id, deleteData);
    }
    setOpenWidget(false);
  }

  const saveDashbboardSection = () => {
    if (dashboardPageId) {
      updateDashboardPage(dashboardPageId, dashboard?.id, {
        title: sectionTitle,
        order: sections.length + 1,
        updated_by: user.id,
        contents: [
          {
            status: 'active',
            layout_group: sectionTitle.replace(/\s+/g, '-').toLowerCase() || '',
            layout: JSON.stringify(layouts),
            layout_charts: dynamicDashboardData,
          },
        ],
      });
    }
    
    // Create dashboard page
    if (!dashboardPageId) {
      createDashboardSection({
        dashboard_template_id,
        title: sectionTitle,
        order: sections?.length + 1,
        created_by: user.id,
      });
    }

    setIsEditingSectionTitle(false);
  };

  return (
    <>
      <div
        className="flex justify-between items-center mb-4"
        id={String(order)}
      >
        <div className="flex items-center gap-2">
          {isEditingSectionTitle ? (
            <input
              value={sectionTitle}
              onChange={(e) => setSectionTitle(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  saveDashbboardSection();
                }
              }}
              className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          ) : (
            <h2 className="title-text text-2xl pb-1">{sectionTitle || ''}</h2>
          )}

          {viewType === 'edit' &&
            // save/update section onlick
            (isEditingSectionTitle ? (
              <div
                onClick={() => saveDashbboardSection()}
                className="cursor-pointer min-h-[36px] flex items-center justify-center"
              >
                <div className="h-full flex items-center justify-center">
                  <ToolTipV2 tooltip="Save section name">
                    <Icon name="Save" size={20} color="#0029FF" />
                  </ToolTipV2>
                </div>
              </div>
            ) : (
              <div
                onClick={() => setIsEditingSectionTitle(!isEditingSectionTitle)}
                className="cursor-pointer min-h-[36px] flex items-center justify-center"
              >
                <div className="h-full flex items-center justify-center">
                  <ToolTipV2 tooltip="Edit section name">
                    <Icon name="Edit" size={18} color="#0029FF" />
                  </ToolTipV2>
                </div>
              </div>
            ))}
        </div>
        {viewType === 'edit' && (
          <div className="cursor-pointer flex flex-row items-center ">
            <Icon name="gridMenu" />
            {dashboardPageId &&
              <Tooltip content="Delete section" position='left'>
                <button
                  className="w-full ml-2 text-white bg-red-700 hover:bg-red-800 text-[16px] font-[600] rounded-lg px-2"
                  onClick={(e) => setConfirmModal(true)}
                >
                  X
                </button>
              </Tooltip>
            }
          </div>
        )}
      </div>

      <div className="flex flex-col gap-4">
        <ResponsiveReactGridLayout
          className="layout"
          layouts={layouts}
          breakpoints={breakpoints}
          cols={columns}
          rowHeight={87}
          height={90}
          onBreakpointChange={onBreakpointChange}
          // Disable drag on view
          isDraggable={viewType === 'edit'}
          // Disable resize on view
          isResizable={viewType === 'edit'}
          // dragging
          onDragStart={handleDragStart}
          onDragStop={handleDragStop}
          onResizeStop={onResizeStop}
          // onLayoutChange={onLayoutChange}
        >
          {renderGridItem()}
        </ResponsiveReactGridLayout>

        <CustomModal
          open={openWidget}
          onClose={() => {
            setOpenWidget(false);
          }}
        >
          <WidgetSettings
            selectedItem={selectedItem}
            handleSaveItem={handleSaveItem}
            handleDeleteItem={handleDeleteItem}
            onClose={() => setOpenWidget(false)}
            serviceLine={serviceLine}
          />
        </CustomModal>
        <CustomModal
          open={openCategoryModal}
          onClose={() => {
            setOpenCategoryModal(false);
          }}
        >
          <CategorySelection onClose={() => setOpenCategoryModal(false)} />
        </CustomModal>
        <CustomModal
            open={isConfirmModal}
            onClose={() => setConfirmModal(false)}
          >
            <DeleteSectionConfirmation
              sectionTitle={sectionTitle}
              onClose={() => setConfirmModal(false)}
              onDelete={() => {
                if (dashboardPageId) {
                  deleteSection(dashboardPageId)
                }
              }}
            />
        </CustomModal>
        
      </div>
    </>
  );
};

export default DynamicPage;
