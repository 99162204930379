import { DonutChart, Legend, Col, Card } from '@tremor/react';
import InfoTooltip from '../AnalyticsInfoTooltip';
import Icon from '../../../assets/icons/SvgComponent';
import NoDataError from '../../NoDataError';
import Tooltip from '../../HelpTooltip';
import NoDataErrorRetry from '../../NoDataRetry';

interface IProps {
  trendTxt?: boolean;
  title?: string;
  customClass?: string;
  variant?: any;
  showLegend?: boolean;
  data?: any;
  index?: string;
  categories?: string[];
  category?: string;
  loading?: boolean;
  error?: string;
  legendCustomClass?: string;
  info?: string;
  isDollar?: boolean;
  noAccessSubtitle?: string;
  icon?: string;
  propertyId?: string;
  retryMethod?: () => void
}

const valueFormatter = (number: number) =>
  `$ ${Intl.NumberFormat('us').format(number).toString()}`;

const valueFormatterNumber = (number: number) =>
  `${Intl.NumberFormat('us').format(number).toString()}`;
const DonutBarGroup = ({
  trendTxt = true,
  title,
  customClass,
  legendCustomClass,
  variant,
  showLegend = true,
  data,
  categories,
  category,
  index,
  loading,
  error,
  info,
  isDollar = false,
  noAccessSubtitle,
  propertyId,
  icon,
  retryMethod
}: IProps) => {
  if (loading) {
    return (
      <Col className="w-full h-full relative">
        <Card className="shadow-none ring-0 lg:ring-1 dark:bg-dark-tremor-background h-full relative">
          <div>
            <div className="flex justify-between mb-8">
              <div className="flex gap-2 items-center">
                <h2 className="text-[14px] text-[#001C44] dark:text-white font-bold">
                  {title ? title : ''}
                </h2>
                <InfoTooltip content={info} position="top">
                  <Icon name="InfoCircle" size={14} />
                </InfoTooltip>
              </div>
              <Tooltip
                content={propertyId ? propertyId : 'N/A'}
                position="top"
                textAlign="text-center"
              >
                <div className="pt-1">
                  <Icon name={`${icon}`} size={25} />
                </div>
              </Tooltip>
            </div>
            <div
              role="status"
              className=" flex items-center h-64 justify-center space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center "
            >
              <div className="flex items-center justify-center w-full rounded-full h-64 w-64 bg-gray-300 rounded sm:w-96 dark:bg-gray-700"></div>
              <div className="w-full">
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
              </div>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </Card>
      </Col>
    );
  } else {
    return (
      <Col className="w-full h-full relative">
        {data?.length === 0 || !data ? (
          <Card className="shadow-none ring-0 lg:ring-1">
            <div className="flex justify-between mb-8">
              {' '}
              <div className="flex gap-2 items-center mb-1">
                <h2 className="text-[14px] text-[#001C44] dark:text-white font-bold">
                  {title ? title : 'Organic Engaged Sessions'}
                </h2>
                <InfoTooltip content={info} position="top">
                  <Icon name="InfoCircle" size={14} />
                </InfoTooltip>
              </div>
              <Tooltip
                content={propertyId ? propertyId : 'N/A'}
                position="top"
                textAlign="text-center"
              >
                <div className="pt-1">
                  <Icon name={`${icon}`} size={25} />
                </div>
              </Tooltip>
            </div>
            <div className="flex flex-col justify-center w-full items-center my-8 h-64">
              {/* <NoDataError error={error} /> */}
              <NoDataErrorRetry 
                error={error}
                retry={error && error !== '' && typeof retryMethod === 'function'}
                handleRetry={() => retryMethod()}
                />
            </div>
          </Card>
        ) : (
          <Card className="shadow-none ring-0 lg:ring-1 dark:bg-dark-tremor-background h-full relative">
            <div className="flex flex-col justify-between h-full">
              <div>
                <div className="flex justify-between mb-8">
                  {' '}
                  <div className="flex gap-2 items-center mb-1">
                    <h2 className="text-[14px] text-[#001C44] dark:text-white font-bold">
                      {title ? title : 'Chart Title'}
                    </h2>
                    <InfoTooltip content={info} position="top">
                      <Icon name="InfoCircle" size={14} />
                    </InfoTooltip>
                  </div>
                  <Tooltip
                    content={propertyId ? propertyId : 'N/A'}
                    position="top"
                    textAlign="text-center"
                  >
                    <div className="pt-1">
                      <Icon name={`${icon}`} size={25} />
                    </div>
                  </Tooltip>
                </div>

                {showLegend ? (
                  <div
                    className={`flex items-center justify-between w-full h-3/4 gap-2 ${customClass}`}
                  >
                    <div className="w-1/2">
                      <DonutChart
                        data={data ? data : []}
                        category={`${category}`}
                        variant={variant ? variant : 'donut'}
                        index={index}
                        valueFormatter={
                          isDollar ? valueFormatter : valueFormatterNumber
                        }
                        colors={[
                          'blue',
                          'cyan',
                          'indigo',
                          'violet',
                          'fuchsia',
                          'sky',
                          'green',
                          'yellow',
                          'red',
                          'orange',
                          'pink',
                          'purple',
                          'gray',
                          'brown',
                          'black',
                          'white',
                          'blue',
                          'cyan',
                          'indigo',
                          'violet',
                          'fuchsia',
                          'sky',
                          'green',
                          'yellow',
                          'red',
                          'orange',
                          'pink',
                          'purple',
                          'gray',
                          'brown',
                          'black',
                          'white',
                        ]}
                        className="w-full h-64 text-xl"
                      />
                    </div>
                    <div className="w-1/2 flex justify-center max-h-[350px]">
                      <Legend
                        categories={categories ? categories : []}
                        colors={[
                          'blue',
                          'cyan',
                          'indigo',
                          'violet',
                          'fuchsia',
                          'sky',
                          'green',
                          'yellow',
                          'red',
                          'orange',
                          'pink',
                          'purple',
                          'gray',
                          'brown',
                          'black',
                          'white',
                          'blue',
                          'cyan',
                          'indigo',
                          'violet',
                          'fuchsia',
                          'sky',
                          'green',
                          'yellow',
                          'red',
                          'orange',
                          'pink',
                          'purple',
                          'gray',
                          'brown',
                          'black',
                          'white',
                        ]}
                        className={`${legendCustomClass} capitalize flex flex-col overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8`}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={`flex items-center justify-center w-full h-3/4 gap-2 ${customClass}`}
                  >
                    <div className="flex items-center justify-center mt-8 w-full">
                      <DonutChart
                        data={data ? data : []}
                        category={`${category}`}
                        variant={variant ? variant : 'donut'}
                        index={index}
                        valueFormatter={
                          isDollar ? valueFormatter : valueFormatterNumber
                        }
                        colors={[
                          'blue',
                          'cyan',
                          'indigo',
                          'violet',
                          'fuchsia',
                          'sky',
                          'green',
                          'yellow',
                          'red',
                          'orange',
                          'pink',
                          'purple',
                          'gray',
                          'brown',
                          'black',
                          'white',
                          'blue',
                          'cyan',
                          'indigo',
                          'violet',
                          'fuchsia',
                          'sky',
                          'green',
                          'yellow',
                          'red',
                          'orange',
                          'pink',
                          'purple',
                          'gray',
                          'brown',
                          'black',
                          'white',
                        ]}
                        className="w-64 h-64 text-xl"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Card>
        )}
      </Col>
    );
  }
};

export default DonutBarGroup;
