import Icon from '../../assets/icons/SvgComponent';
import React, { useState, useEffect } from 'react';
import Tooltip from '../HelpTooltip';
import api from '../../api';
import { Link, useLocation } from 'react-router-dom';
import { formatNumber } from '../../utils/common';
import ImageIcon from '../ImageIcon';

type ClientHeaderProps = {
  selectedClient?: any;
  handleSyncSuccess: () => void;
  isLoading?: boolean;
};

const ClientHeader: React.FC<ClientHeaderProps> = ({
  selectedClient,
  handleSyncSuccess,
  isLoading,
}) => {
  const location = useLocation();
  const accountCountry = selectedClient?.hubspot?.account_country;
  const [hubspotSyncing, setHubspotSyncing] = useState(false);
  const [renderSection, setRenderSection] = useState(0);
  const [ppcGoogle, setPpcGoogle] = useState(false);
  const [seoGoogle, setSeoGoogle] = useState(false);
  const [socialBing, setSocialBing] = useState(false);
  const [socialLinkedIn, setSocialLinkedIn] = useState(false);
  const [socialMeta, setSocialMeta] = useState(false);
  const [socialPinterest, setSocialPinterest] = useState(false);
  const [socialTiktok, setSocialTiktok] = useState(false);
  const [loadPage, setPageLoading] = useState(false);

  const tabs = [
    {
      label: 'Users',
      path: `/client/details/users/${selectedClient?.id}`,
    },
    {
      label: 'Teams',
      path: `/client/details/teams/${selectedClient?.id}`,
    },
    {
      label: 'Reporting',
      path: `/client/details/reporting/${selectedClient?.id}`,
    },
    {
      label: 'Integrations',
      path: `/client/details/integrations/${selectedClient?.id}`,
    },
    {
      label: 'Services',
      path: `/client/details/services/${selectedClient?.id}`,
    },
    {
      label: 'Changes',
      path: `/client/details/changes/${selectedClient?.id}`,
    },
    {
      label: 'Billing',
      path: `/client/details/billing/${selectedClient?.id}`,
    },
  ];

  useEffect(() => {
    setPageLoading(true);
    setTimeout(() => {
      setPageLoading(false);
    }, 2000);
  }, []);

  if (
    selectedClient?.ppc_google?.status === 'active' ||
    selectedClient?.ppc_google?.status === 'cancelling' ||
    selectedClient?.ppc_google?.status === 'paused' ||
    selectedClient?.ppc_google?.status === 'activated'
  ) {
    if (renderSection <= 3) {
      setRenderSection(renderSection + 1);
      setPpcGoogle(true);
    }
  }
  if (
    selectedClient?.seo_google?.status === 'active' ||
    selectedClient?.seo_google?.status === 'cancelling' ||
    selectedClient?.seo_google?.status === 'paused' ||
    selectedClient?.seo_google?.status === 'activated'
  ) {
    if (renderSection <= 3) {
      setRenderSection(renderSection + 1);
      setSeoGoogle(true);
    }
  }
  if (
    selectedClient?.social_bing?.status === 'active' ||
    selectedClient?.social_bing?.status === 'cancelling' ||
    selectedClient?.social_bing?.status === 'paused' ||
    selectedClient?.social_bing?.status === 'activated'
  ) {
    if (renderSection <= 3) {
      setRenderSection(renderSection + 1);
      setSocialBing(true);
    }
  }
  if (
    selectedClient?.social_linkedin?.status === 'active' ||
    selectedClient?.social_linkedin?.status === 'cancelling' ||
    selectedClient?.social_linkedin?.status === 'paused' ||
    selectedClient?.social_linkedin?.status === 'activated'
  ) {
    if (renderSection <= 3) {
      setRenderSection(renderSection + 1);
      setSocialLinkedIn(true);
    }
  }
  if (
    selectedClient?.social_meta?.status === 'active' ||
    selectedClient?.social_meta?.status === 'cancelling' ||
    selectedClient?.social_meta?.status === 'paused' ||
    selectedClient?.social_meta?.status === 'activated'
  ) {
    if (renderSection <= 3) {
      setRenderSection(renderSection + 1);
      setSocialMeta(true);
    }
  }
  if (
    selectedClient?.social_pinterest?.status === 'active' ||
    selectedClient?.social_pinterest?.status === 'cancelling' ||
    selectedClient?.social_pinterest?.status === 'paused' ||
    selectedClient?.social_pinterest?.status === 'activated'
  ) {
    if (renderSection <= 3) {
      setRenderSection(renderSection + 1);
      setSocialPinterest(true);
    }
  }
  if (
    selectedClient?.social_tiktok?.status === 'active' ||
    selectedClient?.social_tiktok?.status === 'cancelling' ||
    selectedClient?.social_tiktok?.status === 'paused' ||
    selectedClient?.social_tiktok?.status === 'activated'
  ) {
    if (renderSection <= 3) {
      setRenderSection(renderSection + 1);
      setSocialTiktok(true);
    }
  }

  const syncHubspotClients = async () => {
    const params = {
      country: accountCountry,
      company_id: selectedClient?.hubspot?.company_id,
    };
    setHubspotSyncing(true);
    const res = await api.HubSpot.syncHubspotClients(params);
    if (!res.error) {
      setHubspotSyncing(false);
      handleSyncSuccess();
      window.location.reload(); 
    }
  };
  

  return (
    <>
      {isLoading || loadPage ? (
        <div className="animate-pulse">
          <div className="sm:flex justify-between mt-12 sm:mt-0 min-h-[64px]">
            <div className="flex gap-2">
              <div className="flex gap-[8px] items-center">
                <div className="w-16 h-16 bg-gray-300 rounded-full " />
                <div className="flex sm:block  gap-8 items-center">
                  <div className="w-40 sm:w-[324px] h-8 bg-gray-300 rounded mb-2"></div>
                  <div className="w-[100px] sm:w-24 h-5 bg-gray-300 rounded"></div>
                </div>
              </div>
            </div>
            <div className="flex sm:justify-between items-start">
              <button
                onClick={syncHubspotClients}
                className="w-full text-white bg-[#0029FF] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 whitespace-nowrap"
              >
                SYNC WITH HUBSPOT
              </button>
              <Link
                target="_blank"
                to={`https://app.hubspot.com/contacts/1590785/company/${selectedClient?.hubspot?.company_id}`}
                className="w-full pr-2"
              >
                <button className="w-full ml-2 py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                  VIEW IN HUBSPOT
                </button>
              </Link>
            </div>
          </div>
          <div className="flex gap-[16px] items-center mt-[16px] mb-6">
            {tabs.map((tab, index) => (
              <div key={index}>
                <Link
                  to={tab.path}
                  className={`w-fit text-[14px] font-inter font-[500] leading-6  ${
                    location.pathname === tab.path
                      ? 'text-[#0029FF] border-b-2 border-[#0029FF]' // Active tab style
                      : 'text-[#001C44] dark:text-white dark:text-white' // Inactive tab style
                  }`}
                >
                  {tab.label}
                </Link>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className="sm:flex justify-between mt-12 sm:mt-0">
            <div className="flex items-center gap-[8px]">
              <div>
                <ImageIcon
                  data={selectedClient?.name}
                  size={16}
                  textSize={'2xl'}
                />
              </div>
              <div className="flex flex-col items-start gap-1 ">
                <div className="flex justify-between">
                  <div className="flex items-center gap-x-2">
                    <div className="max-w-[45vw] sm:max-w-[40vw]">
                      <Tooltip content={selectedClient?.name} position="right">
                        <div className="max-w-[45vw] sm:max-w-[40vw] truncate cursor-pointer text-[22px] font-archivo font-[600] dark:text-white">
                          {selectedClient?.name}
                        </div>
                      </Tooltip>
                    </div>
                    <div className="">
                      <a
                        href={`https://${selectedClient?.domain}`}
                        target="blank"
                      >
                        <Icon name="ArrowUpRight" size={22} color="#0029FF" />
                      </a>
                    </div>
                    <div>
                      {selectedClient?.category_type && (
                        <div className="uppercase text-xs font-normal bg-[#B2BBC740] py-1 px-2 rounded-md w-fit">
                          {selectedClient?.category_type}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {!loadPage && (
                  <div className="hidden sm:flex gap-2 items-center">
                    <div className="pr-1 border-r border-gray-400">
                      <div className="text-xs text-white rounded-full py-1 px-3 bg-[#009EFF] w-fit uppercase">
                        {selectedClient?.cycle}
                      </div>
                    </div>

                    {/* Define the array of service components */}
                    {[
                      ppcGoogle && (
                        <div className="flex items-center gap-2 px-1 border-r border-gray-400">
                          <Icon name="Click" />
                          <p className="text-[#001C44] dark:text-white text-xs sm:text-sm">
                            GOOGLE ADS:
                          </p>
                          <p className="text-[#001C44] dark:text-white text-xs sm:text-sm">
                            ${formatNumber(selectedClient?.ppc_google?.revenue)}
                          </p>
                          <div className="pr-1">
                            <Tooltip
                              position="bottom"
                              content={`Start Date: ${
                                selectedClient?.ppc_google?.start_date || 'N/A'
                              }
              End Date: ${selectedClient?.ppc_google?.end_date || 'N/A'}`}
                            >
                              <Icon name="HelpIcon" />
                            </Tooltip>
                          </div>
                        </div>
                      ),
                      seoGoogle && (
                        <div className="flex items-center gap-2 border-r px-1 border-gray-400">
                          <Icon name="SearchRefractionIcon" />
                          <p className="text-[#001C44] dark:text-white text-xs sm:text-sm">
                            GOOGLE SEO:
                          </p>
                          <p className="pr-1 text-[#001C44] dark:text-white text-xs sm:text-sm">
                            ${formatNumber(selectedClient?.seo_google?.revenue)}
                          </p>
                          <div className="pr-1">
                            <Tooltip
                              position="bottom"
                              content={`Start Date: ${
                                selectedClient?.seo_google?.start_date || 'N/A'
                              }
              End Date: ${selectedClient?.seo_google?.end_date || 'N/A'}`}
                            >
                              <Icon name="HelpIcon" />
                            </Tooltip>
                          </div>
                        </div>
                      ),
                      socialBing && (
                        <div className="flex items-center gap-2 border-r px-1 border-gray-400">
                          <Icon name="Globe03" />
                          <p className="text-[#001C44] dark:text-white text-xs sm:text-sm">
                            MICROSOFT ADS:
                          </p>
                          <p className="pr-1 text-[#001C44] dark:text-white text-xs sm:text-sm">
                            $
                            {formatNumber(selectedClient?.social_bing?.revenue)}
                          </p>
                          <div className="pr-1">
                            <Tooltip
                              position="bottom"
                              content={`Start Date: ${
                                selectedClient?.social_bing?.start_date || 'N/A'
                              }
              End Date: ${selectedClient?.social_bing?.end_date || 'N/A'}`}
                            >
                              <Icon name="HelpIcon" />
                            </Tooltip>
                          </div>
                        </div>
                      ),
                      socialLinkedIn && (
                        <div className="flex items-center gap-2 border-r pl-2 border-gray-400">
                          <Icon name="Globe03" />
                          <p className="text-[#001C44] dark:text-white text-xs sm:text-sm">
                            LINKEDIN ADS:
                          </p>
                          <p className="pr-1 text-[#001C44] dark:text-white text-xs sm:text-sm">
                            $
                            {formatNumber(
                              selectedClient?.social_linkedin?.revenue
                            )}
                          </p>
                          <div className="pr-1">
                            <Tooltip
                              position="bottom"
                              content={`Start Date: ${
                                selectedClient?.social_linkedin?.start_date
                                  ? selectedClient?.social_linkedin?.start_date
                                  : 'N/A'
                              }
                 End Date: ${
                   selectedClient?.social_linkedin?.end_date
                     ? selectedClient?.social_linkedin?.end_date
                     : 'N/A'
                 }`}
                            >
                              <Icon name="HelpIcon" />
                            </Tooltip>
                          </div>
                        </div>
                      ),
                      socialMeta && (
                        <div className="flex items-center gap-2 border-r pl-2 border-gray-400">
                          <Icon name="Globe03" />
                          <p className="text-[#001C44] dark:text-white text-xs sm:text-sm">
                            META ADS:
                          </p>
                          <p className="pr-1 text-[#001C44] dark:text-white text-xs sm:text-sm">
                            $
                            {formatNumber(selectedClient?.social_meta?.revenue)}
                          </p>
                          <div className="pr-1">
                            <Tooltip
                              position="bottom"
                              content={`Start Date: ${
                                selectedClient?.social_meta?.start_date
                                  ? selectedClient?.social_meta?.start_date
                                  : 'N/A'
                              }
                 End Date: ${
                   selectedClient?.social_meta?.end_date
                     ? selectedClient?.social_meta?.end_date
                     : 'N/A'
                 }`}
                            >
                              <Icon name="HelpIcon" />
                            </Tooltip>
                          </div>
                        </div>
                      ),
                      socialPinterest && (
                        <div className="flex items-center gap-2 border-r pl-2 border-gray-400">
                          <Icon name="Globe03" />
                          <p className="text-[#001C44] dark:text-white text-xs sm:text-sm">
                            PINTEREST:
                          </p>
                          <p className="pr-1 text-[#001C44] dark:text-white text-xs sm:text-sm">
                            $
                            {formatNumber(
                              selectedClient?.social_pinterest?.revenue
                            )}
                          </p>
                          <div className="pr-1">
                            <Tooltip
                              position="bottom"
                              content={`Start Date: ${
                                selectedClient?.social_pinterest?.start_date
                                  ? selectedClient?.social_pinterest?.start_date
                                  : 'N/A'
                              }
                 End Date: ${
                   selectedClient?.social_pinterest?.end_date
                     ? selectedClient?.social_pinterest?.end_date
                     : 'N/A'
                 }`}
                            >
                              <Icon name="HelpIcon" />
                            </Tooltip>
                          </div>
                        </div>
                      ),
                      socialTiktok && (
                        <div className="flex items-center gap-2 border-r pl-2 border-gray-400">
                          <Icon name="Globe03" />
                          <p className="text-[#001C44] dark:text-white text-xs sm:text-sm">
                            TIKTOK:
                          </p>
                          <p className="pr-1 text-[#001C44] dark:text-white text-xs sm:text-sm">
                            $
                            {formatNumber(
                              selectedClient?.social_tiktok?.revenue
                            )}
                          </p>
                          <div className="pr-1">
                            <Tooltip
                              position="bottom"
                              content={`Start Date: ${
                                selectedClient?.social_tiktok?.start_date
                                  ? selectedClient?.social_tiktok?.start_date
                                  : 'N/A'
                              }
                 End Date: ${
                   selectedClient?.social_tiktok?.end_date
                     ? selectedClient?.social_tiktok?.end_date
                     : 'N/A'
                 }`}
                            >
                              <Icon name="HelpIcon" />
                            </Tooltip>
                          </div>
                        </div>
                      ),
                      // Add other services here...
                    ]
                      .filter(Boolean) // Remove null or undefined entries
                      .slice(0, 3) // Show only the first 3 services
                      .map((service, index) => (
                        <React.Fragment key={index}>{service}</React.Fragment>
                      ))}

                    {[
                      ppcGoogle,
                      seoGoogle,
                      socialBing,
                      socialLinkedIn,
                      socialMeta,
                      socialPinterest,
                      socialTiktok,
                    ].filter(Boolean).length > 3 && (
                      <Tooltip
                        position="bottom"
                        textAlign="text-center"
                        content={`${[
                          socialLinkedIn &&
                            `LINKEDIN ADS: $${formatNumber(selectedClient?.social_linkedin?.revenue)}`,
                          socialMeta &&
                            `META ADS: $${formatNumber(selectedClient?.social_meta?.revenue)}`,
                          socialPinterest &&
                            `PINTEREST: $${formatNumber(selectedClient?.social_pinterest?.revenue)}`,
                          socialTiktok &&
                            `TIKTOK: $${formatNumber(selectedClient?.social_tiktok?.revenue)}`,
                        ]
                          .filter(Boolean)
                          .join('\n')}`}
                      >
                        <p className="text-xs text-[#001C44]">
                          +
                          {[
                            ppcGoogle,
                            seoGoogle,
                            socialBing,
                            socialLinkedIn,
                            socialMeta,
                            socialPinterest,
                            socialTiktok,
                          ].filter(Boolean).length - 3}{' '}
                          more
                        </p>
                      </Tooltip>
                    )}
                    {!isLoading && !loadPage && renderSection === 0 && (
                      <div className="flex items-center gap-2 border-r pl-2 border-gray-400">
                        <p className="text-[#001C44] dark:text-white text-sm pr-2">
                          No Services
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-start sm:justify-between items-start">
              <button
                onClick={syncHubspotClients}
                className="w-full text-white bg-[#0029FF] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 whitespace-nowrap"
              >
                SYNC WITH HUBSPOT
              </button>
              <Link
                target="_blank"
                to={`https://app.hubspot.com/contacts/1590785/company/${selectedClient?.hubspot?.company_id}`}
                className="w-full pr-2"
              >
                <button className="w-full ml-2 py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                  VIEW IN HUBSPOT
                </button>
              </Link>
            </div>
          </div>
          <div className="flex gap-[16px] items-center mt-[16px] mb-6">
            {tabs.map((tab, index) => (
              <div key={index}>
                <Link
                  to={tab.path}
                  className={`w-fit text-[14px] font-inter font-[500] leading-6  ${
                    location.pathname === tab.path
                      ? 'text-[#0029FF] border-b-2 border-[#0029FF]' // Active tab style
                      : 'text-[#001C44] dark:text-white dark:text-white' // Inactive tab style
                  }`}
                >
                  {tab.label}
                </Link>
              </div>
            ))}
          </div>
        </>
      )}

      {hubspotSyncing && (
        <div className="absolute top-20 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Icon name="Loading" />
        </div>
      )}
    </>
  );
};
export default ClientHeader;