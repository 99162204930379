import { LineChart } from '@tremor/react';
import { convertKey } from '../constants';
import { useEffect, useState } from 'react';

interface IProps {
  data?: any;
  categories?: string[];
  displayConfig?: any;
  viewType?: string;
  colors?: any[];
}

const chartdata = [
  {
    date: "Jan 23",
    SolarPanels: 2890,
  },
  {
    date: "Feb 23",
    SolarPanels: 2756,
  },
  {
    date: "Mar 23",
    SolarPanels: 3322,
  },
  {
    date: "Apr 23",
    SolarPanels: 3470,
  },
  {
    date: "May 23",
    SolarPanels: 3475,
  },
  {
    date: "Jun 23",
    SolarPanels: 3129,
  },
  {
    date: "Jul 23",
    SolarPanels: 3490,
  },
  {
    date: "Aug 23",
    SolarPanels: 2903,
  },
  {
    date: "Sep 23",
    SolarPanels: 2643,
  },
  {
    date: "Oct 23",
    SolarPanels: 2837,
  },
  {
    date: "Nov 23",
    SolarPanels: 2954,
  },
  {
    date: "Dec 23",
    SolarPanels: 3239,
  },
];

const OrganicKeywordTrends = ({ 
  data, 
  categories, 
  displayConfig, 
  viewType,
  colors
}: IProps) => {
  

  const [checkedCategories, setCheckedCategories] = useState(() => {
      const savedCategories = localStorage.getItem('checkedCategories');
      if (savedCategories) {
        return JSON.parse(savedCategories);
      }
      return categories.reduce((acc, category) => {
        acc[category] = true;
        return acc;
      }, {});
  });

  useEffect(() => {
    localStorage.setItem(
      'checkedCategories',
      JSON.stringify(checkedCategories)
    );
  }, [checkedCategories]);

  const handleCheckboxChange = (category) => {
    setCheckedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const filteredCategories = categories.filter(
    (category) => checkedCategories[category]
  );

  return (
    <div>
      <div className="flex gap-4 justify-end items-center mt-4">
        {categories.map((category, index) => (
          <label
            key={category}
            className="flex items-center gap-2 text-xs font-bold dark:text-white"
          >
            <input
              type="checkbox"
              checked={checkedCategories[category]}
              onChange={() => handleCheckboxChange(category)}
              className="styled-checkbox"
              style={{
                backgroundColor: checkedCategories[category]
                  ? colors[categories.indexOf(category)]
                  : 'transparent',
              }}
            />
            <span>{category}</span>
          </label>
        ))}
      </div>
      <LineChart
        data={["preview","edit"].includes(viewType) ? convertKey(chartdata,categories) : data}
        index="date"
        categories={filteredCategories}
        showLegend={false}
        showAnimation={true}
        yAxisWidth={35}
        colors={colors}
        className="-mb-2 mt-8 h-[13rem]"
      />
    </div>
  );
};

export default OrganicKeywordTrends;
