import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import Api from '../api';
const useUserStore = create(
  devtools((set,get) => ({
    allUsers:[],
    users: [],
    user: {},
    error: false,
    apiStatus: '',
    loading: false,
    errorMessage: '',
    fetchAllUsers: async () => {
      set(() => ({ loading: true, errorMessage: '' }));
      const response = await Api.User.getAllOMGUsers();
      set(() => ({ allUsers: response }));
      set(() => ({ loading: false }));
    },
    fetchUsers: async (filters) => {
      set(() => ({ loading: true, errorMessage: '' }));
      const response = await Api.User.getUsers(filters);
      set(() => ({ users: response }));
      set(() => ({ loading: false }));
    },
    updateUserImg: async (data) => {
      const users = get()?.users.data;
      const newData = users.map( user => user.id === data.id ? { ...data } : user);
      set((state) => ({
        users: {
          ...state.users,
          data: newData
        }
      }))
    },
    fetchUser: async (id) => {
      const response = await Api.User.getUser(id);
      set(() => ({ user: response }));
    },
    createUser: async (data) => {
      set(() => ({
        loading: true,
        error: false,
        errorMessages: '',
        apiStatus: '',
      }));
      const response = await Api.User.create(data);
      if (response[0]?.error) {
        set(() => ({
          apiStatus: 'error',
          loading: false,
          error: true,
          errorMessage: response[0]?.error,
        }));
        throw new Error(response[0]?.error);
      } else {
        set(() => ({
          apiStatus: 'success',
          loading: false,
          error: false,
          errorMessages: '',
        }));
        return response;
      }
    },
    updateUser: async (id, data) => {
      set(() => ({
        loading: true,
        error: false,
        errorMessages: '',
        apiStatus: '',
      }));
      const response = await Api.User.update(id, data);
      if (response[0]?.error) {
        set(() => ({
          apiStatus: 'error',
          loading: false,
          error: true,
          errorMessage: response[0].error,
        }));
        throw new Error(response[0]?.error);
      } else {
        set(() => ({
          apiStatus: 'success',
          loading: false,
          error: false,
          errorMessages: '',
        }));
        return response;
      }
    },
    updateUserStatus: async (data) => {
      set(() => ({
        loading: true,
        error: false,
        errorMessages: '',
        apiStatus: '',
      }));
      const response = await Api.User.updateStatus(data);
      if (response[0]?.error) {
        set(() => ({
          apiStatus: 'error',
          loading: false,
          error: true,
          errorMessage: response[0].error,
        }));
        throw new Error(response[0]?.error);
      } else {
        set(() => ({
          apiStatus: 'success',
          loading: false,
          error: false,
          errorMessages: '',
        }));
        return response;
      }
    },
    deleteUsers: async (data) => {
      set(() => ({ loading: true }));
      await Api.User.delete(data);
      set(() => ({ loading: false }));
    },
    resetStatus: (data) => {
      set(() => ({
        loading: false,
        error: false,
        errorMessages: '',
        apiStatus: '',
      }));
    },
  }))
);

export default useUserStore;
