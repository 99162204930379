import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import useGoogleStore from '../../store/useGoogleStore';
import use3rdPartyStore from '../../store/use3rdPartyStore';
import useMetaStore from '../../store/useMetaStore';
import useAuthStore from '../../store/useAuthStore';
import useClientStore from '../../store/useClientStore';
import 'react-datepicker/dist/react-datepicker.css';
import AnalyticsSeoGA4 from '../../components/AnalyticsTabs/SeoGA4';
import { getStatus } from '../../utils';
import AnalyticsPPC from '../../components/AnalyticsTabs/PPCv3';
import AnalyticsFB from '../../components/AnalyticsTabs/FbAdsv2';
import ComingSoon from '../../components/ComingSoon';
import PublicInternalPageHeader from '../../components/Dashboard/PublicInternalPageHeader';
import Header from '../../components/Layout/Header';
import PublicHeader from '../../components/Layout/Header/PublicHeader';
import axios from 'axios';
import { toQueryString } from '../../utils/common';
import useBingStore from '../../store/useBingStore';
import AnalyticsBingAds from '../../components/AnalyticsTabs/BingAds';

interface PpcTab {
  index: string;
  id: string;
  label: string;
}
interface PublicClientAnalyticsProps {
  type?: string;
}

const PublicClientAnalytics: React.FC<PublicClientAnalyticsProps> = ({ type }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const scrollRef = useRef<any>(null);
  const { selectedClient, fetchClient } = useClientStore((state) => state);
  const { selectedDomain, setDomain } = useAuthStore((state) => state);
  const [isMounted, setIsMounted] = useState(false);
  const [credentialsError, setCredentialsError] = useState(false);
  const [activePpcTab, setActiveTab] = useState<PpcTab>({
    index: '1',
    id: 'overall',
    label: 'Overall',
  });

  const {
    fetchGsearchData,
    fetchGA4SeoData,
    fetchCampainsReport,
    fetchLandingPages,
    resetGoogleStore,
  } = useGoogleStore((state) => state);
  const {
    fetchAccountInsights,
    fetchCampaignInsights,
    fetchAdInsights,
    fetchAdsetInsights,
    resetMetaStore,
    fetchDemographics,
  } = useMetaStore((state) => state);
  const {
    fetchBingCampainsReport,
    resetBingStore
  } = useBingStore((state) => state);

  const {
    fetchDomainRating,
    fetchRefDomains,
    fetchTrafficCompetition,
    fetchRankRanger,
    fetchAhrefsLinks,
    fetchSemrushLinks,
    fetchOrgDomainOverview,
    fetchTopKeywords,
    fetchOrgSearchPositions,
    reset3rdPartyStore,
    fetchDomainRatingHistory,
    fetchUrlRating,
  } = use3rdPartyStore((state) => state);
  
  const isClientActivatedPPC =
    selectedClient?.google_ads?.customer_id &&
    selectedClient?.google_ads?.status === 'activated'
      ? true
      : false;
  const searchParams = new URLSearchParams(location.search);
  let startDate = searchParams.get('startDate');
  let endDate = searchParams.get('endDate');
  const id = searchParams.get('id');
  const clientId = searchParams.get('client_id');
  const publicToken = searchParams.get('token');
  const activepublicTab = searchParams.get('ppage');

  // Scroll to sepecific section
  const scrollToView = (id: string) => {
    const headerHeight = '190px';
    const targetElement = document.getElementById(id);

    if (targetElement) {
      targetElement.style.scrollMargin = headerHeight;
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // Scroll to the active tab
  useEffect(() => {
    if (scrollRef.current) {
      scrollToView(activePpcTab.id);
    }
  }, [activePpcTab]);

  // Set the active tab based on the location
  useEffect(() => {
    if (scrollRef.current) {
      scrollToTop();
      
      if (activepublicTab === 'seo-ga4') {
        setActiveTab({
          index: '1',
          id: 'key-performance',
          label: 'Key Performance Indicator',
        });
      }
      if (activepublicTab === 'paid-search') {
        setActiveTab({
          index: '1',
          id: 'overall',
          label: 'Overall',
        });
      }
      if (activepublicTab === 'microsoft-ads') {
        setActiveTab({
          index: '1',
          id: 'general',
          label: 'General Overview',
        });
      }
      if (activepublicTab === 'fb-ads') {
        setActiveTab({
          index: '1',
          id: 'general',
          label: 'General Overview',
        });
      }
    }

  }, [location.pathname, location.search, selectedClient]);

  // Fetch client data on mount
  useEffect(() => {
    

    if (clientId !== 'No Domain' && !isMounted) {
      axios.defaults.headers.common.Authorization = `Public ${publicToken}`
      fetchClient(clientId).then((res: any) => {
        // fetchReportsData(res?.data);
        setCredentialsError(false)
        handleClientChange(res?.data);
        if(!activepublicTab) {
          
          searchParams.set('ppage', 'seo-ga4');
          navigate({ search: searchParams.toString() });
        }
      }).catch((e:any) => {
        setCredentialsError(true);
      });
    }
  }, []);

  // set Domain/Client on url client id change
  useEffect(() => {
    // check if the selected domain and the search query id are the same and if not, update the selected domain
    if (selectedDomain?.id !== id && id !== null) {
      setDomain({ id: id, label: '' });
    }
  }, [id]);

  // fetch client date on domain change
  useEffect(() => {
    if (isMounted) {
      if (selectedDomain?.id !== 'No Domain') {
        fetchClient(selectedDomain?.id);
      } else {
        return;
      }
    }
  }, [selectedDomain]);

  // call handleClientChange on client id change
  // useEffect(() => {
  //   handleClientChange();
  // }, [selectedClient?.id]);

  // handle client change by adjusting the start and end date based on the cycle
  const handleClientChange = (paramClient?: any) => {
    const client = paramClient;
    const update = paramClient ? true : isMounted;
    const searchParams = new URLSearchParams(location.search);
    let startDate = searchParams.get('startDate');
    const localDate = JSON.parse(localStorage.getItem('seoStartDate'));
    let endDate;

    // Validate and initialize startMoment
    let startMoment =
      startDate && moment(startDate).isValid()
        ? moment(startDate)
        : localDate && moment(localDate).isValid()
          ? moment(localDate)
          : null;
    if (paramClient) {
      setIsMounted(true);
    }
    if (!startMoment && update) {
      // Default to the current date adjusted to the cycle
      startMoment = moment();
      if (client?.cycle === 'Cycle 2') {
        // Set it to the 15th of the current month and the 14th of the next month
        startDate = startMoment.set('date', 15).format('YYYY-MM-DD');
        endDate = startMoment
          .clone()
          .add(1, 'month')
          .set('date', 14)
          .format('YYYY-MM-DD');
      } else if (client?.cycle === 'Cycle 1') {
        // Set to the 15th of the last month to the 14th of the current month
        startDate = startMoment
          .subtract(1, 'month')
          .set('date', 15)
          .format('YYYY-MM-DD');
        endDate = startMoment
          .add(1, 'month')
          .set('date', 14)
          .format('YYYY-MM-DD');
      }
      searchParams.set('startDate', startDate);
      searchParams.set('endDate', endDate);
      
      // navigate({ search: searchParams.toString() });dasdasdasdasdsaxcz czxczc
      navigate(`/client/dashboard${toQueryString({
        token: searchParams.get('token'),
        client_id: searchParams.get('client_id'),
        ppage: activepublicTab,
        startDate: searchParams.get('startDate'),
        endDate: searchParams.get('endDate'),
      })}`);
      fetchReportsData(client);
    } else {
      // Adjust the start and end dates based on the cycle
      if (client?.cycle === 'Cycle 2' && startMoment?.date() != 15 && update) {
        startDate = startMoment.set('date', 15).format('YYYY-MM-DD');
        endDate = startMoment
          .clone()
          .add(1, 'month')
          .set('date', 14)
          .format('YYYY-MM-DD');
        searchParams.set('startDate', startDate);
        searchParams.set('endDate', endDate);
        localStorage.setItem('seoStartDate', JSON.stringify(startDate));
        localStorage.setItem('seoEndDate', JSON.stringify(endDate));
        
        navigate({ search: searchParams.toString() });
      } else if (
        client?.cycle === 'Cycle 1' &&
        startMoment?.date() != 1 &&
        update
      ) {
        startDate = startMoment.set('date', 1).format('YYYY-MM-DD');
        endDate = startMoment.clone().endOf('month').format('YYYY-MM-DD');
        searchParams.set('startDate', startDate);
        searchParams.set('endDate', endDate);
        localStorage.setItem('seoStartDate', JSON.stringify(startDate));
        localStorage.setItem('seoEndDate', JSON.stringify(endDate));
        
        navigate({ search: searchParams.toString() });
      } else {
        if (update) {
          fetchReportsData(paramClient);
        }
      }
    }
  };
  // call fetchReportsData on start date change
  useEffect(() => {
    if (isMounted) {
      fetchReportsData();
    }
  }, [startDate]);

  const scrollToTop = () => {
    const headerHeight = '185px';
    scrollRef.current.style.scrollMargin = headerHeight;
    return scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };
  // utility function to get formatted date from storage
  const getFormattedDateFromStorage = (key) => {
    const rawDate = localStorage.getItem(key);
    const json = rawDate ? JSON.parse(rawDate) : null;
    return rawDate ? moment(json).format('YYYY-MM-DD') : null;
  };
  // handle reports data fetch based send param or selected client
  const fetchReportsData = (paramClient?: any, resetCache?:boolean) => {
    
    const getDefaultSEODate = () => {
      const now = moment();
      const isCycle2 = selectedClient?.cycle?.toLowerCase() === 'cycle 2';
      if (isCycle2) {
        if (now.date() <= 14) {
          return {
            startDate: now.subtract(1, 'month').date(15).format('YYYY-MM-DD'),
            endDate: now.date(14).format('YYYY-MM-DD'),
          };
        } else {
          return {
            startDate: now.date(15).format('YYYY-MM-DD'),
            endDate: now.add(1, 'month').date(14).format('YYYY-MM-DD'),
          };
        }
      }
      return {
        startDate: now.startOf('month').format('YYYY-MM-DD'),
        endDate: now.endOf('month').format('YYYY-MM-DD'),
      };
    };

    const getDefaultPPCDate = () => {
      const startOfMonth = moment().startOf('month');
      return {
        startDate: startOfMonth.format('YYYY-MM-DD'),
        endDate: startOfMonth.clone().endOf('month').format('YYYY-MM-DD'),
      };
    };

    let seoDates = {
      startDate: startDate || getFormattedDateFromStorage('seoStartDate'),
      endDate: endDate || getFormattedDateFromStorage('seoEndDate'),
    };

    let ppcDates = {
      startDate: getFormattedDateFromStorage('startDate'),
      endDate: getFormattedDateFromStorage('endDate'),
    };

    // Set default dates if none are found
    seoDates =
      seoDates.startDate && seoDates.endDate ? seoDates : getDefaultSEODate();
    ppcDates =
      ppcDates.startDate && ppcDates.endDate ? ppcDates : getDefaultPPCDate();

    if (selectedClient?.id || paramClient?.id) {
      setIsMounted(true);
      // Perform SEO related fetches
      fetchGA4Analytics(seoDates.startDate, seoDates.endDate, paramClient, resetCache);
      getGoogleSearchConsole(seoDates.startDate, seoDates.endDate, paramClient, resetCache);
      getSemRush(paramClient, resetCache);
      getAhrefRush(seoDates.startDate, seoDates.endDate, paramClient, resetCache);
      getRankRanger(seoDates.startDate, seoDates.endDate, paramClient, resetCache);

      // // Perform PPC && Meta related fetches
      getGoogleAdds(ppcDates.startDate, ppcDates.endDate, paramClient,resetCache);
      fetchFbInsights(ppcDates.startDate, ppcDates.endDate, paramClient, resetCache);

      // Bing ads
      fetchBingAds('2023-05-01','2024-05-31',paramClient)
    }
  };

  const fetchGA4Analytics = (
    startDate: Date | string,
    endDate: Date | string,
    paramClient?: any,
    resetCache?:boolean
  ) => {
    const client = paramClient || selectedClient;
    const data = {
      account_email: client?.google_analytics?.ga4?.account_email,
      propertyId: client?.google_analytics?.ga4?.property_id,
      startDate: moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
      reportType: 'seo',
      endDate,
      resetCache: resetCache || false,
      clientId: client?.id
    };
    if (data.propertyId && data.account_email) {
      fetchGA4SeoData(data).catch((error: unknown) => {
        console.log(error, 'error');
      });
      fetchLandingPages(data).catch(
        (error: unknown) => console.log(error, 'error')
      );
    } else {
      resetGoogleStore(['ga4SeoData']);
    }
  };
  const getGoogleAdds = (
    startDate: Date | string,
    endDate: Date | string,
    paramClient?: any,
    resetCache?:boolean
  ) => {
    const client = paramClient || selectedClient;
    const status = getStatus(client?.google_ads?.status);
    const adwordsId = client?.google_ads?.customer_id;
    if (status && adwordsId) {
      const data = {
        customerId: adwordsId,
        startDate: moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: endDate,
        resetCache: resetCache || false,
      };
      fetchCampainsReport(data);
    } else {
      resetGoogleStore(['campaignsReport']);
    }
  };
  const getSemRush = (paramClient?: any, resetCache?:boolean) => {
    const client = paramClient || selectedClient;
    const semrushStatus = getStatus(client?.semrush?.status);
    const payload = {
      id: client?.id,
      page: 1,
      limit: 10,
      resetCache: resetCache || false,
    };
    if (semrushStatus) {
      fetchTrafficCompetition({ id: client?.id, resetCache: resetCache || false})
        .then(() => {})
        .catch((error: unknown) => {
          console.log(error, 'error');
        });
      fetchSemrushLinks(payload);
      fetchOrgDomainOverview({ id: client?.id, resetCache: resetCache || false});
      fetchOrgSearchPositions({ id: client?.id, resetCache: resetCache || false});
    } else {
      reset3rdPartyStore([
        'semrushData',
        'semrushLinks',
        'orgDomainOverview',
        'orgSearchPositions',
      ]);
    }
  };
  const getAhrefRush = (
    startDate: Date | string,
    endDate: Date | string,
    paramClient?: any,
    resetCache?:boolean
  ) => {
    const client = paramClient || selectedClient;
    const status = getStatus(client?.ahrefs?.status);
    if (status) {
      const data = {
        id: client?.id,
        startDate: moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: endDate,
        resetCache: resetCache || false,
      };
      const ahrefsFilter = {
        id: client?.id,
        page: 1,
        limit: 10,
        startDate: moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: endDate,
        resetCache: resetCache || false,
      };
      fetchAhrefsLinks(ahrefsFilter);
      fetchUrlRating(data);
      fetchDomainRating(data);
      fetchRefDomains(data);
      fetchTopKeywords(data);
      fetchDomainRatingHistory(data);
    } else {
      reset3rdPartyStore([
        'refdomains',
        'domainRating',
        'ahrefsLinks',
        'topKeywords',
      ]);
    }
  };
  const getGoogleSearchConsole = (
    startDate: Date | string,
    endDate: Date | string,
    paramClient?: any,
    resetCache?:boolean
  ) => {
    const client = paramClient || selectedClient;
    const account_email = client?.google_search_console?.account_email;
    const siteUrl = client?.google_search_console?.siteUrl;
    const status = getStatus(client?.google_search_console?.status);
    if (siteUrl && account_email && status) {
      const data = {
        siteUrl,
        account_email,
        startDate: moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: endDate,
        resetCache: resetCache || false,
        clientId: client?.id
      };
      fetchGsearchData(data).catch((error: unknown) => {
        console.log(error, 'error');
      });
    } else {
      resetGoogleStore(['googleSearchConsole']);
    }
  };
  const getRankRanger = (
    startDate: Date | string,
    endDate: Date | string,
    paramClient?: any,
    resetCache?:boolean
  ) => {
    const client = paramClient || selectedClient;
    const status = getStatus(client?.rank_ranger?.status);
    const rrCampaignId = client?.rank_ranger?.campaign_id;
    const rrCampaignName = client?.domain;
    if (status && rrCampaignId) {
      const data = {
        campaignId: rrCampaignId,
        domain: rrCampaignName,
        date: moment(startDate).format('YYYY-MM'),
        startDate: moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: endDate,
        resetCache: resetCache || false,
      };
      fetchRankRanger(data);
      // fetchGoogleRanking(data);
    } else {
      reset3rdPartyStore(['rankRangerData']);
    }
  };
  const fetchFbInsights = async (
    startDate: Date | string,
    endDate: Date | string,
    paramClient?: any,
    resetCache?:boolean
  ) => {
    const client = paramClient || selectedClient;
    const status = getStatus(client?.facebook_ads?.status);
    const data = {
      clientId: client.id,
      since: startDate,
      until: moment(endDate).format('YYYY-MM-DD'),
      timeIncrement: 'monthly',
      level: 'campaign',
      limit: 100,
      resetCache: resetCache || false,
    };
    if (client?.facebook_ads?.ads_id && status) {
      try {
        await Promise.all([
          fetchAccountInsights(data),
          fetchCampaignInsights(data),
          fetchAdInsights(data),
          fetchAdsetInsights(data),
          fetchDemographics(data),
        ]);
      } catch (error) {
        console.log(error, 'error');
      }
    } else {
      resetMetaStore([
        'accountInsights',
        'campaignInsights',
        'adInsights',
        'adsetInsights',
      ]);
    }
  };

  const fetchBingAds = async (
    startDate: Date | string,
    endDate: Date | string,
    paramClient?: any,
    resetCache?:boolean
  ) => {
    const client = paramClient || selectedClient;
    const status = getStatus(client?.seo_bing?.status);
    // Also check for accountID
    if (status) {
      const data = {
        account: 'devops@onlinemarketinggurus.com.au',
        accountId: '141883435',
        startDate: startDate,
        endDate: endDate,
        resetCache: true,
      };
      fetchBingCampainsReport(data);
    } else {
      resetBingStore(['campaignsReport']);
    }
  }

  const handleResetCache = () => {
    fetchReportsData(selectedClient, true);
  }
  
  // if(selectedClient?.loading && ) {
  //   <div className="w-full bg-[#D2EEFF] font-inter">
  //     <PublicHeader />
  //     <h1>Invalid credentials</h1>
  //   </div>
  // }
  if(!clientId && !publicToken) {
    return (
      <div className="w-full bg-[#D2EEFF] font-inter">
        <PublicHeader />
        <ComingSoon
          title="Missing token or client ID"
          message="Please get the correct token or client ID"
        />
      </div>
    )
  }
  if(credentialsError) {
    return (
      <div className="w-full bg-[#D2EEFF] font-inter">
        <PublicHeader />
        <ComingSoon
          title="Invalid token or client ID"
          message="Please get the correct token or client ID"
        />
      </div>
    )
  }
  
  return (
    <div className="w-full bg-[#D2EEFF] font-inter" ref={scrollRef}>
      <PublicHeader />
      {isMounted
        ?
        <PublicInternalPageHeader 
        title=''
          subtitle="Monthly Activity Overview"
          customClass="sticky top-[48px] sm:top-[64px] sm:px-[2.5rem] z-[12]"
          activeTab={activePpcTab}
          setActiveTab={setActiveTab}
          handleScroll={scrollToView}
          handleResetCache={handleResetCache}
          type={type}
        />
        : <div className="animate-pulse flex space-x-4 px-3 py-1 sm:px-10  sm:pt-4">
            <div className="flex-1 space-y-6 py-1">
              <div className="flex flex-col-reverse sm:flex-col space-y-3">
                <div className="grid grid-cols-3 gap-4 mt-3 sm:mt-0">
                  <div className="h-12 bg-slate-700 rounded col-span-2"></div>
                  <div className="h-12 bg-slate-700 rounded col-span-1"></div>
                </div>
                <div className="h-7 bg-slate-700 rounded mt-1 sm:mt-8"></div>
              </div>
            </div>
          </div>
      }
      <div className="flex flex-col px-3 pt-[20px] sm:pt-0 sm:px-[2.5rem] py-4 gap-2 lg:gap-4 dark:bg-[#0C111D]">
        {activepublicTab === 'seo-ga4' && (
          <>
            {selectedDomain?.id === 'No Domain' ? (
              <ComingSoon
                title="No Client Domain"
                message="Before We Venture Further, Let's Find Our Common Ground: Please Search and Select a Client First."
              />
            ) : (
              <AnalyticsSeoGA4
                clientLoading={selectedClient?.loading}
                category={selectedClient?.category_type}
              />
            )}
          </>
        )}
        {activepublicTab === 'paid-search' && (
          <>
            {selectedDomain?.id === 'No Domain' ? (
              <ComingSoon
                title="No Client Domain"
                message="Before We Venture Further, Let's Find Our Common Ground: Please Search and Select a Client First."
              />
            ) : (
              <AnalyticsPPC
                clientLoading={selectedClient?.loading}
                isClientActivated={isClientActivatedPPC}
              />
            )}
          </>
        )}
        {activepublicTab === 'microsoft-ads' && (
          <>
           {selectedDomain?.id === 'No Domain' ? (
              <ComingSoon
                title="No Client Domain"
                message="Before We Venture Further, Let's Find Our Common Ground: Please Search and Select a Client First."
              />
            ) : (
              <AnalyticsBingAds />
            )}
          </>
        )
        }
        {activepublicTab === 'fb-ads' && (
          <>
            {selectedDomain?.id === 'No Domain' ? (
              <ComingSoon
                title="No Client Domain"
                message="Before We Venture Further, Let's Find Our Common Ground: Please Search and Select a Client First."
              />
            ) : (
              <AnalyticsFB />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PublicClientAnalytics;
