import React, { useEffect, useState } from 'react';
import { Card, Grid, Col } from '@tremor/react';
import { useLocation } from 'react-router-dom';

import ServiceNotAvailable from '../../components/Common/ServiceNotAvailable';
import useClientStore from '../../store/useClientStore';
import useDashboardStore from '../../store/useDashboardStore';
import EmptyPage from '../../components/ReactGridComponents/EmptyPage';
import DynamicPage from '../../components/ReactGridComponents/Components/DynamicPage';

interface IProps {
  clientLoading: boolean;
  startDate?: string;
  endDate?: string;
  serviceLine?: string;
  activeTab?: string;
}

const Page = ({
  clientLoading,
  startDate,
  endDate,
  serviceLine="seo",
  activeTab
}: IProps) => {

  const { selectedClient } = useClientStore((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const {
    sections,
    loading,
    fetchDashboardTemplate,
  } = useDashboardStore((state) => state);

  useEffect(() => {
    if (selectedClient?.id) {
      fetchPages(selectedClient?.id);
    }
  }, [selectedClient, startDate, endDate]);

  const fetchPages = async (id: string) => {
    await fetchDashboardTemplate(id,serviceLine);
    setIsLoading(true)
  };


  const isPublicDashboard = window.location.pathname === '/client/dashboard';
  const noServiceAvailable = ['activated', 'active'].includes(
    selectedClient?.seo_google?.status
  )
    ? true
    : false;

  const noIntegrationAvailable =
    !['activated', 'active'].includes(
      selectedClient?.google_analytics?.ga4?.status
    ) &&
    !['activated', 'active'].includes(
      selectedClient?.google_search_console?.status
    ) &&
    !['activated', 'active'].includes(selectedClient?.semrush?.status) &&
    !['activated', 'active'].includes(selectedClient?.ahrefs?.status) &&
    !['activated', 'active'].includes(selectedClient?.rank_ranger?.status);

  if (!selectedClient?.loading && !loading) {
    if (isPublicDashboard && noServiceAvailable && noIntegrationAvailable) {
      return (
        <ServiceNotAvailable
          title="No Dashboard Available"
          serviceType="seo"
          hideRequestAccess={isPublicDashboard}
        />
      );
    }
    if (!noServiceAvailable)
      return (
        <ServiceNotAvailable
          serviceType="seo"
          hideRequestAccess={isPublicDashboard}
        />
      );
    if (noIntegrationAvailable)
      return (
        <ServiceNotAvailable
          title="Integration Not Set Up"
          serviceType="seo"
          hideRequestAccess={isPublicDashboard}
        />
      );
  }
  
  return (
    <>
      {loading ? (
        <div className="w-full h-[600px] bg-white mt-2 px-6 rounded-lg">
          <div className="w-36 h-8 bg-gray-300 animate-pulse my-8" />
          <Grid numItems={3} className="gap-2 mt-12">
            <Col>
              <Card className="bg-gray-300 h-[390px] w-full animate-pulse" />
            </Col>
            <Col>
              <Card className="bg-gray-300 h-[390px] w-full animate-pulse" />
            </Col>
            <Col>
              <Card className="bg-gray-300 h-[390px] w-full animate-pulse" />
            </Col>
          </Grid>
        </div>
      ) : (
        <>
          {(!loading && isLoading) &&
          selectedClient &&
          ((sections?.length === 0 && sections[0]?.error) || !sections || sections?.length === 0) ? (
            <EmptyPage />
          ) : (
            sections?.length > 0 &&
            sections
              .sort((a, b) => a.order - b.order)
              .map((section, index) => {
              return (
                <Card
                  className="dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2 h-auto"
                  key={index}
                >
                  <DynamicPage
                    key={section._id}
                    dashboardPageId={section._id}
                    title={section.title}
                    order={section.order}
                    dashboard={section?.contents[0]}
                    viewType="view"
                    startDate={startDate}
                    endDate={endDate}
                  />
                </Card>
              );
            })
          )}
        </>
      )}
    </>
  );
};

export default Page;
