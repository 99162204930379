import { Link } from 'react-router-dom';
import useDashboardStore from '../../store/useDashboardStore';
import CustomModal from '../CustomModal';
import { useState } from 'react';
import CategorySelection from './Modals';
import useClientStore from '../../store/useClientStore';

const EmptyPage = () => {

  const { selectedClient } = useClientStore((state) => state);
  const {
    sections
  } = useDashboardStore((state) => state);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);

  return (
    <>
      <div className="h-[600px] flex flex-col gap-[16px] items-center justify-center bg-[#FFF] mt-6 rounded-lg">
        <h4 className="font-bold text-[#001C44]">No Template Selected</h4>
        <p className="w-[440px] text-center text-[#001C44] font-inter font-[300]">
          Stay top-of-mind and convert site visitors into customers with
          competitor-crusing remarketing
        </p>

        {sections?.length > 0 
          ? <Link
              to={`/client/analytics-grid/dashboard?clientId=${selectedClient?.id}&viewType=edit`} 
              className="w-fit font-semibold flex font-inter text-[#001C44] bg-[#D0EDFF] items-center justify-center gap-1 rounded-full py-1.5 px-5 text-black cursor-pointer"
            >
              Edit Dashboard
            </Link>
          : 
          <button 
              onClick={() => setOpenCategoryModal(true)}
              className="w-fit font-semibold flex font-inter text-[#001C44] bg-[#D0EDFF] items-center justify-center gap-1 rounded-full py-1.5 px-5 text-black cursor-pointer">
              Select a Template
            </button>
        }
      </div>
      
      <CustomModal
        open={openCategoryModal}
        onClose={() => {
          setOpenCategoryModal(false);
        }}
        >
        <CategorySelection onClose={() => setOpenCategoryModal(false)} />
      </CustomModal>
    </>
  );
};

export default EmptyPage;
