import { useLocation } from 'react-router-dom';
import { Card } from '@tremor/react';

import useClientStore from '../../../store/useClientStore';
import DynamicPage from '../Components/DynamicPage';
import useDashboardStore from '../../../store/useDashboardStore';
import { useEffect, useRef, useState } from 'react';
import DynamicPageHeaderGroup from '../Components/DynamicPageHeaderGroup';

const DashboardPreview = () => {
  const location = useLocation();
  const { selectedClient, fetchClient } = useClientStore((state) => state);
  const {
    sections,
    fetchDashboardTemplate,
    isSectionsFetched,
    addNewSection,
    isAddingNewSection,
    serviceLine
  } = useDashboardStore((state) => state);

  const searchParams = new URLSearchParams(location.search);
  const clientId = searchParams.get('clientId');
  const viewType = searchParams.get('viewType');
  

  const scrollRef = useRef<any>(null);
  
  const [activePpcTab, setActiveTab] = useState(
    sections?.length > 0 ? sections[0] : null
  );

  // const selectedTab =
  //   type === 'client'
  //     ? location.pathname.split('/')[2]
  //     : location.pathname.split('/')[3];

  let startDate = searchParams.get('startDate');
  let endDate = searchParams.get('endDate');
  const id = searchParams.get('id');

  // // Set the active tab based on the location
  useEffect(() => {
    if (scrollRef.current) {
      setActiveTab(sections?.length > 0 ? sections[0] : []);
    }
  }, [location.pathname, location.search, selectedClient]);

  // Scroll to sepecific section
  const scrollToView = (id: string) => {
    const headerHeight = '220px';
    const targetElement = document.getElementById(id);

    if (targetElement) {
      targetElement.style.scrollMargin = headerHeight;
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // Scroll to the active tab
  useEffect(() => {
    if (scrollRef.current) {
      scrollToView(activePpcTab?.order);
    }
  }, [activePpcTab]);

  useEffect(() => {
    if (!isSectionsFetched && sections?.length === 0) {
      fetchPages();
    }
  }, [selectedClient]);

  useEffect(() => {
    if(!selectedClient?.id) {
      fetchClient(clientId);
    }
  },[selectedClient]);

  const fetchPages = () => {
    if (selectedClient?.id || clientId) fetchDashboardTemplate(clientId ? clientId : selectedClient?.id, serviceLine);
  };

  return (
    <div>
        <DynamicPageHeaderGroup
          subtitle="Monthly Activity Overview"
          customClass="sticky top-[110px] sm:top-[64px] sm:px-[2.5rem] z-[12]"
          activeTab={activePpcTab}
          setActiveTab={setActiveTab}
          handleScroll={scrollToView}
          // handleResetCache={handleResetCache}
          type={"seo-ga4"}
          viewType="preview"
          // data={} // dynamic data here
        />
        {/* <div className={`relative block lg:flex items-start justify-between`}>
          <div className="flex gap-2 items-center sm:block w-full md:w-[16vw] lg:w-[30vw] my-1 sm:my-0">
            <div className="flex justify-between items-center sm:justify-start sm:gap-2 text-[26px] sm:text-3xl  font-[800] text-[#001C44] dark:text-white">
              <div>
                <Tooltip content={selectedClient?.name} position="right">
                  <div className="max-w-[80vw] sm:max-w-[65vw] md:max-w-[15vw] lg:max-w-[35vw] truncate cursor-pointer  text-[24px]">
                    {selectedClient?.name}
                  </div>
                </Tooltip>
              </div>
            </div>

            <div className="flex gap-2">
              {selectedClient?.loading && (
                <div className="bg-[#B2BBC740] w-[120px] h-[24px]"></div>
              )}
              {selectedClient?.category_type && (
                <div className="uppercase text-xs font-normal bg-[#B2BBC740] py-1 px-2 rounded-md w-fit h-fit sm:h-full">
                  {selectedClient?.category_type}
                </div>
              )}
              {selectedClient?.cycle &&
                !location.pathname.includes('/client/analytics/paid-search') &&
                !location.pathname.includes('/client/analytics/fb-ads') && (
                  <div className=" text-xs text-white rounded-md py-1 px-2 bg-[#009EFF] w-fit h-fit sm:h-full">
                    {selectedClient?.cycle}
                  </div>
                )}
            </div>
          </div>
        </div> */}
      <div className="px-6 py-3 m-4 rounded-md">
        {/* <hr className="my-8" /> */}

        {sections &&
          !sections[0]?.error &&
          sections.length > 0 &&
          sections
            .sort((a, b) => a.order - b.order)
            .map((section) => (
            <Card
              className="dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2 h-auto"
              key={section._id}
            >
              <DynamicPage
                key={section._id}
                dashboardPageId={section._id}
                title={section.title}
                order={section.order}
                dashboard={section?.contents[0]}
                viewType="preview"
              />
            </Card>
          ))}
      </div>
    </div>
  );
};

export default DashboardPreview;
