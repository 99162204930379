import { Link } from 'react-router-dom';
import { FaRedo } from 'react-icons/fa';
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox';
import Icon from '../../assets/icons/SvgComponent';
import ImageIcon from '../../components/ImageIcon';
import Tooltip from '../../components/HelpTooltip';

interface GenerateColumnsProps {
  sortingColumn: string | null;
  sortingDirection: any;
  navToClientDetails: (id: string) => void;
  isFlagged: boolean;
  handleRegenerate: (value: any) => void;
  handleEdit: (value: any) => void;
  handleSend: (value: any) => void;
  handleApprove: (value: any) => void;
  handleFlag: (value: any) => void;
  setItem: (value: any) => void;
  isLeadGen: boolean;
}

export const generateSEOColumns = ({
  sortingColumn,
  sortingDirection,
  navToClientDetails,
  isFlagged,
  handleRegenerate,
  handleEdit,
  handleSend,
  handleApprove,
  handleFlag,
  setItem,
  isLeadGen,
}: GenerateColumnsProps) => [
  {
    id: 'Select',
    header: ({ table }: any) => {
      return (
        <div className="flex items-center justify-center w-fit pl-3">
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      );
    },
    cell: ({ row }: any) => {
      return (
        <div className="flex items-center justify-center w-fit pl-3">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      );
    },
  },
  {
    id: 'name',
    header: () => (
      <div className="w-[180px] sm:w-[16vw]">
        {sortingColumn === 'name' ? (
          <div className="flex w-[180px] sm:w-[16vw] justify-start gap-2 cursor-pointer">
            <div className="text-left text-sm font-inter w-fit">Client</div>
            <div>
              {sortingDirection === 'asc' && <Icon name="Arrowup" />}
              {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
              {sortingDirection === null && <Icon name="Sort" />}
            </div>
          </div>
        ) : (
          <div className="flex justify-start gap-2 w-[180px] sm:w-[16vw] text-sm font-inter cursor-pointer">
            <div>Client</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'client',
    cell: (props: any) => {
      const lastComment = props.getValue()?.comments?.length > 0 && 
        props.getValue()?.comments[props.getValue()?.comments?.length - 1];
      const notFlaggedHelpTip = `${props.getValue()?.name}`;
      return (
        <Link
          onClick={() => {
            navToClientDetails(props?.getValue()?.client_id?.id);
          }}
          to={`/client/details/reporting/${props?.getValue()?.client_id?.id}`}
          className="w-[180px] sm:w-[16vw]"
        >
          <div className="max-w-[180px] sm:max-w-[16vw]">
            <Tooltip
              content={isFlagged ? lastComment?.comment : notFlaggedHelpTip}
              position="right"
            >
              <div className="truncate overflow-hidden text-sm max-w-[180px] sm:max-w-[16vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF]">
                {props.getValue()?.name}
              </div>
              {isFlagged ? (
                <div className="text-xs font-[500] font-inter truncate text-left text-red-300 max-w-[180px] sm:max-w-[16vw]">
                  {lastComment?.comment}
                </div>
              ) : (
                <div className="text-left text-xs font-inter font-light text-[#405573] dark:text-gray-300 w-fit">
                  SEO
                </div>
              )}
            </Tooltip>
          </div>
        </Link>
      );
    },
  },
  {
    id: 'account_manager',
    header: () => (
      <div className="w-[100px] sm:w-[6vw] ">
        {sortingColumn === 'account_manager' ? (
          <div className="flex w-[100px] sm:w-[6vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">AM</div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex w-[100px] sm:w-[6vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">AM</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'client',
    cell: (props: any) => (
      <div className="flex justify-center w-[100px] sm:w-[6vw]">
        <Tooltip content={props.getValue()?.account_manager} position="right">
          <ImageIcon
            size={8}
            data={props.getValue()?.account_manager}
            textSize={`xs`}
          />
        </Tooltip>
      </div>
    ),
  },
  {
    id: 'consultant',
    header: () => (
      <div className="w-[100px] sm:w-[6vw] ">
        {sortingColumn === 'consultant' ? (
          <div className="flex w-[100px] sm:w-[6vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">Cons</div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex w-[100px] sm:w-[6vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">Cons</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'client',
    cell: (props: any) => (
      <div className="flex justify-center w-[100px] sm:w-[6vw] ">
        <Tooltip content={props.getValue()?.consultant} position="right">
          <ImageIcon
            size={8}
            data={props.getValue()?.consultant}
            textSize={`xs`}
          />
        </Tooltip>
      </div>
    ),
  },

  {
    id: 'report.seven_days.clicks',
    header: () => (
      <div className="w-[160px] sm:w-[10vw] ">
        {sortingColumn === 'report.seven_days.clicks' ? (
          <div className="flex w-[160px] sm:w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">Clicks</div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex justify-center gap-2 w-[160px] sm:w-[10vw] cursor-pointer">
            <div className="text-sm font-inter">Clicks</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'clicks',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[10vw] ">
        <div className="flex justify-center font-inter font-[500] text-xs">
          {props.getValue()}
        </div>
      </div>
    ),
  },
  {
    id: 'report.seven_days.impressions',
    header: () => (
      <div className="w-[160px] sm:w-[10vw]">
        {sortingColumn === 'report.seven_days.impressions' ? (
          <div className="flex w-[160px] sm:w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">Impressions</div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex justify-center w-[160px] sm:w-[10vw] gap-2 cursor-pointer">
            <div className="text-sm font-inter">Impressions</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'impression',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[10vw] ">
        <div className="flex justify-center font-inter font-[500] text-xs ">
          {props.getValue()}
        </div>
      </div>
    ),
  },
  {
    id: 'report.seven_days.ctr',
    header: () => (
      <div className="w-[160px] sm:w-[10vw]">
        {sortingColumn === 'report.seven_days.ctr' ? (
          <div className="flex w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">CTR</div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex justify-center w-[160px] sm:w-[10vw] gap-2 cursor-pointer">
            <div className="text-sm font-inter">CTR</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'CTR',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[10vw] ">
        <div className="flex justify-center font-inter font-[500] text-xs ">
          {props.getValue()}
        </div>
      </div>
    ),
  },

  {
    id: 'report.seven_days.newUsers',
    header: () => (
      <div className="w-[160px] sm:w-[10vw]">
        {sortingColumn === 'report.seven_days.newUsers' ? (
          <div className="flex w-[160px] sm:w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">New Users</div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex justify-center w-[160px] sm:w-[10vw] gap-2 cursor-pointer">
            <div className="text-sm font-inter">New Users</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'newUsers',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[10vw]">
        <div className="flex justify-center font-inter font-[500] text-xs ">
          {props.getValue()}
        </div>
      </div>
    ),
  },
  {
    id: 'report.seven_days.organic_newUsers',
    header: () => (
      <div className="w-[160px] sm:w-[10vw]">
        {sortingColumn === 'report.seven_days.organic_newUsers' ? (
          <div className="flex w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">Organic Users</div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex justify-center w-[160px] sm:w-[10vw] gap-2 cursor-pointer">
            <div className="text-sm font-inter">Organic Users</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'organic_newUsers',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[10vw] ">
        <div className="flex justify-center font-inter font-[500] text-xs ">
          {props.getValue()}
        </div>
      </div>
    ),
  },
  {
    id: 'report.seven_days.organic_sessions',
    header: () => (
      <div className="w-[160px] sm:w-[12vw]">
        {sortingColumn === 'report.seven_days.organic_sessions' ? (
          <div className="flex w-[160px] sm:w-[12vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">Organic Sessions</div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex justify-center w-[160px] sm:w-[12vw] gap-2 cursor-pointer">
            <div className="text-sm font-inter">Organic Sessions</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'organic_sessions',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex justify-center font-inter font-[500] text-xs ">
          {props.getValue()}
        </div>
      </div>
    ),
  },

  {
    id: 'report.seven_days.organic_engagedSessions',
    header: () => (
      <div className="w-[160px] sm:w-[14vw]">
        {sortingColumn === 'report.seven_days.organic_engagedSessions' ? (
          <div className="flex w-[160px] sm:w-[14vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">Organic Engaged Sessions</div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex justify-center w-[160px] sm:w-[14vw] gap-2 cursor-pointer">
            <div className="text-sm font-inter">Organic Engaged Sessions</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'organic_engagedSessions',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[14vw]">
        <div className="flex justify-center font-inter font-[500] text-xs ">
          {props.getValue()}
        </div>
      </div>
    ),
  },

  {
    id: 'report.seven_days.organic_conversions',
    header: () => (
      <div className="w-[160px] sm:w-[14vw]">
        {sortingColumn === 'report.seven_days.organic_conversions' ? (
          <div className="flex w-[14vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">Organic Conversions</div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex justify-center w-[160px] sm:w-[14vw] gap-2 cursor-pointer">
            <div className="text-sm font-inter">Organic Conversions</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'organic_conversions',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[14vw] ">
        <div className="flex justify-center font-inter font-[500] text-xs ">
          {props.getValue()}
        </div>
      </div>
    ),
  },

  {
    id: 'report.seven_days.organic_conversions_rate',
    header: () => (
      <div className="w-[160px] sm:w-[14vw]">
        {sortingColumn === 'report.seven_days.organic_conversions_rate' ? (
          <div className="flex w-[160px] sm:w-[14vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">Organic Conversions Rate</div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex justify-center w-[160px] sm:w-[14vw] gap-2 cursor-pointer">
            <div className="text-sm font-inter">Organic Conversions Rate</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'organic_conversions_rate',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[14vw] ">
        <div className="flex justify-center font-inter font-[500] text-xs ">
          {props.getValue()}
        </div>
      </div>
    ),
  },

  {
    id: 'report.seven_days.top_pages_10',
    header: () => (
      <div className="w-[160px] sm:w-[10vw]">
        {sortingColumn === 'report.seven_days.top_pages_10' ? (
          <div className="flex w-[160px] sm:w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">Top 10 Pages</div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex justify-center w-[160px] sm:w-[10vw] gap-2 cursor-pointer">
            <div className="text-sm font-inter">Top 10 Pages</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'top_pages_10',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[10vw]">
        <div className="flex justify-center font-inter font-[500] text-xs ">
          {props.getValue()}
        </div>
      </div>
    ),
  },

  {
    id: 'report.seven_days.top_page',
    header: () => (
      <div className="w-[160px] sm:w-[10vw]">
        {sortingColumn === 'report.seven_days.top_page' ? (
          <div className="flex w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">Top Page</div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex justify-center w-[160px] sm:w-[10vw] gap-2 cursor-pointer">
            <div className="text-sm font-inter">Top Page</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'top_page',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[10vw] ">
        <div className="flex justify-center font-inter font-[500] text-xs ">
          {props.getValue()}
        </div>
      </div>
    ),
  },

  {
    id: 'report.seven_days.top_3',
    header: () => (
      <div className="w-[160px] sm:w-[10vw]">
        {sortingColumn === 'report.seven_days.top_3' ? (
          <div className="flex w-[160px] sm:w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">Top 3</div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex justify-center w-[160px] sm:w-[10vw] gap-2 cursor-pointer">
            <div className="text-sm font-inter">Top 3</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'top_3',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[10vw] ">
        <div className="flex justify-center font-inter font-[500] text-xs ">
          {props.getValue()}
        </div>
      </div>
    ),
  },

  {
    id: 'report.seven_days.refDomain',
    header: () => (
      <div className="w-[160px] sm:w-[10vw]">
        {sortingColumn === 'report.seven_days.refDomain' ? (
          <div className="flex w-[160px] sm:w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">Referring Domain </div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex justify-center w-[160px] sm:w-[10vw] gap-2 cursor-pointer">
            <div className="text-sm font-inter">Referring Domain</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'refDomain',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[10vw] ">
        <div className="flex justify-center font-inter font-[500] text-xs ">
          {props.getValue()}
        </div>
      </div>
    ),
  },

  {
    id: 'report.seven_days.domain_rating',
    header: () => (
      <div className="w-[160px] sm:w-[10vw]">
        {sortingColumn === 'report.seven_days.domain_rating' ? (
          <div className="flex w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">Domain Rating</div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex justify-center w-[160px] sm:w-[10vw] gap-2 cursor-pointer">
            <div className="text-sm font-inter">Domain Rating</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'domain_rating',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[10vw] ">
        <div className="flex justify-center font-inter font-[500] text-xs ">
          {props.getValue()}
        </div>
      </div>
    ),
  },

  {
    id: 'report.seven_days.url_rating',
    header: () => (
      <div className="w-[160px] sm:w-[10vw]">
        {sortingColumn === 'report.seven_days.url_rating' ? (
          <div className="flex w-[10vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">URL Rating</div>
            {sortingDirection === 'asc' && <Icon name="Arrowup" />}
            {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        ) : (
          <div className="flex justify-center w-[160px] sm:w-[10vw] gap-2 cursor-pointer">
            <div className="text-sm font-inter">URL Rating</div>
            <Icon name="Sort" />
          </div>
        )}
      </div>
    ),
    accessorKey: 'url_rating',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[10vw] ">
        <div className="flex justify-center font-inter font-[500] text-xs ">
          {props.getValue()}
        </div>
      </div>
    ),
  },

  {
    id: 'Action',
    header: () => (
      <div className="flex justify-center w-[100px] sm:w-[7vw] text-sm font-inter">
        Action
      </div>
    ),
    accessorKey: 'actions',
    cell: (props: any) => (
      <div className="flex space-x-3 items-center w-[100px] sm:w-[7vw] justify-center ">
        {isFlagged && (
          <FaRedo
            title="Regenerate"
            onClick={() => handleRegenerate(props?.getValue())}
            className="cursor-pointer"
            color="#0000E7"
            size={18}
          />
        )}
        <div
          className="cursor-pointer"
          onClick={() => {
            handleEdit(props?.getValue());
          }}
        >
          <Icon name="edit" />
        </div>
        {!isFlagged && (
          <div
            className="cursor-pointer"
            onClick={() => {
              setItem(props?.getValue());
              handleSend(props?.getValue());
            }}
          >
            <Icon name="Send01" />
          </div>
        )}
        {isFlagged ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              setItem(props?.getValue());
              handleApprove(props?.getValue());
            }}
          >
            <Icon name="Flag01" />
          </div>
        ) : (
          <div
            className="cursor-pointer"
            onClick={() => {
              setItem(props?.getValue());
              handleFlag(props?.getValue());
            }}
          >
            <Icon name="Flag01" />
          </div>
        )}
      </div>
    ),
  },
];
