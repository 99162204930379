import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import ImageIcon from "../ImageIcon";
import axios from "axios";
import useUserStore from "../../store/useUserStore";


interface IProps {
  user: any
}

const UploadImage = ({
  user,
}: IProps) => {
  const { updateUserImg } = useUserStore((state) => state);
  const [preview, setPreview] = useState(null)

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    
    setPreview(URL.createObjectURL(file));

    const formData = new FormData();
    formData.append("profile_picture", file);

    let AUTH_TOKEN = JSON.parse(localStorage.getItem('auth-storage'))?.state?.token;
    const imgUser = await axios.post(`${process.env.REACT_APP_API_ROOT}/api/v1/user/${user.id}/upload-profile-picture`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${AUTH_TOKEN}`
      }
    });
    updateUserImg(imgUser?.data?.user);
  },[])

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    onDrop,
    multiple: false
  })

  return (
    <div>
      <div 
        {...getRootProps()}
        className="flex justify-between w-full border-b pb-4 px-6 mb-2">
        <input {...getInputProps()} />

        <div className="relative rounded-full flex justify-center items-center ">
          <div className="w-full h-full flex items-center justify-center text-gray-600 ">
            {user?.profile_pic ? (
              <>
                <img
                  src={`${user?.profile_pic}?v=${user?.updated_by.updated_at}`}
                  alt="profile"
                  className="w-[80px]"
                />
              </>
            ) : preview ? 
                <img
                  src={`${preview}?v=${user?.updated_by.updated_at}`}
                  alt="profile"
                  className="w-[80px]"
                />
            : (
              <ImageIcon data={user} size={20} textSize={'2xl'} />
            )}
          </div>
        </div>
        <div className="relative  px-2 border border-solid rounded-md border-gray-300 overflow-hidden text-[#344054] ">
          <div className="w-full h-full flex flex-col items-center justify-center text-xs text-gray-600 bg-white dark:bg-[#262627]">
            <div className="dark:text-[#E6EFF5]">
              <span className="text-[#005C9F] font-semibold dark:text-[#589778]">
                Click to upload
              </span>{' '}
              or drag and drop
            </div>
            <div className="dark:text-[#E6EFF5]">
              SVG, PNG, JPG or GIF (max.800x400x)
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadImage;