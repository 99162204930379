import { Grid, Col } from '@tremor/react';
import moment from 'moment';
import SparkAreaChartCard from '../SparkAreaChartCard';
import AreaCardChart from '../AreaCardChart';
import useClientStore from '../../../store/useClientStore';
import use3rdPartyStore from '../../../store/use3rdPartyStore';
import {
  formatNumberWithCommas,
  formatToCurrency,
  formatNumber,
  processData,
  parseDate,
} from '../../../utils/common';
import useGoogleStore from '../../../store/useGoogleStore';
import { isLedGenClient } from '../../../utils/client';
import MobileView from './MobileView';
import {
  useRetryFetchAhrefDomainRatingHistory,
  useRetryFetchAhrefDomains,
  useRetryFetchGA4Analytics,
  useRetryFetchGS4Analytics,
} from '../Hooks';

interface IProps {
  sliceData?: boolean;
}

const LeadingIndicators = ({ sliceData }: IProps) => {
  const { ga4SeoData, googleSearchConsole } = useGoogleStore((state) => state);
  const startDate = parseDate(localStorage.getItem('seoStartDate'));
  const endDate = parseDate(localStorage.getItem('seoEndDate'));

  const { selectedClient } = useClientStore((state) => state);
  const propertyId = selectedClient?.google_analytics?.ga4?.property_id;
  const isLeadGen = isLedGenClient(selectedClient);
  const {
    orgDomainOverview,
    refdomains,
    fetchRefDomains,

    domainRatingHistory,

    reset3rdPartyStore,
  } = use3rdPartyStore((state) => state);

  const ahrefsData = {
    transactions: ga4SeoData?.transactions,
    refdomains: refdomains?.refdomains || {},
    domain_rating: domainRatingHistory?.data?.domain_rating || {},
  };

  const isGoogleConsoleActive =
    selectedClient.google_search_console?.status === 'activated';
  const isDomainOverviewLoading =
    orgDomainOverview?.loading || selectedClient?.loading;

  const keywordCount =
    orgDomainOverview?.data && orgDomainOverview?.data[0]?.['Organic Keywords']
      ? formatNumberWithCommas(orgDomainOverview?.data[0]?.['Organic Keywords'])
      : 0;
  const traffic =
    orgDomainOverview?.data && orgDomainOverview?.data[0]?.['Organic Traffic']
      ? formatNumberWithCommas(orgDomainOverview?.data[0]?.['Organic Traffic'])
      : 0;
  const trafficCost =
    orgDomainOverview?.data && orgDomainOverview?.data[0]?.['Organic Cost']
      ? formatToCurrency(orgDomainOverview?.data[0]?.['Organic Cost'])
      : 0;
  // const rank =
  //   orgDomainOverview?.data && orgDomainOverview?.data[0]?.['Rank']
  //     ? formatNumberWithCommas(orgDomainOverview?.data[0]?.['Rank'])
  //     : 0;

  const clicks = processData(googleSearchConsole, 'clicks');
  const impressions = processData(googleSearchConsole, 'impressions');
  const ctr = processData(googleSearchConsole, 'ctr');
  // const position = processData(googleSearchConsole, 'position');
  const transactions = processData(ahrefsData, 'transactions');
  const domainReferring = processData(
    ahrefsData,
    'refdomains',
    'Referring Domains'
  );
  const domainRating = processData(
    ahrefsData,
    'domain_rating'
    // 'Referring Domains'
  );

  const lastMonthClicks =
    Array.isArray(clicks) && clicks.length > 0
      ? clicks[clicks.length - 1]?.value
      : undefined;

  const lastMonthImpressions =
    Array.isArray(impressions) && impressions.length > 0
      ? impressions[impressions.length - 1]?.value
      : undefined;

  const lastMonthCtr =
    Array.isArray(ctr) && ctr.length > 0
      ? ctr[ctr.length - 1]?.value
      : undefined;

  // const lastMonthPositions =
  //   Array.isArray(position) && position.length > 0
  //     ? position[position.length - 1]?.value
  //     : undefined;

  const lastMonthTransactions =
    Array.isArray(transactions) && transactions.length > 0
      ? transactions[transactions.length - 1]?.value
      : undefined;

  const lastMonthDomainReferring =
    Array.isArray(domainReferring) && domainReferring.length > 0
      ? domainReferring[domainReferring.length - 1]?.value
      : undefined;

  const lastMonthDomainRating =
    Array.isArray(domainRating) && domainRating.length > 0
      ? domainRating[domainRating.length - 1]?.value
      : undefined;

  const data = orgDomainOverview?.data;

  // const rankInfo =
  //   data && data.length > 0 && data[0].rank_info ? data[0].rank_info : 'Rank';
  const keywordInfo =
    data && data.length > 0 && data[0].organic_keyword_info
      ? data[0].organic_keyword_info
      : 'Keyword';
  const trafficInfo =
    data && data.length > 0 && data[0].organic_traffic_info
      ? data[0].organic_traffic_info
      : 'Traffic';
  const trafficCostInfo =
    data && data.length > 0 && data[0].organic_cost_info
      ? data[0].organic_cost_info
      : 'Organic Traffic Value';

  // const retryFetchGA4Analytics = () => {
  //   const client = selectedClient;
  //   const data = {
  //     account_email: client?.google_analytics?.ga4?.account_email,
  //     propertyId: propertyId,
  //     startDate: moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
  //     reportType: 'seo',
  //     endDate: moment(endDate).format('YYYY-MM-DD'),
  //     resetCache:false,
  //   };
  //   if (data.propertyId && data.account_email) {
  //     fetchGA4SeoData(data).catch((error: unknown) => {
  //       console.log(error, 'error');
  //     });
  //   } else {
  //     resetGoogleStore(['ga4SeoData']);
  //   }
  // };

  // const retryFetchGAConsole = () => {
  //   const client = selectedClient;
  //   const data = {
  //     siteUrl: client?.google_search_console?.siteUrl,
  //     account_email: client?.google_search_console?.account_email,
  //     startDate: moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
  //     endDate: moment(endDate).format('YYYY-MM-DD'),
  //     resetCache: false,
  //   };
  //   if (data.siteUrl && data.account_email && getStatus(client?.google_search_console?.status)) {
  //     fetchGsearchData(data).catch((error: unknown) => {
  //       console.log(error, 'error');
  //     });
  //   } else {
  //     resetGoogleStore(['ga4SeoData']);
  //   }
  // };

  const retryFetchGA4Analytics = useRetryFetchGA4Analytics();
  const retryFetchGAConsole = useRetryFetchGS4Analytics();
  const retryFetchAhrefDomains = useRetryFetchAhrefDomains();
  const retryFetchDomainRatingHistory = useRetryFetchAhrefDomainRatingHistory();

  const retryFetchAhref = () => {
    const client = selectedClient;
    const data = {
      id: client?.id,
      startDate: moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
      endDate: endDate,
      resetCache: false,
    };
    if (data.id) {
      fetchRefDomains(data).catch((error: unknown) => {
        console.log(error, 'error');
      });
    } else {
      reset3rdPartyStore(['refdomains']);
    }
  };

  return (
    <>
      <div className="flex justify-start items-center mb-4">
        <h2 className="title-text text-base sm:text-[1.85rem] pb-1 hidden sm:block">
          Leading Indicators
        </h2>
      </div>
      <Grid
        numItems={1}
        numItemsSm={1}
        numItemsMd={2}
        numItemsLg={3}
        className="gap-4"
      >
        {/* <Col>
          <SparkAreaChartCard
            data={rank}
            name="Rank"
            loading={isDomainOverviewLoading}
            info={rankInfo}
            icon='semrush'
          />
        </Col> */}
        <Col>
          <SparkAreaChartCard
            data={keywordCount}
            name="Total Keywords Indexed"
            loading={isDomainOverviewLoading}
            info={keywordInfo}
            icon="semrush"
            propertyId={selectedClient?.semrush?.domain ? selectedClient?.semrush?.domain : selectedClient?.domain ? selectedClient?.domain : 'N/A'}
          />
        </Col>
        <Col>
          <SparkAreaChartCard
            data={traffic}
            name="Estimated Traffic"
            loading={isDomainOverviewLoading}
            info={trafficInfo}
            icon="semrush"
            propertyId={selectedClient?.semrush?.domain ? selectedClient?.semrush?.domain : selectedClient?.domain ? selectedClient?.domain : 'N/A'}
          />
        </Col>
        <Col>
          <SparkAreaChartCard
            data={trafficCost}
            name="Estimated Organic Traffic Value"
            loading={isDomainOverviewLoading}
            info={trafficCostInfo}
            icon="semrush"
            propertyId={selectedClient?.semrush?.domain ? selectedClient?.semrush?.domain : selectedClient?.domain ? selectedClient?.domain : 'N/A'}
          />
        </Col>
      </Grid>

      <MobileView
        retryGA={retryFetchGAConsole}
        retryRefDomain={retryFetchAhrefDomains}
        retryRefDomainHistory={retryFetchDomainRatingHistory}
      />
      <div className="hidden sm:block">
        {isGoogleConsoleActive && (
          <Grid
            numItems={1}
            numItemsSm={1}
            numItemsMd={2}
            numItemsLg={3}
            className="gap-4 mt-4"
          >
            <Col>
              <AreaCardChart
                title={
                  googleSearchConsole?.clicks?.label
                    ? googleSearchConsole?.clicks?.label
                    : 'Clicks'
                }
                chatData={clicks}
                category={['Clicks']}
                info={
                  googleSearchConsole?.clicks?.info
                    ? googleSearchConsole?.clicks?.info
                    : 'Clicks'
                }
                prevPeriod={googleSearchConsole?.clicks?.previous_period}
                previousYear={googleSearchConsole?.clicks?.previous_year}
                isLoading={googleSearchConsole.loading}
                totalValue={formatNumberWithCommas(lastMonthClicks)}
                error={googleSearchConsole.error}
                icon="googleSearch"
                propertyId={selectedClient?.google_search_console?.site_url}
                retryMethod={retryFetchGAConsole}
              />
            </Col>
            <Col>
              <AreaCardChart
                title={'Impressions'}
                chatData={impressions}
                category={['Impressions']}
                info={
                  googleSearchConsole?.impressions?.info
                    ? googleSearchConsole?.impressions?.info
                    : 'Impressions'
                }
                prevPeriod={googleSearchConsole?.impressions?.previous_period}
                previousYear={googleSearchConsole?.impressions?.previous_year}
                isLoading={googleSearchConsole.loading}
                totalValue={formatNumberWithCommas(lastMonthImpressions)}
                error={googleSearchConsole.error}
                icon="googleSearch"
                propertyId={selectedClient?.google_search_console?.site_url}
                retryMethod={retryFetchGAConsole}
              />
            </Col>
            <Col>
              <AreaCardChart
                title={'CTR'}
                chatData={ctr}
                category={['Ctr']}
                info={
                  googleSearchConsole?.ctr?.info
                    ? googleSearchConsole?.ctr?.info
                    : 'CTR'
                }
                prevPeriod={googleSearchConsole?.ctr?.previous_period}
                previousYear={googleSearchConsole?.ctr?.previous_year}
                isLoading={googleSearchConsole.loading}
                totalValue={formatNumber(lastMonthCtr, true)}
                hasPercent={true}
                error={googleSearchConsole.error}
                icon="googleSearch"
                propertyId={selectedClient?.google_search_console?.site_url}
                retryMethod={retryFetchGAConsole}
              />
            </Col>
            {/* <Col>
            <AreaCardChart
              title={'Domain Positions'}
              chatData={position}
              category={['Position']}
              info={
                googleSearchConsole?.position?.info
                  ? googleSearchConsole?.position?.info
                  : 'Domain Positions'
              }
              prevPeriod={googleSearchConsole?.position?.previous_period}
              previousYear={googleSearchConsole?.position?.previous_year}
              isLoading={googleSearchConsole.loading}
              totalValue={formatNumberWithCommas(lastMonthPositions)}
              error={googleSearchConsole.error}
              icon='googleSearch'
            />
          </Col> */}
          </Grid>
        )}

        <Grid
          numItems={1}
          numItemsSm={1}
          numItemsMd={2}
          numItemsLg={3}
          className="gap-4 mt-4"
        >
          {!isLeadGen && (
            <Col>
              <AreaCardChart
                title={
                  ahrefsData?.transactions?.label
                    ? ahrefsData?.transactions?.label
                    : 'Transactions'
                }
                chatData={transactions}
                category={['Transactions']}
                info={
                  ahrefsData?.transactions?.info
                    ? ahrefsData?.transactions?.info
                    : 'Transactions'
                }
                prevPeriod={ahrefsData?.transactions?.previous_period}
                previousYear={ahrefsData?.transactions?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(lastMonthTransactions)}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                propertyId={`Property ID: ${propertyId}`}
                retryMethod={retryFetchGA4Analytics}
              />
            </Col>
          )}
          <Col>
            <AreaCardChart
              title={
                ahrefsData?.refdomains?.label
                  ? ahrefsData?.refdomains?.label
                  : 'Referring Domains'
              }
              chatData={domainReferring}
              category={['Referring Domains']}
              info={
                ahrefsData?.refdomains?.info
                  ? ahrefsData?.refdomains?.info
                  : 'Referring Domains'
              }
              prevPeriod={ahrefsData?.refdomains?.previous_period}
              previousYear={ahrefsData?.refdomains?.previous_year}
              isLoading={refdomains.loading}
              totalValue={formatNumberWithCommas(lastMonthDomainReferring)}
              error={refdomains.error}
              icon="ahref"
              propertyId={selectedClient?.ahrefs?.domain ? selectedClient?.ahrefs?.domain : selectedClient?.domain ? selectedClient?.google_search_console?.site_url : 'N/A'}
              retryMethod={retryFetchAhrefDomains}
            />
          </Col>
          <Col>
            <AreaCardChart
              title={
                ahrefsData?.domain_rating?.label
                  ? ahrefsData?.domain_rating?.label
                  : 'Domain Rating'
              }
              chatData={domainRating}
              category={['Domain Rating']}
              info={
                ahrefsData?.domain_rating?.info
                  ? ahrefsData?.domain_rating?.info
                  : 'Domain Rating'
              }
              prevPeriod={ahrefsData?.domain_rating?.previous_period}
              previousYear={ahrefsData?.domain_rating?.previous_year}
              isLoading={domainRatingHistory.loading}
              totalValue={formatNumberWithCommas(lastMonthDomainRating)}
              error={domainRatingHistory.error}
              icon="ahref"
              propertyId={selectedClient?.ahrefs?.domain ? selectedClient?.ahrefs?.domain : selectedClient?.domain ? selectedClient?.google_search_console?.site_url : 'N/A'}
              retryMethod={retryFetchDomainRatingHistory}
            />
          </Col>
        </Grid>
      </div>
    </>
  );
};

export default LeadingIndicators;
