import React, { useEffect, useState } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import ReactSelect from 'react-select';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import api from '../../../api';
import DatePicker from 'react-datepicker';
interface WeeklyProps {
  selectDate?: any;
  closeModal: () => void;
  campaign?: any;
  clients?: any;
  integration?: any;
}

interface FlatObject {
  name?: string;
  domain?: string;
  refdomains?: any;
  account_manager?: string;
  consultant?: string;
  revenue?: number;
  start_date?: string;
  status?: string;
  cycle?: string;
  account_manager_health_status?:string;
  consultant_health_status?:string;
  organic_revenue?: number;
  organic_new_users?: number;
  organic_conversions?: number;
  new_users?: number;
  clicks?: number;
  impressions?: number;
  top_10_keywords_reach?: number;
  top_1_keywords_reach?: number;
  top_3_keywords_reach?: number;
  referring_domain?: number;
  costPerLead?: number;
  ecommerce_return?: number;
  conversion_rate?: number;
  cost_per_conversion?: number;
  cost?: number;
  ctr?: number;
  conversions?: number;
  conversions_value?: number;
  costPerConversion?: number;
  cpc?: number;
  roas?: number;
  reach?: number;
  frequency?: number;
  cpm?: number;
  link_clicks?: number;
  website_leads?: number;
  cpl?: number;
  cvr?: number;
  revenueMeta?: number;
}

const serviceTypeOptions = [
  { value: 'seo_google', label: 'Google SEO' },
  { value: 'ppc_google', label: 'Google Ads' },
  { value: 'social_meta', label: 'Meta' },
];
const campaignOptions = [
  { value: '', label: 'All Campaign' },
  { value: 'Lead Gen', label: 'Lead Gen' },
  { value: 'Ecommerce', label: 'Ecommerce' },
];

const clientsOptions = [
  { value: true, label: 'All Clients' },
  { value: false, label: 'My Client' },
];

const integrationOptions = [
  { value: 'all', label: 'Integrated' },
  { value: 'missing_performance', label: 'Missing Performance' },
  { value: 'missing_integration', label: 'Missing Integration' },
  // { value: 'integrated', label: 'Integrated' },
];
const ExportModal: React.FC<WeeklyProps> = ({
  selectDate,
  closeModal,
  campaign,
  clients,
  integration,
}) => {
  const [exportData, setExportData] = useState<any[]>([]);
  const [processedData, setProcessedData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const lastMonth = moment().subtract(1, 'month').format('MM-yyyy');
  const maxDate = moment(lastMonth, 'MM-yyyy').endOf('month').toDate();

  const today = new Date();
  today.setMonth(today.getMonth() - 1);

  const [date, setDate] = useState(selectDate ? moment(selectDate).toDate() : today);
  const [service, setService] = useState<{
    value: string;
    label: string;
  } | null>(serviceTypeOptions[0]);
  const [selectedCampaign, setSelectedCampaign] = useState<{
    label: string;
    value: string;
  } | null>(campaign ? campaign : campaignOptions[0]);

  const [selectedIntegration, setSelectedIntegration] = useState<{
    label: string;
    value: string;
  } | null>(integration ? integration : integrationOptions[0]);
  const [selectedClients, setSelectedClients] = useState<{
    label: string;
    value: boolean;
  } | null>(clients ? clients : clientsOptions[0]);

  const [loading, setLoading] = useState(false);
  const [downloadReady, setDownloadReady] = useState(false);

  // useEffect(() => {
  //   fetchExportData();
  // }, []);

  useEffect(() => {
    const flattenedData = exportData.map(flattenObject); // Process each entry in exportData
    setProcessedData(flattenedData); // Assume you have a setState for processedData
  }, [exportData]);

  const fetchExportData = async () => {
    if (!loading) {
      setLoading(true);
      setDownloadReady(false);
      setErrorMessage('');

      const params = {
        report_date: moment(date).format('YYYY-MM'),

        page: 1,
        limit: 10000,
        campaign_type: selectedCampaign?.value,
        service: service?.value,
        integration: selectedIntegration?.value,
        all: selectedClients?.value,
      };

      try {
        const res = await api.Performance.getPerformance(params);
        if (!res.error && res.data.length > 0) {
          // Check if data is non-empty
          setExportData(res.data);
          setDownloadReady(true); // Enable download only if data is non-empty
        } else {
          // Handle no data or error case
          setDownloadReady(false); // Ensure download is disabled
          setErrorMessage(
            'No data available to download. Please choose a different date and try again.'
          );
        }
      } catch (error) {
        console.error('An error occurred:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  function flattenObject(obj: any, service: any): FlatObject {

    let flatObject: FlatObject = {
      name: obj?.name,
      domain: obj?.domain,
      refdomains: obj?.ahrefs?.refdomains,
      account_manager: obj?.account_manager,
      account_manager_health_status: obj?.health_status?.find((obj: any) => obj?.role === 'am')
      ?.val || 'N/A',
      consultant: obj?.consultant,
      consultant_health_status: obj?.health_status?.find((obj: any) => obj?.role?.includes('con'))
      ?.val || 'N/A',
      revenue: obj?.revenue,
      start_date: obj?.start_date,
      cycle: obj?.cycle,
    };

    if (service?.value === 'seo_google') {
      flatObject = {
        ...flatObject,
        organic_revenue: obj?.ga4?.organic_revenue,
        organic_new_users: obj?.ga4?.organic_new_users,
        organic_conversions: obj?.ga4?.organic_conversions,
        new_users: obj?.ga4?.new_users,
        clicks: obj?.gsc?.clicks,
        impressions: obj?.gsc?.impressions,
        top_10_keywords_reach: obj?.ahrefs?.top_10_keywords_reach,
        top_1_keywords_reach: obj?.ahrefs?.top_1_keywords_reach,
        top_3_keywords_reach: obj?.ahrefs?.top_3_keywords_reach,
        referring_domain: obj?.ahrefs?.refdomains,
        costPerLead: obj?.return?.conversions,
        ecommerce_return: obj?.return?.ecommerce,
      };
    } else if (service?.value === 'ppc_google') {
      flatObject = {
        ...flatObject,
        clicks: obj?.googleAds?.clicks,
        conversion_rate: obj?.googleAds?.conversion_rate,
        conversions: obj?.googleAds?.conversions,
        conversions_value: obj?.googleAds?.conversions_value,
        costPerConversion: obj?.googleAds?.costPerConversion,
        cpc: obj?.googleAds?.cpc,
        ctr: obj?.googleAds?.ctr, // Click-through rate
        impressions: obj?.googleAds?.impressions,
        roas: obj?.googleAds?.roas,
      };
    } else if (service?.value === 'social_meta') {
      flatObject = {
        ...flatObject,
        impressions: obj?.meta?.impressions,
        reach: obj?.meta?.reach,
        frequency: obj?.meta?.frequency,
        cpm: obj?.meta?.cpm,
        link_clicks: obj?.meta?.link_clicks,
        ctr: obj?.meta?.ctr,
        website_leads: obj?.meta?.website_leads,
        cpl: obj?.meta?.cpl,
        cvr: obj?.meta?.cvr,
        revenue: obj?.meta?.revenue,
        roas: obj?.meta?.roas,
      };
    }

    return flatObject;
  }

  useEffect(() => {
    const flattenedData = exportData.map((item) =>
      flattenObject(item, service)
    ); // Correctly process each entry
    setProcessedData(flattenedData); // Update the state with processed data
  }, [exportData]);

  useEffect(() => {
    if (processedData.length > 0) {
      // Generate headers from the keys of the first object in processedData
      const headersFromData = Object.keys(processedData[0]).map((key) => ({
        label: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '), // Optional: Format the key to capitalize the first letter
        key: key,
      }));
      setHeaders(headersFromData);
    }
  }, [processedData]);
  return (
    <div className="flex w-[100%] h-[100%] justify-center pt-[10%]">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="flex flex-col h-[350px] w-[850px] rounded-lg bg-white p-5 dark:bg-gray-800"
      >
        <div className="flex justify-between items-center h-[20px] mb-4">
          <span>
            <Icon name="AlignLeft" />
          </span>
          <span onClick={closeModal} className="cursor-pointer text-lg">
            <Icon name="Xclose" />
          </span>
        </div>
        {selectDate && (
          <div className="flex justify-between gap-4 items-center">
            <div>
              <DatePicker
                selected={moment(date, 'YYYY-MM').toDate()}
                onChange={(date) => {
                  if (date !== null) {
                    setDate(date);
                    setDownloadReady(false);
                  }
                }}
                dateFormat="MMM yyyy"
                maxDate={maxDate}
                showMonthYearPicker
                className="react-datepicker__month-container text-center h-[40px] w-40 border border-[#D1D5DB] cursor-pointer myDatePicker dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-md text-[14px] text-[#525252]"
              />
            </div>
            <div>
              <ReactSelect
                options={campaignOptions}
                value={selectedCampaign}
                placeholder="All Campaign"
                onChange={(e) => {
                  setSelectedCampaign(e);
                  setDownloadReady(false);
                }}
                className="w-36"
              />
            </div>
            <div>
              <ReactSelect
                options={clientsOptions}
                value={selectedClients}
                placeholder="All Clients"
                onChange={(e) => {
                  setSelectedClients(e);
                  setDownloadReady(false);
                }}
                className="w-36"
              />
            </div>
            <div>
              <ReactSelect
                options={integrationOptions}
                value={selectedIntegration}
                placeholder="Integrated"
                onChange={(e) => {
                  setSelectedIntegration(e);
                  setDownloadReady(false);
                }}
                className="w-[210px]"
              />
            </div>
            <div>
              <ReactSelect
                options={serviceTypeOptions}
                onChange={(e) => {
                  setService(e);
                  setDownloadReady(false);
                }}
                value={service}
                placeholder="Google SEO"
                className="w-28"
              />
            </div>
          </div>
        )}

        <div className="flex justify-between gap-2 mt-4">
          {!downloadReady ? (
            <button
              className={`py-2 px-4 mt-4 bg-white hover:bg-blue-500 hover:text-white transition-opacity text-blue-500 border-blue-500 border rounded-md w-full text-center `}
              onClick={() => fetchExportData()}
            >
              {loading ? <Icon name="Loading" size={22} /> : 'Get Data'}
            </button>
          ) : (
            <CSVLink
              data={processedData}
              headers={headers}
              asyncOnClick={true}
              filename={`performance-${moment(new Date()).format('YYYY-MM')}.csv`}
              className="py-2 px-4 mt-4 bg-blue-500 hover:bg-blue-600 text-white rounded-md w-full text-center"
            >
              {loading ? <Icon name="Loading" size={22} /> : 'Export'} {`(${processedData?.length} Reports)`}
            </CSVLink>
          )}
        </div>
        {errorMessage.length > 0 && (
          <p className="text-red-500 mt-4 text-center">{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default ExportModal;