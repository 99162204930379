import { useRoutes, Navigate } from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout';
import AdminLayout from '../layouts/AdminLayout';
import ClientLayout from '../layouts/ClientLayout';
import LoginClient from '../pages/LoginClient';
import Clients from '../pages/Clients';
import Feedback from '../pages/Feedback';
import Referrals from '../pages/Referrals';
import { ReactElement } from 'react';
import Dashboard from '../pages/Dashboard';
import ClientDetails from '../pages/ClientDetails';
import Users from '../pages/Users/customer';
import UsersOMG from '../pages/Users/omg';
import Settings from '../pages/Settings';
import ForgotPassword from '../pages/ForgotPassword';
import useAuthStore from '../store/useAuthStore';
import Notification from '../pages/Notifications';
import Billing from '../pages/Billing';
import Home from '../pages/Home';
import InternalAnalytics from '../pages/InternalAnalytics';
import { isAdminRole } from '../utils';
import ClientsFeedback from '../pages/ClientsFeedback';
import Goals from '../pages/Goals';
import Reports from '../pages/Reports';
import Chat from '../pages/Chat';
import ResetPass from '../pages/ResetPass';
import ReportsV2 from '../pages/ReportsV2';
import WeeklyReport from '../pages/WeeklyReport';
import Insights from '../pages/Insights';
import OmgSettings from '../pages/OMGSettings';
import ClientAccess from '../pages/ClientAccess';
import AppTab from '../pages/ClientAccess/appTab';
import ChangeHistory from '../pages/ChangeHistory';
import ChangeRequest from '../pages/ChangeRequest';
import ChangeReports from '../pages/ChangeRequest/reports';
import Performance from '../pages/Performance';
import KPIs from '../pages/Kpis';
import Post from '../pages/Post';
import Crawler from '../pages/Tools/Crawler';
import PublicClientAnalytics from '../pages/PublicClientAnalytics';
import PerformanceInsights from '../pages/PerformanceInsights';
import LinksLookup from '../pages/LinksLookup';
import ReportDetailsMonthly from '../pages/ReportDetails/monthly';
import ReportDetailsWeekly from '../pages/ReportDetails/weekly';
import LinksLookupV2 from '../pages/LinksLookupV2';

import InternalAnalyticsGridLayout from '../pages/InternalAnalyticsGridLayout';

import ClientDashboard from '../components/ReactGridComponents/Components/ClientDashboard';
import DashboardPreview from '../components/ReactGridComponents/Preview/DashboardPreview';

import BillingClient from '../components/Settings/billing';
import Positions from '../pages/PositionOpportunities';
import ContactFinder from '../pages/ContactFinder';
import ContactBuilder from '../pages/ContactFinder/builder'

/* TRAFFIC TRACKING */
import { useAnalytics } from '../analytics/useAnalytics'
import EmailInsights from '../pages/EmailInsights';


export default function Router(): ReactElement | null {
  useAnalytics()
  const { token, user } = useAuthStore((state) => state);
  const isOmgUser = token && user?.role?.level === 'omg';
  const omgUrl = isAdminRole(user?.role?.id) ? '/home' : '/clients';
  const routeTo = isOmgUser ? omgUrl : '/dashboard';

  let element: ReactElement | null = useRoutes([
    {
      element: token ? <Navigate to={routeTo} /> : <AuthLayout />,
      children: [
        { path: '/', element: <LoginClient /> },
        // { path: '/sign-up', element: <SignUp /> },
        { path: '/forgot-password', element: <ForgotPassword /> },
        { path: '/reset-password', element: <ResetPass /> },
      ],
    },
    {
      element: isOmgUser ? <AdminLayout /> : <Navigate to="/" />,
      children: [
        { path: 'home', element: <Home /> },
        // { path: 'client-analytics', element: <Clients /> },
        { path: 'clients', element: <Clients /> },
        { path: '/client-users', element: <Users type={'users'} /> },
        { path: '/omg-users', element: <UsersOMG type={'omg'} /> },
        // { path: 'client/details/:id', element: <ClientDetails /> },
        // { path: 'client/details', element: <ClientDetails /> },
        { path: 'client/analytics/:type', element: <InternalAnalytics /> },
        // analytics grid
        { path: 'client/analytics-grid/:type', element: <InternalAnalyticsGridLayout /> },
        { path: 'client/analytics-grid/dashboard', element: <ClientDashboard /> },
        { path: 'client/analytics-grid/dashboard/preview', element: <DashboardPreview /> },

        { path: 'client/dashboard/:id', element: <Dashboard /> },
        // { path: 'client/analytics/:id', element: <Analytics /> },
        { path: 'client/notifications/:id', element: <Notification /> },
        { path: 'client/billing/:id', element: <Billing /> },
        { path: 'client-reporting', element: <ReportsV2 /> },
        { path: 'weekly-reporting', element: <WeeklyReport /> },
        { path: 'performance', element: <Performance /> },
        { path: 'insights-performance', element: <PerformanceInsights /> },

        // { path: 'performance', element: <WeeklyReport /> },

        { path: 'insights', element: <Insights /> },
        { path: 'client-wins', element: <ReportsV2 /> },
        { path: 'client-kpi', element: <ReportsV2 /> },
        { path: 'client/referrals', element: <Referrals /> },
        { path: 'user-settings', element: <Settings /> },
        { path: 'user-notifications', element: <Notification /> },
        { path: 'user-messages', element: <Notification /> },
        { path: 'clients-feedback', element: <ClientsFeedback /> },
        { path: 'client/details/:tab/:id', element: <ClientDetails /> },
        { path: 'client-access/all-clients', element: <ClientAccess /> },
        { path: 'client-access/all-clients/:tab', element: <AppTab /> },
        { path: 'client-access/send-request', element: <ClientAccess /> },
        { path: 'client-access/pending-request', element: <ClientAccess /> },
        { path: 'client-access/completed-request', element: <ClientAccess /> },
        { path: 'chat', element: <Chat /> },
        { path: 'goals', element: <Goals /> },
        { path: 'change-requests', element: <ChangeRequest /> },
        { path: 'kpis', element: <KPIs /> },
        { path: 'change-history', element: <ChangeHistory /> },
        { path: 'change-reports', element: <ChangeReports /> },
        { path: '/omg-settings/:type', element: <OmgSettings /> },
        { path: '/tools-links', element: <LinksLookupV2 /> },
        { path: 'tools/position-opportunity', element: <Positions /> },
        { path: 'reporting/email-insights', element: <EmailInsights /> },
        { path: 'tools/contact-finder', element: <ContactFinder /> },
        { path: 'tools/contact-finder/builder/:id', element: <ContactBuilder /> },
        { path: 'tools/crawler', element: <Crawler /> },
        { path: '*', element: <Navigate to="/" /> },
        { path: '/post/:id', element: <Post /> },
      ],
    },
    {
      element: token ? <ClientLayout /> : <Navigate to="/" />,
      children: [
        { path: 'dashboard', element: <Dashboard /> },
        {
          path: 'analytics/:type',
          element: <InternalAnalytics type="client" />,
        },
        { path: 'goals', element: <Goals /> },
        { path: 'reports', element: <Reports /> },
        { path: 'reports/details/monthly/:id', element: <ReportDetailsMonthly /> },
        { path: 'reports/details/weekly/:id', element: <ReportDetailsWeekly /> },
        { path: 'referrals', element: <Referrals /> },
        { path: 'billing', element: <BillingClient /> },
        { path: 'settings/:type', element: <Settings /> },
        { path: 'feedback', element: <Feedback /> },
        { path: 'chat', element: <Chat /> },
        { path: '*', element: <Navigate to="/" /> },
      ],
    },
    // public pages
    {
      path: 'client/dashboard',
      element: <PublicClientAnalytics />,
    },
  ]);

  return element;
}
