import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import { useThemeStore } from '../../../store/useThemeStore';
import { toQueryString } from '../../../utils/common';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperButtonNext from '../../SwiperButton/swiper';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import useAuthStore from '../../../store/useAuthStore';
import useDashboardStore from '../../../store/useDashboardStore';

interface IProps {
  isInternalAnalytics: boolean;
  isPublic: boolean;
}

const HeaderTab = ({ isInternalAnalytics, isPublic = false }: IProps) => {
  const location = useLocation();
  const { mode } = useThemeStore((state) => state);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {user} = useAuthStore((state) => state);
  const isOmgUser = user?.role?.level === 'omg';

  const { setServiceLine } = useDashboardStore(state => state);

  const gridBaseUrl = "analytics-grid"

  let analyticsServices = [
    {
      label: 'Google SEO',
      value: 'seo-ga4',
      icon: 'GoogleSEO',
      show: true,
    },
    {
      label: 'Google Ads',
      value: 'paid-search',
      icon: 'GoogleAdsAnalyticTab',
      show: true,
    },
    {
      label: 'Microsoft Ads',
      value: 'microsoft-ads',
      icon: 'BingAnalyticsTab',
      show: isOmgUser,
    },
    {
      label: 'Meta',
      value: 'fb-ads',
      icon: 'MetaAnalyticsTab',
      show: true,
    },
  ];
  analyticsServices = analyticsServices.filter((service) => service.show);
  const [selectedAnalyticsService, setSelectedAnalyticsService] = useState({
    label: '',
    value: '',
    icon: '',
  });

  useEffect(() => {
    if (
      location.pathname.includes('seo-ga4') ||
      (isPublic && searchParams.get('ppage') === 'seo-ga4')
    ) {
      setSelectedAnalyticsService({
        label: 'Google SEO',
        value: 'seo-ga4',
        icon: 'GoogleSEO',
      });
    } else if (
      location.pathname.includes('paid-search') ||
      (isPublic && searchParams.get('ppage') === 'paid-search')
    ) {
      setSelectedAnalyticsService({
        label: 'Google Ads',
        value: 'paid-search',
        icon: 'GoogleAdsAnalyticTab',
      });
    } else if (
      location.pathname.includes('fb-ads') ||
      (isPublic && searchParams.get('ppage') === 'fb-ads')
    ) {
      setSelectedAnalyticsService({
        label: 'Meta',
        value: 'fb-ads',
        icon: 'MetaAnalyticsTab',
      });
    } else if (
      location.pathname.includes('microsoft-ads') ||
      (isPublic && searchParams.get('ppage') === 'microsoft-ads')
    ) {
      setSelectedAnalyticsService({
        label: 'Microsoft Ads',
        value: 'microsoft-ads',
        icon: 'BingAnalyticsTab',
      });
    }
  }, [location.search]);

  const handleInternalAnalyticsServices = (selectedOption) => {
    setSelectedAnalyticsService(selectedOption);
    const newPath = `/client/${gridBaseUrl}/${selectedOption.value}`;
    const queryString = searchParams.toString();
    
    let service = 'seo'
    if(selectedOption?.value === "seo-ga4") service = 'seo'
    if(selectedOption?.value === "paid-search") service = 'google_ads'
    if(selectedOption?.value === "microsoft-ads") service = 'microsoft_ads'
    if(selectedOption?.value === "fb-ads") service = 'meta'
    
    setServiceLine(service)

    navigate(`${newPath}?${queryString}`);
  };

  const handleAnalyticsServices = (selectedOption) => {
    setSelectedAnalyticsService(selectedOption);
    const newPath = `/${gridBaseUrl}/${selectedOption.value}`;
    const queryString = searchParams.toString();

    let service = 'seo'
    if(selectedOption?.value === "seo-ga4") service = 'seo'
    if(selectedOption?.value === "paid-search") service = 'google_ads'
    if(selectedOption?.value === "microsoft-ads") service = 'microsoft_ads'
    if(selectedOption?.value === "fb-ads") service = 'meta'
    
    setServiceLine(service)
    
    navigate(`${newPath}?${queryString}`);
  };

  return (
    <>
      <div
        className={`relative hidden sm:flex items-center px-[24px] py-[16px] sm:pt-0 justify-between sm:px-0 sm:py-0 sm:justify-start sm:gap-2 md:gap-4 lg:gap-8 w-full scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8 `}
      >
        {analyticsServices.map((tab, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                if (isInternalAnalytics) {
                  handleInternalAnalyticsServices(tab);
                } else {
                  handleAnalyticsServices(tab);
                }
              }}
              className={`text-[15px] font-inter font-[600] sm:font-semibold text-[#9EACBD] flex gap-2 items-center sm:justify-start w-fit px-2`}
            >
              <div>
                <Icon
                  name={tab.icon}
                  size={18}
                  color={`${selectedAnalyticsService.label === tab.label && mode === 'dark' ? '#FAC515' : selectedAnalyticsService.label === tab.label ? '#0029FF' : mode === 'dark' ? '#FFF' : ''}`}
                />
              </div>
              <div
                className={`mt-[2px] ${selectedAnalyticsService.label === tab.label ? 'text-[#0029FF] dark:text-[#FAC515] font-[700] sm:font-semibold cursor-pointer' : 'dark:text-white cursor-pointer'}`}
              >
                {tab.label}
              </div>
            </div>
          );
        })}
      </div>
      <div className="relative block w-full sm:hidden">
        <Swiper
          className="h-full flex justify-between mySwiper mx-2 px-2"
          modules={[Pagination]}
          spaceBetween={2}
          slidesPerView={3}
        >
          <div className="bg-white">
            <SwiperButtonNext next={true}>
              <Icon
                name="ChevronRight"
                size={25}
                color={mode === 'dark' ? '#FFF' : '#000'}
              />
            </SwiperButtonNext>
          </div>
          {analyticsServices?.map((tab: any, index: number) => (
            <SwiperSlide
              key={index}
              onClick={() => {
                if (isInternalAnalytics) {
                  handleInternalAnalyticsServices(tab);
                } else {
                  handleAnalyticsServices(tab);
                }
              }}
            >
              <div
                className={`text-[15px] font-inter font-[600] sm:font-semibold text-[#9EACBD] flex gap-2  items-center sm:justify-start w-fit px-2`}
              >
                <div className="w-[18px] h-[18px]" />
                <div
                  className={`mt-[2px] ${selectedAnalyticsService.label === tab.label ? 'text-[#0029FF] dark:text-[#FAC515] font-[700] sm:font-semibold cursor-pointer' : 'dark:text-white cursor-pointer'}`}
                >
                  {tab.label}
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="bg-white">
            <SwiperButtonNext prev={true}>
              <Icon
                name="ChevronLeft"
                size={25}
                color={mode === 'dark' ? '#FFF' : '#000'}
              />
            </SwiperButtonNext>
          </div>
        </Swiper>
      </div>
    </>
  );
};

export default HeaderTab;
