import { useEffect, useState } from 'react';
import IndeterminateCheckbox from '../IndeterminateCheckbox';
import { formatToCurrency } from '../../utils/common';
import { useThemeStore } from '../../store/useThemeStore';
import Icon from '../../assets/icons/SvgComponent';
import GurulyticsTable from '../../components/GurulyticsTable';
import useXeroStore from '../../store/useXeroStore';
import useClientStore from '../../store/useClientStore';
import moment from 'moment';
import CustomModal from '../../components/CustomModal';
import PreviewModal from '../../components/Modals/Billing/PreviewModal';
import { ToastContainer, toast, Flip } from 'react-toastify';
import Alert from '../../components/Alert';
import ReactSelect from '../../components/ReactSelect';
import useAuthStore from '../../store/useAuthStore';

const Billing: React.FC = () => {
  const { fetchClient, selectedClient } = useClientStore((state) => state);
  const { selectedDomain } = useAuthStore((state) => state);
  const { fetchInvoices, invoices, fetchInvoicesPDF, loading, invoicesLink } = useXeroStore((state) => state);
  const [previewModal, setPreviewModal] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState(null);
  
  useEffect(() => {
    if (selectedDomain?.id) {
      fetchClient(selectedDomain?.id);
    }
  },[])

  useEffect(() => {
    handleFetchInvoices();
  }, [selectedClient, fetchInvoices, pagination, status]);

  const handleFetchInvoices = () => {
    const filters = {
      clientId: selectedDomain?.id,
      contactId: selectedClient?.xero?.contact_id,
      page: pagination,
      pageSize: limit,
      status: status?.value,
    };
    fetchInvoices(filters);
  };

  const handleFetchInvoicePDF = async (invoiceId, status) => {
    try {
      if (status === 'PAID') {
        setPreviewModal(true);
        await fetchInvoicesPDF(selectedClient?.id,invoiceId);
      } else {
        // Show a toast notification
        toast.info(
          <Alert
            icon=""
            title="REDIRECTING"
            message="Please wait for the invoice new tab"
          />,
          {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );

        // Fetch invoices and open in a new tab
        await fetchInvoicesPDF(selectedClient?.id,invoiceId);
        if (invoicesLink?.onlineInvoices?.[0]?.onlineInvoiceUrl) {
          window.open(
            invoicesLink.onlineInvoices[0].onlineInvoiceUrl,
            '_blank'
          );
        }
      }
    } catch (error) {
      console.error('Failed to fetch Link:', error);
    }
  };

  const columns = [
    {
      id: 'Invoice',
      header: () => (
        <div className="w-[20dvw] flex  justify-start font-inter text-xs text-[#475467]">
          Invoice No.
        </div>
      ),
      accessorKey: 'invoiceNumber',
      cell: (props: any) => (
        <div className="text-sm flex justify-start items-center w-[20dvw] font-inter gap-2">
          <div>
            <Icon name='File'/>
          </div>
          <div className="text-[#101828] text-sm font-[500]">
            {props.getValue()} {props?.row?.original?.reference && `- ${props?.row?.original?.reference}`}
          </div>
        </div>
      ),
    },
    // {
    //   id: 'Reference',
    //   header: () => (
    //     <div className="w-[12dvw] flex  justify-center font-inter dark:text-white">
    //       Reference
    //     </div>
    //   ),
    //   accessorKey: 'reference',
    //   cell: (props: any) => (
    //     <div className="flex w-[12dvw] justify-center cursor-pointer flex justify-center font-inter">
    //       <span className="text-[#001C44] text-[14px] font-[400] dark:text-white max-w-[12dvw] truncate">
    //         {props.getValue() || 'N/A'}
    //       </span>
    //     </div>
    //   ),
    // },
    {
      id: 'invoiceDate',
      header: () => (
        <div className="w-[10dvw] flex  justify-center font-inter text-xs text-[#475467]">
          Start Date
        </div>
      ),
      accessorKey: 'date',
      cell: (props: any) => (
        <div className="flex w-[10dvw] justify-center cursor-pointer flex justify-center font-inter">
          <span className="text-[#475467] text-sm font-[400] dark:text-white">
            {moment(props.getValue()).format('MMM D, YYYY')}
          </span>
        </div>
      ),
    },
    {
      id: 'invoiceDueDate',
      header: () => (
        <div className="w-[10dvw] flex  justify-center font-inter text-xs text-[#475467]">Due Date</div>
      ),
      accessorKey: 'dueDate',
      cell: (props: any) => (
        <div className="flex w-[10dvw] justify-center cursor-pointer flex justify-center font-inter">
          <span className="text-[#475467] text-sm font-[400] dark:text-white">
            {moment(props.getValue()).format('MMM D, YYYY')}
          </span>
        </div>
      ),
    },
    {
      id: 'Amount',
      header: () => (
        <div className="w-[10dvw] flex  justify-center font-inter dark:text-white text-xs text-[#475467]">
          Amount Paid
        </div>
      ),
      accessorKey: 'amountPaid',
      cell: (props: any) => {
        return (
          <div className="flex w-[10dvw] justify-center cursor-pointer flex justify-center font-inter">
            <span className="text-[#475467] text-sm font-[400]">
              {formatToCurrency(
                props.getValue(),
                2,
                props.row.original.currencyCode
              )}
            </span>
          </div>
        );
      },
    },
    {
      id: 'AmountDue',
      header: () => (
        <div className="w-[7dvw] flex  justify-center font-inter dark:text-white text-xs text-[#475467]">
          Amount Due
        </div>
      ),
      accessorKey: 'amountDue',
      cell: (props: any) => {
        return (
          <div className="flex w-[7dvw] justify-center cursor-pointer flex justify-center font-inter">
            <span className="text-[#475467] text-sm font-[400]">
              {formatToCurrency(
                props.getValue(),
                2,
                props.row.original.currencyCode
              )}
            </span>
          </div>
        );
      },
    },

    {
      id: 'Status',
      header: () => (
        <div className="w-[7dvw] flex  justify-center font-inter text-xs text-[#475467]">Status</div>
      ),
      accessorKey: 'status',
      cell: (props: any) => (
        <div className="w-[7dvw] flex  justify-center font-inter">
          <div className="flex items-center gap-2 border-[#80AECF] font-inter">
            <div
              className={
              ` ${ props.getValue() === 'OVERDUE'
                  ? 'flex items-center justify-center gap-1 border-2  text-[#FFCA00] border-[#FFCA00] text-gray-800 text-xs font-[500]  px-1.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300 bg-[#FECDCA]'
                  : 'flex items-center justify-center gap-1 border-2 text-[#067647]  border-[#067647] text-gray-800 text-xs font-[500]  px-1.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300 bg-[#ECFDF3]'}
                  text-xs
                  `
              }
            >
            <div>
              <Icon name='Hourglass' color={props.getValue() === 'OVERDUE' ? "#B42318":"#17B26A"}/>
            </div>
              <span
                className={
                  props.getValue() === 'Pending'
                    ? 'text-[#FFCA00]'
                    : 'text-[#067647]'
                }
              >
                {props.getValue()}
              </span>
            </div>
          </div>
        </div>
      ),
    },

    {
      id: 'Action',
      header: () => (
        <div className="w-[8dvw] flex justify-center font-inter text-xs text-[#475467]">Action</div>
      ),
      accessorKey: 'invoiceID',
      cell: (props: any) => (
        <div
          className="w-[8dvw] flex gap-4 justify-start cursor-pointer text-sm font-[600] text-[#0029FF] flex justify-center font-inter dark:text-white"
          onClick={() => {
            handleFetchInvoicePDF(
              props?.getValue(),
              props?.row?.original?.status
            );
          }}
        >
          {/* {props?.row?.original?.status !== 'PAID' ? (
            <Icon name="ArrowUpRight" size={20} color="#0029FF" />
          ) : (
            <Icon name="eye" />
          )} */}
          View
        </div>
      ),
    },
  ];

  return (
    <div className="w-full font-inter p-[24px]">
      <div className='flex justify-between items-center mb-[24px]'>
        <div>
          <div className='font-archivo font-[600] text-[#101828] text-lg dark:text-white'>Billing</div>
          <div className='text-[#475467] text-sm'>Manage your billings, view payment statuses and detailed billing records with ease.</div>
        </div>
        <div className="flex justify-end items-center gap-4">
          <div className="w-[160px]">
            <ReactSelect
              value={status}
              options={[
                {
                  label: 'All Status',
                  value: '',
                },
                {
                  label: 'OPEN',
                  value: 'AUTHORISED',
                },
                {
                  label: 'OVERDUE',
                  value: 'OVERDUE',
                },
              ]}
              placeholder="All Status"
              handleOnChange={(selectedOption: any) => setStatus(selectedOption)}
            />
          </div>
          
        </div>
      </div>
      <div className="bg-white  dark:bg-[#1F2937] rounded-md">
        <GurulyticsTable
            data={invoices?.invoices || []}
            columns={columns}
            totalPages={invoices?.pagination?.pageCount}
            limit={limit}
            setLimit={setLimit}
            loading={loading}
            page={pagination}
            setPage={setPagination}
            tableHeight="h-[70vh]"
            totalDocs={invoices?.pagination?.itemCount}
            errorMsg={!selectedClient?.xero?.contact_id 
                ? "XERO billing is not yet set up" 
                : "Your account is up to date. You have no outstanding invoices"
            }
          />
      </div>

      <CustomModal open={previewModal} onClose={() => setPreviewModal(false)}>
        <PreviewModal
          invoiceLink={
            invoicesLink?.onlineInvoices?.[0]?.onlineInvoiceUrl || ''
          }
          onClose={() => setPreviewModal(false)}
        />
      </CustomModal>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};
export default Billing;
