import React, { useState, useEffect, useMemo } from 'react';
import BarCharts from '../../components/Insights/BarChart/positionOpportunity';
import PieChart from '../../components/Insights/PieChart/positionOpportunity';
import 'react-datepicker/dist/react-datepicker.css';
import useToolStore from '../../store/useToolStore';
import useAuthStore from '../../store/useAuthStore';
import useUserStore from '../../store/useUserStore';
import ReactSelect from '../../components/ReactSelect';
import { useLocation, useParams } from 'react-router-dom';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface StackedBarChartProps {
  data: {
      name: string;
      '1-3': number;
      '4-10': number;
      '11-20': number;
      '21-50': number;
      '51-100': number;
  }[];
}

const StackedBarChart: React.FC<StackedBarChartProps> = ({ data }) => {
  return (
      <ResponsiveContainer width="60%" height={300}>
          <BarChart data={data}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="1-3" stackId="a" fill="#fdc23d" />
              <Bar dataKey="4-10" stackId="a" fill="#016dc9" />
              <Bar dataKey="11-20" stackId="a" fill="#008ff8" />
              <Bar dataKey="21-50" stackId="a" fill="#2cb3ff" />
              <Bar dataKey="51-100" stackId="a" fill="#8fcdff" radius={[10, 10, 0, 0]}/>
          </BarChart>
      </ResponsiveContainer>
  );
};

const ContactFinder = () => {
  const [activeTab, setActiveTab] = useState('potentialLeads');
  const { currentUser } = useAuthStore((state) => state);
  const { fetchContacts, fetchCompetitorData, fetchQuotas, loading } = useToolStore((state) => state);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [reportData, setReportData] = useState({});
  const [payload, setPayload] = useState({
    role: '',
    force: false,
    hubspot_id: '',
    duplicate: false,
    duplicateData: {},
    data: [],
    userEmail: '',
    emailData:{},
    emailLoading: false,
    keyword: '',
    database: '',
    databaseLabel: '',
    balance: '',
    semBalance: '',
    resets: '',
    selectedItem: null,
    emailItem: null,
    showModal: false,
    showEmailModal: false,
    chartData: [],
    fourtoten: null,
    intent1:{},
    intent2:{},
    intent3:{},
  });
  const location = useLocation();
  const client_id = location.pathname.split('/')[4];
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const formatMonthYear = (month, year) => `${year}-${String(month + 1).padStart(2, '0')}`;
  const previousMonthYear = formatMonthYear(currentMonth === 0 ? 11 : currentMonth - 1, currentMonth === 0 ? currentYear - 1 : currentYear);

  const { 
    data, 
    force,
    hubspot_id,
    duplicate,
    duplicateData,
    userEmail,
    emailData, 
    emailLoading,
    keyword, 
    database, 
    databaseLabel, 
    balance, 
    semBalance, 
    resets, 
    selectedItem, 
    emailItem, 
    showModal, 
    showEmailModal, 
    chartData, 
    fourtoten,
    intent1,
    intent2,
    intent3 } = payload;

  const dbOptions = [
    { label: 'United States', value: 'US' },
    { label: 'United Kingdom', value: 'GB' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'Australia', value: 'AU' },
    { label: 'Canada', value: 'CA' },
    { label: 'India', value: 'IN' },
  ];

  const handleRowClick = (item, index) => {
    let merged = {...item, index: index}
    setPayload(prevPayload => ({
      ...prevPayload,
      selectedItem: merged,
      showModal: true
    }));
  };

  const SideModal = ({ item, onClose }) => {
    if (!item) return null;
    item.contacts.sort((a, b) => {
      if (a.position && !b.position) return -1;
      if (!a.position && b.position) return 1;
      return 0;
    })
    return (
      <>
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={onClose}
        />
        <div style={{top: '4rem', width: '40%'}} className="fixed right-0 top-6 h-full w-100 bg-white dark:bg-gray-800 shadow-lg z-50 p-4 overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Contact Details</h2>
            <button 
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          </div>
          
          <div className="space-y-4">
            <div>
              <h3 className="font-semibold">Page Title</h3>
              <p>{item.title}</p>
            </div>

            <div>
              <h3 className="font-semibold">Position</h3>
              <p>{item.index}</p>
            </div>

            <div>
              <h3 className="font-semibold">Domain</h3>
              <p>{item.displayLink}</p>
            </div>

            <div>
              <h3 className="font-semibold">URL</h3>
              <a href={item.link} target="_blank" className="text-blue-600 hover:underline">{item.link}</a>
            </div>

            <div>
              <h3 className="font-semibold">Contacts ({item.contacts?.length || 0})</h3>
              <div className="space-y-2">
                {item.contacts?.map((contact, index) => (
                  <div key={index} className="p-2 bg-gray-50 dark:bg-gray-700 rounded">
                    <p><strong>Email:</strong> {contact.email}</p>
                    <p><strong>Name:</strong> {contact.firstName ? contact.firstName : 'Unknown'} {contact.lastName ? contact.lastName : ''}</p>
                    <p><strong>Position:</strong> {contact.position ? contact.position : 'Unknown'}</p>
                    <p><strong>Hubspot:</strong> {contact.isInCRM?.isInCRM ? <a target="_blank" href={'https://app.hubspot.com/contacts/1590785/record/0-1/'+contact.isInCRM.details.id} className="px-2 py-1 text-s text-red-500">Yes</a> : <span className="px-2 py-1 text-s text-blue-500 border-blue-500">New</span>}</p>
                    <button
                    className="mt-4 rounded-lg text-white text-sm border border-[#005C9F] bg-[#009f50] shadow-xs px-2 py-1 dark:text-[#E6EFF5]"
                    onClick={() => handleFetchEmailReport(item, contact)}>Generate Email</button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const EmailModal = ({ item, onClose }) => {
    if (!item) return null;
    console.log('Inside Email', item)
    console.log('Email Data: ', emailData)
    console.log('current rankings: ', fourtoten)
    const options = (emailData as { competitors: { keywords: { Keyword: string; Position: string; 'Search Volume': string; CPC: string; Competition: string; }[] } }).competitors?.keywords?.map(kw => ({
      value: kw, 
      label: kw.Keyword
    })) || [];
    return emailLoading || !fourtoten ? 
    (
      <>
      <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={onClose}
        />
        <div style={{top: '4rem', width: '75%'}} className="fixed right-0 h-full bg-white dark:bg-gray-800 shadow-lg z-50 p-4 overflow-x-auto">
          <div className="flex justify-between items-center mb-4">
            <div>
              <h2 className="text-xl font-bold">Building Email...</h2>
            </div>
          </div>
        </div>
      </>
      ) : (
      <>
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={onClose}
        />
        <div style={{top: '4rem', width: '75%'}} className="fixed right-0 h-full bg-white dark:bg-gray-800 shadow-lg z-50 p-4 overflow-x-auto">
          <div className="flex justify-between items-center mb-4">
            <div>
              <h2 className="text-xl font-bold">Email Template</h2>
            </div>
            <div className="flex items-center justify-between">
            <div className="mr-4"><strong>KEYWORDS: </strong></div>
              <div className="flex space-x-4"> {/* Added flex and spacing */}
              
                <div>
                  
                  <ReactSelect
                    options={options}
                    value={options.find(option => option.value === intent1) || null}
                    handleOnChange={(selectedOption: any) => {
                      setPayload(prevPayload => ({
                        ...prevPayload,
                        intent1: selectedOption.value,
                      }));
                    }}
                    placeholder="Select a 1st Intent Keyword"
                  />
                </div>
                <div>
                  <ReactSelect
                    options={options}
                    value={options.find(option => option.value === intent2) || null}
                    handleOnChange={(selectedOption: any) => {
                      setPayload(prevPayload => ({
                        ...prevPayload,
                        intent2: selectedOption.value,
                      }));
                    }}
                    placeholder="Select a 2nd Intent Keyword"
                  />
                </div>
                <div>
                  <ReactSelect
                    options={options}
                    value={options.find(option => option.value === intent3) || null}
                    handleOnChange={(selectedOption: any) => {
                      setPayload(prevPayload => ({
                        ...prevPayload,
                        intent3: selectedOption.value,
                      }));
                    }}
                    placeholder="Select a 3rd Intent Keyword"
                  />
                </div>
              </div>
              <button 
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 ml-6"
              >
                ✕
              </button>
            </div>
          </div>
          
          <div className="space-y-4">
            <div>
              <h3 className="font-semibold"></h3>
              <p><strong>Subject:</strong> </p>
              <p>You're not being found for <strong>RELEVANT_CLIENT_SERVICE</strong></p>
              <hr className="mt-4 mb-4"/>
              <p className="mb-4">Hey {(emailData as { contact?: { firstName: string } }).contact?.firstName || 'there'},</p>
              <p className="mb-4">I hope you're well and don't mind me reaching out.</p>
              <p className="mb-4">We've worked with <strong>RELEVANT_CLIENTS</strong> and many others.</p>
              <p className="mb-4">Our team are running free online performance audits on the lead up to <strong>UPCOMING_EVENT</strong> and would love to take a look at the opportunities available to <strong>{item.displayLink}</strong> from an SEO/Ads perspective, free of charge. It's essentially a digital health check to ensure all your campaigns are performing optimally.</p>
              <p className="mb-4">As it stands, <strong>{item.displayLink} is ranking for {(emailData as { competitors?: { keywordTotals: Array<{ "Organic Keywords": number }> } }).competitors?.keywordTotals?.[0]?.["Organic Keywords"] || 0} keywords</strong> which suggests a lot of room for growth and improvement in terms of online visibility. Of these keywords, <strong>{fourtoten['4-10']} are in positions 4-10 on Google which is within striking distance of where you want them to be.</strong> </p>
              <ul className="mb-4">
                <li key={1}>
                  {Object.keys(intent1).length > 0 ? (
                    <>
                      <strong>&quot;{(intent1 as { Keyword: string; 'Search Volume': string; Position: string }).Keyword}&quot;</strong> is searched <strong>{(intent1 as { 'Search Volume': string })['Search Volume']}</strong> times per month and you're on <strong>page {Math.ceil(Number((intent1 as { Position: string }).Position) / 10)}</strong> of Google
                    </>
                  ) : (
                    <strong style={{ color: 'red' }}>Waiting for keyword 1 selection...</strong>
                  )}
                </li>
                <li key={2}>
                  {Object.keys(intent2).length > 0 ? (
                    <>
                      <strong>&quot;{(intent2 as { Keyword: string; 'Search Volume': string; Position: string }).Keyword}&quot;</strong> is searched <strong>{(intent2 as { 'Search Volume': string })['Search Volume']}</strong> times per month and you're on <strong>page {Math.ceil(Number((intent2 as { Position: string }).Position) / 10)}</strong> of Google
                    </>
                  ) : (
                    <strong style={{ color: 'red' }}>Waiting for keyword 2 selection...</strong>
                  )}
                </li>
                <li key={3}>
                  {Object.keys(intent3).length > 0 ? (
                    <>
                      <strong>&quot;{(intent3 as { Keyword: string; 'Search Volume': string; Position: string }).Keyword}&quot;</strong> is searched <strong>{(intent3 as { 'Search Volume': string })['Search Volume']}</strong> times per month and you're on <strong>page {Math.ceil(Number((intent3 as { Position: string }).Position) / 10)}</strong> of Google
                    </>
                  ) : (
                    <strong style={{ color: 'red' }}>Waiting for keyword 3 selection...</strong>
                  )}
                </li>
              </ul>
              <p className="mb-4">There is a lot of opportunity to direct more traffic and sales to your site. Plus, there are hundreds of additional high performing keywords which you're not yet ranking for.</p>
              <p className="mb-4"><strong>SCREENSHOT_GRAPH_BELOW</strong></p>
              <p className="mb-4">If you're working with an agency at the moment or managing your marketing efforts inhouse, this will analyse how your campaigns are performing and we'll offer suggestions for improvement going forward.</p>
              <p className="mb-4"><strong>RELEVANT_CASE_STUDY</strong></p>
              <p className="mb-4">Keen to connect if you have some time later today, otherwise this / next week? Let me know if you have any questions.</p>
              <p className="mb-12"> Book a time for a quick chat <strong style={{color:'blue'}}>MEETING_LINK_HERE</strong>.</p>  
              <div className="space-y-4 mt-4 mb-4">
                <StackedBarChart data={payload.chartData} />
              </div>      
              <p className="mb-6">&nbsp;</p> 
              <p>&nbsp;</p>   
            </div>
          </div>
          
        </div>
      </>
    );
  };

  const handleFetchEmailReport = (item, contact) => {
    setError('');
    setPayload(prevPayload => ({
        ...prevPayload,
        emailItem: item,
        showEmailModal: true,
        emailLoading: true // Set loading to true when fetching data
    }));

    fetchCompetitorData({ domain: item.displayLink, database: database })
        .then(competitors => {
            if (competitors.success === false) {
                setError(competitors.message);
                setPayload(prevPayload => ({
                    ...prevPayload,
                    emailLoading: false // Set loading to false on error
                }));
                return; // Exit early on error
            }

            const aggregatedData: { [key: string]: { [key: string]: number } } = {};

            competitors.data.keywordHistory.forEach(entry => {
                const { year, month, Position } = entry;
                const monthYear = `${year}-${month}`;

                let category;
                if (Position >= 1 && Position <= 3) {
                    category = '1-3';
                } else if (Position >= 4 && Position <= 10) {
                    category = '4-10';
                } else if (Position >= 11 && Position <= 20) {
                    category = '11-20';
                } else if (Position >= 21 && Position <= 50) {
                    category = '21-50';
                } else if (Position >= 51 && Position <= 100) {
                    category = '51-100';
                } else {
                    return; // Skip if position is out of range
                }

                if (!aggregatedData[monthYear]) {
                    aggregatedData[monthYear] = {
                        '1-3': 0,
                        '4-10': 0,
                        '11-20': 0,
                        '21-50': 0,
                        '51-100': 0
                    };
                }

                aggregatedData[monthYear][category] += 1;
            });

            const chartFormatter = Object.entries(aggregatedData).map(([monthYear, categories]) => ({
                name: monthYear,
                '1-3': categories['1-3'] || 0,
                '4-10': categories['4-10'] || 0,
                '11-20': categories['11-20'] || 0,
                '21-50': categories['21-50'] || 0,
                '51-100': categories['51-100'] || 0
            }));

            console.log('Aggregated Data:', aggregatedData); // Debugging information
            console.log('Chart Formatter:', chartFormatter); // Debugging information
            console.log('Previous Month Year:', previousMonthYear); // Log the value of previousMonthYear

            const foundFourtoten = chartFormatter.find(item => item.name === previousMonthYear);
            console.log('Found Fourtoten:', foundFourtoten); // Debugging information

            setPayload(prevPayload => ({
                ...prevPayload,
                emailData: { contact: contact, competitors: competitors.data },
                fourtoten: foundFourtoten,
                chartData: chartFormatter.reverse(),
                emailLoading: false // Set loading to false after data is received
            }));
        })
        .catch(err => {
            console.error('Error fetching competitor data:', err); // Log the error
            setError('An error occurred while fetching data.');
            setPayload(prevPayload => ({
                ...prevPayload,
                emailLoading: false // Set loading to false on error
            }));
        });
  };

  const forceSend = () => {
    setPayload(prevPayload => {
      const updatedPayload = {
          ...prevPayload,
          force: true,
          userEmail: currentUser.email,
          duplicate:false,
          duplicateData:{}
      };
      fetchContacts(payload)
      .then(data => {
        console.log(data)
        if(data.success === false){
          if(data.data.duplicate === true){
            setPayload(prevPayload => ({
              ...prevPayload,
              emailLoading:false,
              duplicate: true,
              duplicateData: data.data,
            }));
          }else{
            setPayload(prevPayload => ({
              ...prevPayload,
              emailLoading:false,
              duplicate: false,
              duplicateData: {},
              
            }));
            setError(data.message);
            setSuccess(null);
          }
        }else{
          setPayload(prevPayload => ({
            ...prevPayload,
            emailLoading:true,
            keyword: '',
            database: '',
            databaseLabel:'',
            duplicate: false,
            duplicateData: {},
          }));
          setSuccess(data.data.message);
          setError(null);
        }
      })
      return updatedPayload;
    })
  }
  const handleFetchingPositions = () => {
    if(keyword == ''){
      setError('keyword cannot be empty');
      return;
    }
    if(database == ''){
      setError('Please select a region to analyze');
      return;
    }

    setError('');
    setPayload(prevPayload => ({
      ...prevPayload,
      emailLoading:true
    }));
    
    fetchContacts(payload)
    .then(data => {
      setSuccess(null); 
      setError(null);
      if (data.success === false) {
        if (data.data.duplicate === true) {
          setPayload(prevPayload => ({
            ...prevPayload,
            emailLoading: false,
            duplicate: true,
            duplicateData: data.data,
          }));
        } else {
          setPayload(prevPayload => ({
            ...prevPayload,
            emailLoading: false,
            duplicate: false,
            duplicateData: {},
          }));
          setError(data.message);
          setSuccess(null);
        }
      } else {
        setSuccess(data.data.message); 
        setError(null);
        setPayload(prevPayload => ({
          ...prevPayload,
          emailLoading: true,
          keyword: '',
          database: '',
          databaseLabel: '',
          duplicate: false,
          duplicateData: {},
        }));
      }
    })
    .catch(err => {
      console.error('Error fetching contacts:', err);
      setError('An error occurred while fetching contacts.');
      setSuccess(null);
    });
  }

  const onInput = (e) => {
    setPayload(prevPayload => ({
      ...prevPayload,
      keyword: e.target.value,
    }));
  }

  useEffect(() => {
    fetchQuotas()
      .then((response) => {
        setPayload(prevPayload => ({
          ...prevPayload,
          balance: response.data.snov.data.balance,
          semBalance: response.data.semRush,
          resets: response.data.snov.data.limit_resets_in,
          userEmail: currentUser.email,
          loading: false
        }));
      })
      .catch(error => {
        console.error('Error fetching quotas:', error);
        setPayload(prevPayload => ({
          ...prevPayload,
          loading: false,
          error: 'Failed to load quotas'
        }));
      });
  }, []);

  return (
    <>
      <div className="p-6 kpis bg-white dark:bg-gray-800 rounded-xl shadow-sm">
        <div className="flex flex-col space-y-4">
          <div className="flex items-center justify-between">
            <h1 className="text-xl font-bold font-inter text-[#001C44] dark:text-white">
              Prospect Finder
            </h1>
            <div className="hidden md:flex items-center space-x-2">
              <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                </svg>
                API Status: Active
              </span>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2">
            <div className="bg-gradient-to-r from-blue-50 to-indigo-50 dark:from-blue-900/30 dark:to-indigo-900/30 p-3 rounded-lg border border-blue-100 dark:border-blue-800">
              <div className="flex items-center">
                <div className="p-2 bg-blue-100 dark:bg-blue-800 rounded-md mr-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-600 dark:text-blue-300" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                  </svg>
                </div>
                <div>
                  <h3 className="text-xs font-semibold text-gray-600 dark:text-gray-300">SNOV</h3>
                  <div className="flex items-center mt-1">
                    <span className="text-sm font-bold text-gray-800 dark:text-white">{balance} Credits</span>
                    <span className="ml-2 px-1.5 py-0.5 text-xs rounded bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">Resets in {resets}d</span>
                  </div>
                  <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">~96 Credits per search</p>
                </div>
              </div>
            </div>
            
            <div className="bg-gradient-to-r from-purple-50 to-pink-50 dark:from-purple-900/30 dark:to-pink-900/30 p-3 rounded-lg border border-purple-100 dark:border-purple-800">
              <div className="flex items-center">
                <div className="p-2 bg-purple-100 dark:bg-purple-800 rounded-md mr-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-purple-600 dark:text-purple-300" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 2h10v7h-2l-1 2H8l-1-2H5V5z" clipRule="evenodd" />
                  </svg>
                </div>
                <div>
                  <h3 className="text-xs font-semibold text-gray-600 dark:text-gray-300">SEMRUSH</h3>
                  <div className="flex items-center mt-1">
                    <span className="text-sm font-bold text-gray-800 dark:text-white">{semBalance} Credits</span>
                  </div>
                  <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">~3000 Credits per email</p>
                </div>
              </div>
            </div>
            
            <div className="bg-gradient-to-r from-green-50 to-teal-50 dark:from-green-900/30 dark:to-teal-900/30 p-3 rounded-lg border border-green-100 dark:border-green-800">
              <div className="flex items-center">
                <div className="p-2 bg-green-100 dark:bg-green-800 rounded-md mr-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-600 dark:text-green-300" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                  </svg>
                </div>
                <div>
                  <h3 className="text-xs font-semibold text-gray-600 dark:text-gray-300">GOOGLE</h3>
                  <div className="flex items-center mt-1">
                    <span className="text-sm font-bold text-gray-800 dark:text-white">10 Free Searches</span>
                    <span className="ml-2 px-1.5 py-0.5 text-xs rounded bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">Daily</span>
                  </div>
                  <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">$5 per 10 searches after</p>
                </div>
              </div>
            </div>
          </div>
          
          {!loading ? (
            <div className="mt-6">
              <div className="flex flex-col md:flex-row md:items-center gap-4">
                <div className="relative flex-grow">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <input
                    value={keyword}
                    name={'keyword'}
                    onChange={(e) => onInput(e)}
                    placeholder="Enter keyword to find prospects..."
                    className="block w-full pl-10 pr-3 py-2.5 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                
                <div className="w-full md:w-64">
                  <ReactSelect
                    options={dbOptions}
                    value={database}
                    placeholder={databaseLabel ? databaseLabel : 'Select Region'}
                    handleOnChange={(selectedOption: any) =>
                      setPayload(prevPayload => ({
                        ...prevPayload,
                        database: selectedOption.value,
                        databaseLabel: selectedOption.label,
                      }))
                    }
                  />
                </div>
                
                <button
                  className="w-full md:w-auto px-5 py-2.5 text-sm font-medium text-white bg-gradient-to-r from-blue-600 to-indigo-700 hover:from-blue-700 hover:to-indigo-800 rounded-lg shadow-sm transition-all duration-150 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800"
                  onClick={() => handleFetchingPositions()}
                >
                  <div className="flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                    </svg>
                    Fetch Prospects
                  </div>
                </button>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center p-6 mt-4 bg-gray-50 dark:bg-gray-700/30 rounded-lg">
              <div className="flex flex-col items-center">
                <div className="flex space-x-2 mb-3">
                  <div className="h-2.5 w-2.5 bg-blue-600 rounded-full animate-bounce"></div>
                  <div className="h-2.5 w-2.5 bg-blue-600 rounded-full animate-bounce [animation-delay:-.3s]"></div>
                  <div className="h-2.5 w-2.5 bg-blue-600 rounded-full animate-bounce [animation-delay:-.5s]"></div>
                </div>
                <div className="text-sm font-medium text-gray-600 dark:text-gray-300">Searching for prospects...</div>
              </div>
            </div>
          )}
          
          {duplicate && (
            <div className="mt-4 p-4 border-l-4 border-yellow-400 bg-yellow-50 dark:bg-yellow-900/20 dark:border-yellow-600 rounded-md">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700 dark:text-yellow-200">
                    {(duplicateData as { message: string; payload: { report_id: string } }).message} found here: <a target={'_blank'} className="font-medium underline text-yellow-700 dark:text-yellow-200 hover:text-yellow-600 dark:hover:text-yellow-100" href={`/tools/contact-finder/builder/` + (duplicateData as { message: string; payload: { report_id: string } }).payload.report_id}>{(duplicateData as { message: string; payload: { report_id: string } }).payload.report_id}</a>
                  </p>
                  <p className="mt-2 text-sm text-yellow-700 dark:text-yellow-200">
                    Alternatively, you can <button className="font-medium underline text-yellow-700 dark:text-yellow-200 hover:text-yellow-600 dark:hover:text-yellow-100" onClick={() => forceSend()}>force a fresh search</button> if needed.
                  </p>
                </div>
              </div>
            </div>
          )}
          
          {success && (
            <div className="mt-4 p-4 border-l-4 border-green-400 bg-green-50 dark:bg-green-900/20 dark:border-green-600 rounded-md">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-green-800 dark:text-green-200">{success}</p>
                </div>
              </div>
            </div>
          )}
          
          {error && (
            <div className="mt-4 p-4 border-l-4 border-red-400 bg-red-50 dark:bg-red-900/20 dark:border-red-600 rounded-md">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-red-800 dark:text-red-200">{error}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        
        {!loading && data.length > 0 && (
          <div className="mt-6">
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm overflow-hidden">
              <div className="border-b border-gray-200 dark:border-gray-700">
                <div className="px-4 py-3 flex space-x-2">
                  <button
                    className={`px-4 py-2 text-sm font-medium rounded-md transition-all duration-150 ${
                      activeTab === 'potentialLeads'
                        ? 'bg-blue-600 text-white shadow-sm'
                        : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                    }`}
                    onClick={() => setActiveTab('potentialLeads')}
                  >
                    <div className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                      </svg>
                      Potential Leads
                    </div>
                  </button>
                  <button
                    className={`px-4 py-2 text-sm font-medium rounded-md transition-all duration-150 ${
                      activeTab === 'competitors'
                        ? 'bg-blue-600 text-white shadow-sm'
                        : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                    }`}
                    onClick={() => setActiveTab('competitors')}
                  >
                    <div className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                      </svg>
                      Top 30
                    </div>
                  </button>
                </div>
              </div>
              {activeTab === 'competitors' ? (
                <>
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 font-inter">
                  <thead>
                    <tr>
                      <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                        SERP Position
                      </th>
                      <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                        Page Title
                      </th>
                      <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                        Domain
                      </th>
                      <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                        Contacts Found
                      </th>
                      <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                        Hubspot Found
                      </th>
                      <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                        Full URL
                      </th>
                    </tr>
                  </thead>
                    <tbody>
                      {data.slice(0, Math.min(30, data.length)).map((item, index) => (
                        <tr onClick={() => handleRowClick(item, (index + 1))} key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 w-fit max-w-[300px] z-40">
                          <td className='z-10  p-2 min-w-fit'>{index + 1}</td>
                          <td className='z-10  p-2 min-w-fit'>{item.title}</td>
                          <td className='z-10  p-2 min-w-fit'>{item.displayLink}</td>
                          <td className='z-10  p-2 min-w-fit'>{item.contacts.length}</td>
                          <td className='z-10  p-2 min-w-fit'>
                            {item.contacts?.reduce((count, contact) => {
                              return count + (contact.isInCRM?.isInCRM ? 1 : 0)
                            }, 0)}
                          </td>
                          <td className='z-10  p-2 min-w-fit'><a style={{color: '#005C9F'}} href={item.link} target="_blank">{item.link}</a></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <>
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 font-inter">
                    <thead>
                      <tr>
                        <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                          SERP Position
                        </th>
                        <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                          Page Title
                        </th>
                        <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                          Domain
                        </th>
                        <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                          Contacts Found
                        </th>
                        <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                          Hubspot Found
                        </th>
                        <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                          Full URL
                        </th>
                        <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.slice(30).map((item, index) => (
                        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 w-fit max-w-[300px] z-40">
                          <td className='z-10  p-2 min-w-fit'>{index + 31}</td>
                          <td className='z-10  p-2 min-w-fit'>{item.title}</td>
                          <td className='z-10  p-2 min-w-fit'>{item.displayLink}</td>
                          <td className='z-10  p-2 min-w-fit'>{item.contacts.length}</td>
                          <td className='z-10  p-2 min-w-fit'>
                            {item.contacts?.reduce((count, contact) => {
                              return count + (contact.isInCRM?.isInCRM ? 1 : 0)
                            }, 0)}
                          </td>
                          <td className='z-10  p-2 min-w-fit'><a style={{color: '#005C9F'}} href={item.link} target="_blank">{item.link}</a></td>
                          <td className='z-10  p-2 min-w-fit'>
                            <button 
                              className="rounded-lg mb-2 text-white text-sm border border-[#005C9F] bg-[#005C9F] shadow-xs px-2 py-1 dark:text-[#E6EFF5]"
                              onClick={() => handleRowClick(item, (index + 31))}>
                              View Contacts
                            </button> 
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      
      {selectedItem && (
        <SideModal 
          item={selectedItem} 
          onClose={() => setPayload(prevPayload => ({
            ...prevPayload,
            selectedItem: null,
            showModal: false,
          }))} 
        /> 
      )}
      
      {emailItem && (
        <EmailModal 
          item={emailItem} 
          onClose={() => setPayload(prevPayload => ({
            ...prevPayload,
            emailItem: null,
            showEmailModal: false,
            intent1: {},
            intent2: {},
            intent3: {}, 
          }))} 
        /> 
      )}
    </>
  );
};

export default ContactFinder;


