import { useMemo } from 'react';
import { Card } from '@tremor/react';
import InfoTooltip from '../../AnalyticsSections/AnalyticsInfoTooltip';
import Icon from '../../../assets/icons/SvgComponent';
import TrendTooltip from '../../AnalyticsSections/TrendTooltip';
import TrendTxt from '../../Common/TrendTxt';
import LineChartDisplay from '../Charts/lineChartDisplay';
import PieChartDisplay from '../Charts/pieChartDisplay';
import AreaChartDisplay from '../Charts/areaChartDisplay';
import BarChartDisplay from '../Charts/barChartDisplay';
import useDashboardStore from '../../../store/useDashboardStore';
import {
  getMetricSourceIcon,
  getStatusMetric,
} from '../constants';
import {
  formatNumber,
  formatToCurrency,
  processDataDynamic,
} from '../../../utils/common';
import SessionConversionTable from '../Tables/SessionConversionTable';
import OrgRankingPosition from '../Tables/OrgRankingPosition';
import RankingsOverview from '../Tables/RankingsOverview';
import GSCKeywordsAndPages from '../Tables/GSCKeywordsAndPages';
import AhrefBacklinks from '../Tables/AhrefBacklinks';
import SemrushBacklinks from '../Tables/SemrushBacklinks';
import CompetitorsInsights from '../Tables/CompetitorsInsights';
import moment from 'moment';
import OrganicKeywordTrends from '../Charts/organicKeywordTrends';
import DonutBarGroup from '../Charts/donutBarGroup';
import Tooltip from '../../HelpTooltip';
import useClientStore from '../../../store/useClientStore';

interface IProps {
  layoutData: any;
  graphData?: any;
  setSelectedItem: (data: any) => void;
  setOpenWidget: (value: boolean) => void;
  viewType?: string;
  isGraphDataLoading?: boolean;
}



const GridItem = ({
  layoutData,
  setSelectedItem,
  setOpenWidget,
  viewType = 'view',
  isGraphDataLoading,
}: IProps) => {
  
  const { selectedClient } = useClientStore((state) => state);

  const customValue = useMemo(() => {
    return Math.floor(Math.random() * 10000) + 1
  },[]);
  const customPrevPeriod = useMemo(() => {
    return `${Math.floor(Math.random() * 10) + 1}`;
  },[]);
  const customPrevYear = useMemo(() => {
    return `${Math.floor(Math.random() * 10) + 1}`;
  },[]);

  const { graphData } = useDashboardStore(
    (state) => state
  );

  const activeItem = graphData ? graphData[layoutData?.id] : null;

  const processedData = layoutData?.data_config?.metrics
    ? processDataDynamic(
        activeItem?.graphData,
        layoutData?.data_config?.metrics
      )
    : undefined;
  // processData
  
  const topKeywordsData = useMemo(() => {
    return layoutData?.data_config?.metrics === "top_keywords_history" && 
    activeItem?.graphData?.top_keywords_history?.map((item) => {
      return {
        date: moment(item.date).format('MMM YY'),
        'Top 3': item.top3,
        '4-10': item.top4_10,
        '11-Plus': item.top11_plus,
      };
    });
  },[layoutData]);

  const formattedTopTrafficSources = useMemo(() => {
    return ["top_traffic_sources",'new_users'].includes(layoutData?.data_config?.metrics) && 
    activeItem?.graphData?.data.map((item, index) => {
      return {
        name: item.sessionDefaultChannelGroup || item.firstUserDefaultChannelGroup,
        value: item.sessions,
      };
    });
  },[layoutData]);

  const topTrafficSourcesCategories = useMemo(() => {
    return ["top_traffic_sources",'new_users'].includes(layoutData?.data_config?.metrics) 
    && activeItem?.graphData?.data?.map(
      (item) => item.sessionDefaultChannelGroup || item.firstUserDefaultChannelGroup
    );
  },[layoutData])

  
  const totalValue =
    processedData && Array.isArray(processedData) && processedData.length > 0
      ? processedData[processedData.length - 1]?.value
      : undefined;

  const dataItem = graphData[layoutData?.id]?.graphData
  const isLightTheme = layoutData?.display_config?.themeStyle === 'light' || true;

  const getMetricIconTooltipText = useMemo(() => {
    switch(layoutData?.data_config?.source) {
      case 'semrush':
        return selectedClient?.semrush?.domain ? selectedClient?.semrush?.domain : selectedClient?.domain ? selectedClient?.domain : 'N/A';
      case 'ahrefs':
        return selectedClient?.ahrefs?.domain ? selectedClient?.ahrefs?.domain : selectedClient?.domain ? selectedClient?.domain : 'N/A';
      case 'gsc':
        return selectedClient?.google_search_console?.site_url ? selectedClient?.google_search_console?.site_url : 'N/A';
      case 'ga4':
        return `Property ID: ${selectedClient?.google_analytics?.ga4?.property_id ? selectedClient?.google_analytics?.ga4?.property_id : 'N/A'}`;
      case 'rankRanger':
        return `Campaign ID: ${selectedClient?.rank_ranger?.campaign_id ? selectedClient?.rank_ranger?.campaign_id : 'N/A'}`
      default:
        return 'N/A'
    }
  },[layoutData])

  // console.log('getMetricIconTooltipText: ', layoutData?.data_config)

  return (
    <>
      <Card
        className={`border-none shadow-none ring-0 lg:ring-1 lg:border ${isLightTheme ? '' : 'bg-dark-tremor-background'} dark:bg-dark-tremor-background p-[16px] relative min-h-[410px] sm:min-h-[374px] h-auto ${layoutData?.display_config?.chartType}`}
      >
        <div className="flex items-center justify-between pb-[0.8rem] sm:pb-0">
          <div className="flex gap-2 items-center mb-1 break-all">
            <h2
              className={`text-[14px] ${isLightTheme ? 'text-[#001C44]' : 'text-white'}  dark:text-white font-bold`}
            >
              {layoutData?.title ? layoutData?.title : ''}
            </h2>
            {/* Info param */}
            <InfoTooltip
              content={
                layoutData?.toolTip ? layoutData?.toolTip : layoutData?.title
              }
              position="bottom"
            >
              <Icon name="InfoCircle" size={14} />
            </InfoTooltip>
          </div>
          <div className="flex items-center">
            {layoutData?.display_config?.showIntegrationIcon && (
              <Tooltip
                content={getMetricIconTooltipText}
                position="left"
                textAlign="text-center"
              >
                <div className="pt-1">
                  {getMetricSourceIcon(layoutData?.data_config?.source)
                    ?.value !== 'unknown' ? (
                    <Icon
                      name={
                        getMetricSourceIcon(layoutData?.data_config?.source)
                          ?.value
                      }
                      size={20}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </Tooltip>
            )}
            {viewType === 'edit' && (
              <div
                onClick={(e) => {
                  setOpenWidget(true);
                  setSelectedItem(layoutData);
                }}
                className="block ml-2 cursor-pointer z-50 w-4 h-4 flex items-center"
              >
                <Icon name="gridMenu" />
              </div>
            )}
          </div>
        </div>
        {/* Hide for Organic Keywords Trend */}
        {layoutData?.data_config?.metrics !== "top_keywords_history" &&
          <h3
          style={{
            display: ["table","pie"].includes(layoutData?.display_config?.chartType) ? "none": ""
          }}
            className={`text-[30px] font-semibold leading-8  ${isLightTheme ? '' : 'text-white'} mb-2 dark:text-white`}
          >
            {isGraphDataLoading ? (
              <div className="w-[160px] h-[30px] animate-pulse bg-gray-300 rounded-lg" />
            ) : (
              <>
              {layoutData?.display_config?.chartType === "value" 
              ? ['Organic Cost'].includes(layoutData?.data_config?.metrics)
                ? formatToCurrency(dataItem)
                : formatNumber(dataItem)
              : <>
                  {["preview","edit"].includes(viewType)
                  ? ['totalRevenue', 'organic_totalRevenue'].includes(layoutData?.data_config?.metrics)
                    ? formatToCurrency(customValue) 
                      : ['sessionConversionRate','ctr','organic_sessionConversionRate'].includes(layoutData?.data_config?.metrics) 
                      ? formatNumber(customValue, true)
                    : formatNumber(customValue,false,layoutData?.data_config?.metrics === "position" ? 0 :2)
                  : totalValue
                    ? ['totalRevenue', 'organic_totalRevenue', 'Organic Cost'].includes(layoutData?.data_config?.metrics)
                      ? formatToCurrency(totalValue) 
                        : ['sessionConversionRate','ctr','organic_sessionConversionRate'].includes(layoutData?.data_config?.metrics) 
                        ? formatNumber(totalValue, true)
                      : formatNumber(totalValue,false,layoutData?.data_config?.metrics === "position" ? 0 :2)
                    : '0'}
                </>
              }
              </>
            )}
          </h3>
        }
        {!["table","value"].includes(layoutData?.display_config?.chartType) && layoutData?.display_config?.showTrendline && (
          <div className="flex border-b border-tremor-border dark:border-dark-tremor-border w-full mb-4 mt-2">
            <>
              <div>
                {isGraphDataLoading ? (
                  <div className="w-[120px] h-[12px] animate-pulse bg-gray-300 rounded-lg my-[8px]" />
                ) : (
                  <TrendTooltip percentage={["preview","edit"].includes(viewType) ? customPrevPeriod : dataItem?.previous_period}>
                    <TrendTxt
                      type="period"
                      title="Prev Period"
                      size="small"
                      themeStyle={layoutData?.display_config?.themeStyle}
                      value={["preview","edit"].includes(viewType) ? customPrevPeriod :
                          dataItem ? dataItem?.previous_period : '0'
                      }
                    />
                  </TrendTooltip>
                )}
              </div>
              <div className="ml-[6px]">
                {isGraphDataLoading ? (
                  <div className="w-[120px] h-[12px] animate-pulse bg-gray-300 rounded-lg my-[8px]" />
                ) : (
                  <TrendTooltip percentage={["preview","edit"].includes(viewType) ? customPrevYear : dataItem?.previous_year}>
                    <TrendTxt
                      type="year"
                      title="Prev Year"
                      size="small"
                      value={["preview","edit"].includes(viewType) ? customPrevYear :
                        dataItem ? dataItem?.previous_year : '0'
                      }
                    />
                  </TrendTooltip>
                )}
              </div>
            </>
          </div>
        )}
        <div className="w-full">
          {isGraphDataLoading ? (
            <div className="w-full h-48 animate-pulse bg-gray-300 rounded-lg -mb-2 mt-8 " />
          ) : (
            <>
              {layoutData?.display_config?.chartType === 'area' && (
                <AreaChartDisplay
                  data={processedData || []}
                  category={[getStatusMetric(layoutData?.data_config?.metrics)]}
                  displayConfig={layoutData?.display_config}
                  viewType={viewType}
                />
              )}
              {layoutData?.display_config?.chartType === 'line' && 
              layoutData?.data_config?.metrics !== 'top_keywords_history' && (
                <LineChartDisplay
                  data={processedData}
                  category={[getStatusMetric(layoutData?.data_config?.metrics)]}
                  displayConfig={layoutData?.display_config}
                  viewType={viewType}
                />
              )}
              {layoutData?.display_config?.chartType === 'line' &&
                layoutData?.data_config?.metrics === 'top_keywords_history' && (
                <OrganicKeywordTrends
                  data={topKeywordsData}
                  categories={['Top 3', '4-10', '11-Plus']}
                  displayConfig={layoutData?.display_config}
                  colors={['#FFC265', '#7DCEFF', '#019AA3', '#00199D', '#002943']}
                  viewType={viewType}
                />
              )}
              {layoutData?.display_config?.chartType === 'bar' && (
                <BarChartDisplay
                  data={processedData || []}
                  category={[getStatusMetric(layoutData?.data_config?.metrics)]}
                  displayConfig={layoutData?.display_config}
                  viewType={viewType}
                />
              )}
              {/* {layoutData?.display_config?.chartType === 'pie' && (
                <PieChartDisplay
                  data={processedData || []}
                  category={getStatusMetric(layoutData?.data_config?.metrics)}
                />
              )} */}
              {/* For Landing Page (All Channels) and Organic Landing Page */}
              {["landingPage","organic_landingPage"].includes(layoutData?.data_config?.metrics) && 
                layoutData?.display_config?.chartType === 'table' && (
                <>
                  <SessionConversionTable 
                    isLoading={isGraphDataLoading}
                    data={graphData[layoutData?.id]?.graphData || []} />
                  
                </>
              )}
              {/* Semrush Organic Ranking Position */}
              {layoutData?.data_config?.metrics === "organic_search_positions" && 
                layoutData?.display_config?.chartType === 'table' && (
                <>
                  <OrgRankingPosition 
                    isLoading={isGraphDataLoading}
                    data={graphData[layoutData?.id]?.graphData || []} />
                </>
              )}
              {/* Ranking Overview table */}
              {layoutData?.data_config?.metrics === "ranking_overview" && 
                layoutData?.display_config?.chartType === 'table' && (
                <>
                  <RankingsOverview 
                    isLoading={isGraphDataLoading}
                    data={graphData[layoutData?.id]?.graphData || []} />
                </>
              )}
              {/* GSC keywords and pages */}
              {["queries","pages"].includes(layoutData?.data_config?.metrics) && 
                layoutData?.display_config?.chartType === 'table' && (
                <>
                  <GSCKeywordsAndPages 
                    isLoading={isGraphDataLoading}
                    data={graphData[layoutData?.id]?.graphData || []}/>
                </>
              )}
              {/* Semrush value only */}
              {["Organic Keywords","Organic Traffic","Organic Cost"].includes(layoutData?.data_config?.metrics) && 
                layoutData?.display_config?.chartType === 'value' && (
                null
              )}
              {/* Ahref backlinks */}
              {["backlinks"].includes(layoutData?.data_config?.metrics) && 
                layoutData?.data_config?.source === "ahrefs" && 
                layoutData?.display_config?.chartType === 'table' && (
                <>
                  <AhrefBacklinks 
                    isLoading={isGraphDataLoading}
                    data={graphData[layoutData?.id]?.graphData || []}/>
                </>
              )}
              {/* Semrush backlinks */}
              {["backlinks"].includes(layoutData?.data_config?.metrics) && 
                layoutData?.data_config?.source === "semrush" && 
                layoutData?.display_config?.chartType === 'table' && (
                <>
                  <SemrushBacklinks 
                    isLoading={isGraphDataLoading}
                    data={graphData[layoutData?.id]?.graphData || []}/>
                </>
              )}

              {["traffic_competitors"].includes(layoutData?.data_config?.metrics) && 
                layoutData?.data_config?.source === "semrush" && 
                layoutData?.display_config?.chartType === 'table' && (
                <>
                  <CompetitorsInsights 
                    isLoading={isGraphDataLoading}
                    data={graphData[layoutData?.id]?.graphData || []}/>
                </>
              )}

              {/* Traffic by channel dunot bar */}
              {["top_traffic_sources",'new_users'].includes(layoutData?.data_config?.metrics) &&
                <>
                  <DonutBarGroup 
                    data={formattedTopTrafficSources}
                    categories={topTrafficSourcesCategories}
                    variant="pie"
                    category="value"
                    index="name"
                    showLegend={true}
                  />
                </>
              }
              
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default GridItem;
