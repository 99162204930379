import { DonutChart, Legend} from '@tremor/react';

interface IProps {
  customClass?: string;
  variant?: any;
  showLegend?: boolean;
  data?: any;
  index?: string;
  categories?: string[];
  category?: string;
  loading?: boolean;
  error?: string;
  legendCustomClass?: string;
  isDollar?: boolean;
}

const valueFormatter = (number: number) =>
  `$ ${Intl.NumberFormat('us').format(number).toString()}`;

const valueFormatterNumber = (number: number) =>
  `${Intl.NumberFormat('us').format(number).toString()}`;

const DonutBarGroup = ({
  customClass,
  legendCustomClass,
  variant,
  showLegend = true,
  data,
  categories,
  category,
  index,
  loading,
  error,
  isDollar = false
}: IProps) => {

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        {showLegend ? (
          <div
            className={`flex items-center justify-between w-full h-3/4 gap-2 ${customClass}`}
          >
            <div className="w-1/2">
              <DonutChart
                data={data ? data : []}
                category={`${category}`}
                variant={variant ? variant : 'donut'}
                index={index}
                valueFormatter={
                  isDollar ? valueFormatter : valueFormatterNumber
                }
                colors={[
                  'blue',
                  'cyan',
                  'indigo',
                  'violet',
                  'fuchsia',
                  'sky',
                  'green',
                  'yellow',
                  'red',
                  'orange',
                  'pink',
                  'purple',
                  'gray',
                  'brown',
                  'black',
                  'white',
                  'blue',
                  'cyan',
                  'indigo',
                  'violet',
                  'fuchsia',
                  'sky',
                  'green',
                  'yellow',
                  'red',
                  'orange',
                  'pink',
                  'purple',
                  'gray',
                  'brown',
                  'black',
                  'white',
                ]}
                className="w-full h-64 text-xl"
              />
            </div>
            <div className="w-1/2 flex justify-center max-h-[350px]">
              <Legend
                categories={categories ? categories : []}
                colors={[
                  'blue',
                  'cyan',
                  'indigo',
                  'violet',
                  'fuchsia',
                  'sky',
                  'green',
                  'yellow',
                  'red',
                  'orange',
                  'pink',
                  'purple',
                  'gray',
                  'brown',
                  'black',
                  'white',
                  'blue',
                  'cyan',
                  'indigo',
                  'violet',
                  'fuchsia',
                  'sky',
                  'green',
                  'yellow',
                  'red',
                  'orange',
                  'pink',
                  'purple',
                  'gray',
                  'brown',
                  'black',
                  'white',
                ]}
                className={`${legendCustomClass}  flex flex-col overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8`}
              />
            </div>
          </div>
        ) : (
          <div
            className={`flex items-center justify-center w-full h-3/4 gap-2 ${customClass}`}
          >
            <div className="flex items-center justify-center mt-8 w-full">
              <DonutChart
                data={data ? data : []}
                category={`${category}`}
                variant={variant ? variant : 'donut'}
                index={index}
                valueFormatter={
                  isDollar ? valueFormatter : valueFormatterNumber
                }
                colors={[
                  'blue',
                  'cyan',
                  'indigo',
                  'violet',
                  'fuchsia',
                  'sky',
                  'green',
                  'yellow',
                  'red',
                  'orange',
                  'pink',
                  'purple',
                  'gray',
                  'brown',
                  'black',
                  'white',
                  'blue',
                  'cyan',
                  'indigo',
                  'violet',
                  'fuchsia',
                  'sky',
                  'green',
                  'yellow',
                  'red',
                  'orange',
                  'pink',
                  'purple',
                  'gray',
                  'brown',
                  'black',
                  'white',
                ]}
                className="w-64 h-64 text-xl"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
};

export default DonutBarGroup;
