import React, { useEffect, useState } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import ReactSelect from '../../../components/ReactSelect';

import { motion } from 'framer-motion';
import { mode } from '../../../utils/settings';

type Props = {
  onClose: () => void;
  change?: any;
  setConfirmationModalDecline?: any;
  setConfirmationModalApprove?: any;
  onUpdate: (updatedData: any) => void;
};

const dynamicSubReason = (cancelReason) => {
  switch (cancelReason) {
    case 'Death In The Market':
      return [
        { value: 'In House', label: 'In House' },
        { value: 'Business Windup', label: 'Business Windup' },
        { value: 'Aquisition', label: 'Aquisition' },
        { value: 'Regulatory Change', label: 'Regulatory Change' },
        {
          value: 'Personal Circumstances Changed',
          label: 'Personal Circumstances Changed',
        },
      ];
      break;
    case 'Price':
      return [
        { value: 'Too Expensive', label: 'Too Expensive' },
        { value: 'Budget Re-Allocated', label: 'Budget Re-Allocated' },
        {
          value: 'Expectation Misallignment',
          label: 'Expectation Misallignment',
        },
        {
          value: 'Insufficient Budget',
          label: 'Insufficient Budget for Successful Advertising',
        },
      ];
      break;
    case 'Product':
      return [
        { value: 'Insufficient ROI', label: 'Insufficient ROI' },
        { value: 'Speed of Service', label: 'Speed of Service' },
        { value: 'Quality of Product', label: 'Quality of Product' },
        { value: 'Results Achieved', label: 'Results Achieved' },
        {
          value: 'Expectation Misallignment',
          label: 'Expectation Misallignment',
        },
        { value: 'Error in Service', label: 'Error in Service' },
        { value: 'Poor Performance', label: 'Poor Performance' },
        {
          value: 'Incorrect Service Line Sold',
          label: 'Incorrect Service Line Sold',
        },
      ];
      break;
    case 'People':
      return [
        { value: 'Personality Clash', label: 'Personality Clash' },
        { value: 'Change of POC', label: 'Change of POC' },
        { value: 'Responsiveness', label: 'Responsiveness' },
      ];
      break;
    case 'Other':
      return [
        { value: 'Website Not Active', label: 'Website Not Active' },
        {
          value: 'CMS Platform Suitability',
          label: 'CMS Platform Suitability',
        },
        { value: 'Management Only', label: 'Management Only (Do Not Use)' },
      ];
      break;
    case 'Did Not Start':
      return [
        { value: 'N/A', label: 'N/A' }
      ];  
    case 'Service Swap':
      return [
        { value: 'N/A', label: 'N/A' }
      ];
      break;  
  }
};

const ChangeRequestModal: React.FC<Props> = ({
  onClose,
  change,
  setConfirmationModalDecline,
  setConfirmationModalApprove,
  onUpdate,
}) => {

  const [updatedChange, setUpdatedChange] = useState(change);
  const [hasChanges, setHasChanges] = useState(false);
  useEffect(() => {
    setUpdatedChange(change);
  }, [change]);

  useEffect(() => {
      setHasChanges(JSON.stringify(updatedChange) !== JSON.stringify(change));
  }, [updatedChange, change]);

  const handleSave = () => {
      onUpdate(updatedChange);
  };

  const handleCancel = () => {
    setUpdatedChange(change);
};

  return (
    <motion.div
      initial={{ x: '100%' }}
      exit={{ x: '100%' }}
      animate={{ x: 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      style={{ display: 'flex', justifyContent: 'flex-end', height: '100%' }}
    >
      <div
        className="flex flex-col w-1/2 h-full justify-between px-8 py-6 bg-white dark:bg-gray-800"
        style={{ overflowY: 'auto' }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex flex-col w-full gap-y-6">
          <div className="flex items-start justify-between pb-6 border-b border-[#E6EBF0]">
            <div className="flex items-center gap-2">
              <Icon name="arrowLeft" color="gray" />
              <span className="text-2xl font-semibold dark:text-white">
                <span>
                  {change.changeDetails.service === 'seo_google' ? 'Google SEO' : ''}
                  {change.changeDetails.service === 'ppc_google' ? 'Google Ads' : ''}
                  {change.changeDetails.service === 'seo_bing' ? 'Microsoft SEO' : ''}
                  {change.changeDetails.service === 'social_bing' ? 'Microsoft Ads' : ''}
                  {change.changeDetails.service === 'link_boost' ? 'Link Booster' : ''}
                  {change.changeDetails.service === 'display' ? 'Remarketing' : ''}
                  {change.changeDetails.service === 'social_meta' ? 'Meta Ads' : ''}
                  {change.changeDetails.service === 'social_tiktok' ? 'Tiktok Ads' : ''}
                  {change.changeDetails.service === 'social_linkedin' ? 'Linkedin Ads' : ''}
                  {change.changeDetails.service === 'social_pinterest' ? 'Pinterest Ads' : ''}
                  {change.changeDetails.service === 'social_programmatic' ? 'Programmatic Ads' : ''}
                  {change.changeDetails.service === 'social_tactical' ? 'Tactical Ads' : ''}
                  {change.changeDetails.service === 'social_creative' ? 'Creative Ads' : ''}
                  {change.changeDetails.service === 'web_hosting' ? 'Web Hosting' : ''}
                  {change.changeDetails.service === 'web_maintenance' ? 'Web Maintenance' : ''}
                </span>{' '}
                <span style={{ textTransform: 'capitalize' }}>
                - {change.changeDetails.type}
                </span>{' '}
                - {change.domain}
              </span>
            </div>
            <div onClick={onClose}>
              <Icon name="close" size={20} color="gray" />
            </div>
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-2">
              <span className="text-[#101828] dark:text-white">
                Date Submitted:
              </span>
              <span className="text-[#475467]">
                {change.createdAt.split('T')[0]}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-[#101828] dark:text-white">
                Submitted by:
              </span>
              <span className="text-[#475467]">{change.submitter}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-[#101828] dark:text-white">
                Assigned to:
              </span>
              <span className="text-[#475467]">{change.gadApprover ? change.gadApprover : change.approvedBy}</span>
            </div>
          </div>
          {/* APPLY CREDIT */}
          {updatedChange.changeType === 'apply' ? (
            <>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the amount to be credited (EX GST)?:
                </span>
                <input
                  type="text"
                  value={updatedChange.changeDetails.applyCreditAmount}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, applyCreditAmount: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the reason for the credit?
                </span>
                <textarea
                  rows={6}
                  value={updatedChange.changeDetails.applyCreditReason}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, applyCreditReason: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex items-center justify-between gap-4">
                <div className="flex flex-col gap-1.5 w-full">
                  <span className="text-[#001C44] dark:text-white">
                    Which of the below is the most appropriate bucket for the
                    credit?:
                  </span>
                  <input
                    type="text"
                    value={updatedChange.changeDetails.applyCreditBucket}
                    onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, applyCreditBucket: e.target.value } })}
                    className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                  />
                </div>
                <div className="flex flex-col gap-1.5 w-full">
                  <span className="text-[#001C44] dark:text-white">
                    Length of time as client:
                  </span>
                  <input
                    type="text"
                    value={updatedChange.changeDetails.applyCreditTimeAsClient}
                    className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                    disabled
                  />
                </div>
              </div>
            </>
          ) : ('')}
          {/* CANCEL */}
          {updatedChange.changeType === 'cancel' ? (
            <>
              {change.changeDetails.cancelUnder30days
                ? <span style={{padding:'4px 6px', fontSize:'14px'}} className="text-white bg-[#D92D20] border border-[#D92D20] rounded-lg  dark:border-gray-600">Was under 30 days</span>
                : ''}

              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Date of cancel request
                </span>
                <input
                  type="date"
                  value={updatedChange.changeDetails.cancelRequestDate}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, cancelRequestDate: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  When is the last day?
                </span>
                <input
                  type="date"
                  value={updatedChange.changeDetails.cancelEffectDate}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, cancelEffectDate: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the reason for the cancel?
                </span>
                <ReactSelect
                  options={[
                    { value: 'Death In The Market', label: 'Death In The Market' },
                    { value: 'Price', label: 'Price' },
                    { value: 'Product', label: 'Product' },
                    { value: 'People', label: 'People' },
                    { value: 'Other', label: 'Other' },
                    { value: 'Service Swap', label: 'Service Swap' },
                    { value: 'Did Not Start', label: 'Did Not Start' }
                  ]}
                  placeholder="PLEASE SELECT"
                  value={updatedChange.changeDetails.cancelReason ? 
                    { value: updatedChange.changeDetails.cancelReason, label: updatedChange.changeDetails.cancelReason } : null}
                    handleOnChange={(selectedOption) => {
                    setUpdatedChange((prev) => ({
                      ...prev,
                      changeDetails: {
                        ...prev.changeDetails,
                        cancelReason: selectedOption ? selectedOption.value : ''
                      }
                    }));
                  }}
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the sub reason for the cancel?
                </span>
                <ReactSelect
                  options={dynamicSubReason(updatedChange.changeDetails.cancelReason)}
                  placeholder="PLEASE SELECT"
                  value={updatedChange.changeDetails.cancelSubReason ? 
                    { value: updatedChange.changeDetails.cancelSubReason, label: updatedChange.changeDetails.cancelSubReason } : null}
                    handleOnChange={(selectedOption) => {
                    setUpdatedChange((prev) => ({
                      ...prev,
                      changeDetails: {
                        ...prev.changeDetails,
                        cancelSubReason: selectedOption ? selectedOption.value : ''
                      }
                    }));
                  }}
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Briefly explain your choices above:
                </span>
                <textarea
                  rows={6}
                  value={updatedChange.changeDetails.cancelAmComments}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, cancelAmComments: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  How you tried to save the account?
                </span>
                <textarea
                  rows={6}
                  value={updatedChange.changeDetails.cancelSaveComments}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, cancelSaveComments: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What did the client say?
                </span>
                <textarea
                  rows={6}
                  value={updatedChange.changeDetails.cancelClientComments}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, cancelClientComments: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What do you think we could've done better?
                </span>
                <textarea
                  rows={6}
                  value={updatedChange.changeDetails.cancelDoneBetterComments}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, cancelDoneBetterComments: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Additional Notes
                </span>
                <textarea
                  rows={6}
                  value={updatedChange.changeDetails.cancelNoteComments}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, cancelNoteComments: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  When was the start date?
                </span>
                <input
                  type="date"
                  value={updatedChange.changeDetails.cancelStartDate}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, cancelStartDate: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  How long is left in the contract?
                </span>
                <input
                  type="text"
                  value={updatedChange.changeDetails.cancelContractLeftTip}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, cancelContractLeftTip: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              {change.serviceLine === 'seo_google' ||  change.serviceLine === 'seo_bing' ?
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  When should the client be removed from Rank Ranger?
                </span>
                <input
                  type="date"
                  value={updatedChange.changeDetails.cancelRankRanger}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, cancelRankRanger: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              :''}
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  Should we attempt to reactivate?
                </span>
                <ReactSelect
                  options={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' },
                  ]}
                  value={updatedChange.changeDetails.cancelReactivate ? 
                    { value: updatedChange.changeDetails.cancelReactivate, label: updatedChange.changeDetails.cancelReactivate } : null}
                    handleOnChange={(selectedOption) => {
                    setUpdatedChange((prev) => ({
                      ...prev,
                      changeDetails: {
                        ...prev.changeDetails,
                        cancelReactivate: selectedOption ? selectedOption.value : ''
                      }
                    }));
                  }}
                />
              </div>

              {updatedChange.changeDetails.cancelReactivate === 'Yes' ? (
                <>
                  <div className="flex flex-col gap-1.5 w-full">
                    <span className="text-[#001C44] dark:text-white">
                      When should we reach out to the client?
                    </span>
                    <input
                      type="date"
                      value={updatedChange.changeDetails.cancelReactivateDate}
                      onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, cancelReactivateDate: e.target.value } })}
                      className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                    />
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <span className="text-[#001C44] dark:text-white">
                      Further reactivation notes:
                    </span>
                    <textarea
                      rows={6}
                      value={updatedChange.changeDetails.cancelReactivateNotes}
                      onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, cancelReactivateNotes: e.target.value } })}
                      className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                    />
                  </div>
                </>
              ) : (
                ''
              )}
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  Service amount
                </span>
                <input
                  type="text"
                  value={updatedChange.changeDetails.cancelBudget}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  Contract Finish Date
                </span>
                <input
                  type="date"
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                  value={updatedChange.changeDetails.contractFinishDate}
                />
              </div>
            </>
          ) : ('')}
          {/* NON_START */}
          {updatedChange.changeType === 'nonstart' ? (
            <>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Date of non start request
                </span>
                <input
                  type="date"
                  value={updatedChange.changeDetails.nonStartRequestDate}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, nonStartRequestDate: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  When is the last day?
                </span>
                <input
                  type="date"
                  value={updatedChange.changeDetails.nonStartEffectDate}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, nonStartEffectDate: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the reason for the non start?
                </span>
                <ReactSelect
                  options={[
                    { value: 'Death In The Market', label: 'Death In The Market' },
                    { value: 'Price', label: 'Price' },
                    { value: 'Product', label: 'Product' },
                    { value: 'People', label: 'People' },
                    { value: 'Other', label: 'Other' },
                    { value: 'Service Swap', label: 'Service Swap' },
                    { value: 'Did Not Start', label: 'Did Not Start' }
                  ]}
                  placeholder="PLEASE SELECT"
                  value={updatedChange.changeDetails.nonStartReason ? 
                    { value: updatedChange.changeDetails.nonStartReason, label: updatedChange.changeDetails.nonStartReason } : null}
                    handleOnChange={(selectedOption) => {
                    setUpdatedChange((prev) => ({
                      ...prev,
                      changeDetails: {
                        ...prev.changeDetails,
                        nonStartReason: selectedOption ? selectedOption.value : ''
                      }
                    }));
                  }}
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the sub reason for the non start?
                </span>
                <ReactSelect
                  options={dynamicSubReason(updatedChange.changeDetails.nonStartReason)}
                  placeholder="PLEASE SELECT"
                  value={updatedChange.changeDetails.nonStartSubReason ? 
                    { value: updatedChange.changeDetails.nonStartSubReason, label: updatedChange.changeDetails.nonStartSubReason } : null}
                    handleOnChange={(selectedOption) => {
                    setUpdatedChange((prev) => ({
                      ...prev,
                      changeDetails: {
                        ...prev.changeDetails,
                        nonStartSubReason: selectedOption ? selectedOption.value : ''
                      }
                    }));
                  }}
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Briefly explain your choices above:
                </span>
                <textarea
                  rows={6}
                  value={updatedChange.changeDetails.nonStartAmComments}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, nonStartAmComments: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  How you tried to save the account?
                </span>
                <textarea
                  rows={6}
                  value={updatedChange.changeDetails.nonStartSaveComments}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, nonStartSaveComments: e.target.value } })}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What did the client say?
                </span>
                <textarea
                  rows={6}
                  value={updatedChange.changeDetails.nonStartClientComments}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, nonStartClientComments: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What do you think we could've done better?
                </span>
                <textarea
                  rows={6}
                  value={updatedChange.changeDetails.nonStartDoneBetterComments}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, nonStartDoneBetterComments: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Additional Notes
                </span>
                <textarea
                  rows={6}
                  value={updatedChange.changeDetails.nonStartNoteComments}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, nonStartNoteComments: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  When was the start date?
                </span>
                <input
                  type="date"
                  value={updatedChange.changeDetails.nonStartStartDate}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, nonStartStartDate: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  How long is left in the contract?
                </span>
                <input
                  type="text"
                  value={updatedChange.changeDetails.nonStartContractLeftTip}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, nonStartContractLeftTip: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              {change.serviceLine === 'seo_google' ||  change.serviceLine === 'seo_bing' ?
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  When should the client be removed from Rank Ranger?
                </span>
                <input
                  type="date"
                  value={updatedChange.changeDetails.nonStartRankRanger}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, nonStartRankRanger: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              :''}
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  Should we attempt to reactivate?
                </span>
                <ReactSelect
                  options={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' },
                  ]}
                  value={updatedChange.changeDetails.nonStartReactivate ? 
                    { value: updatedChange.changeDetails.nonStartReactivate, label: updatedChange.changeDetails.nonStartReactivate } : null}
                    handleOnChange={(selectedOption) => {
                    setUpdatedChange((prev) => ({
                      ...prev,
                      changeDetails: {
                        ...prev.changeDetails,
                        nonStartReactivate: selectedOption ? selectedOption.value : ''
                      }
                    }));
                  }}
                />
              </div>
              {updatedChange.changeDetails.cancelReactivate === 'Yes' ? (
                <>
                  <div className="flex flex-col gap-1.5 w-full">
                    <span className="text-[#001C44] dark:text-white">
                      When should we reach out to the client?
                    </span>
                    <input
                      type="date"
                      value={updatedChange.changeDetails.nonStartReactivateDate}
                      onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, nonStartReactivateDate: e.target.value } })}
                      className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                    />
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <span className="text-[#001C44] dark:text-white">
                      Further reactivation notes:
                    </span>
                    <textarea
                      rows={6}
                      value={updatedChange.changeDetails.nonStartReactivateNotes}
                      onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, nonStartReactivateNotes: e.target.value } })}
                      className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                    />
                  </div>
                </>
              ) : (
                ''
              )}
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  Service amount
                </span>
                <input
                  type="text"
                  value={updatedChange.changeDetails.nonStartBudget}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  Contract Finish Date
                </span>
                <input
                  type="date"
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                  value={updatedChange.changeDetails.contractFinishDate}
                />
              </div>
            </>
          ) : ('')}
          {/* PAUSE */}
          {updatedChange.changeType === 'pause' ? (
            <>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the reason for the pause?:
                </span>
                <ReactSelect
                  options={[
                    { value: 'Covid Pause', label: 'COVID-19' },
                    { value: 'Non Covid Pause', label: 'NON COVID-19' },
                  ]}
                  value={updatedChange.changeDetails.pauseCategory ? 
                    { value: updatedChange.changeDetails.pauseCategory, label: updatedChange.changeDetails.pauseCategory } : null}
                    handleOnChange={(selectedOption) => {
                    setUpdatedChange((prev) => ({
                      ...prev,
                      changeDetails: {
                        ...prev.changeDetails,
                        pauseCategory: selectedOption ? selectedOption.value : ''
                      }
                    }));
                  }}
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the pause request date?:
                </span>
                <input
                  type="date"
                  value={updatedChange.changeDetails.pauseRequestDate}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, pauseRequestDate: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the pause effect date?:
                </span>
                <input
                  type="date"
                  value={updatedChange.changeDetails.pauseEffectDate}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, pauseEffectDate: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the pause recommencement date?:
                </span>
                <input
                  type="date"
                  value={updatedChange.changeDetails.pauseRecommenceDate}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, pauseRecommenceDate: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Reason for pause?
                </span>
                <textarea
                  rows={6}
                  value={updatedChange.changeDetails.pauseReason}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, pauseReason: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What did the client say?
                </span>
                <textarea
                  rows={6}
                  value={updatedChange.changeDetails.pauseClientComments}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, pauseClientComments: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the client's contract end date?:
                </span>
                <input
                  type="text"
                  value={change.changeDetails.pauseHubspotFinishDate}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the client's budget?:
                </span>
                <input
                  type="text"
                  value={change.changeDetails.pauseClientBudget}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Length of time as client:
                </span>
                <input
                  type="text"
                  value={change.changeDetails.pauseTimeAsClient}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
            </>
          ) : ('')}
          {/* STOP WORK */}
          {updatedChange.changeType === 'stop' ? (
            <>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Stop work comments?:
                </span>
                <textarea
                  rows={6}
                  value={updatedChange.changeDetails.stopWorkComments}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, stopWorkComments: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Client's service amount:
                </span>
                <input
                  type="text"
                  value={updatedChange.changeDetails.stopWorkBudget}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, stopWorkBudget: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  The client's contract value?:
                </span>
                <input
                  type="text"
                  value={updatedChange.changeDetails.stopWorkContractValue}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, stopWorkContractValue: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Stop work request date?:
                </span>
                <input
                  type="date"
                  value={updatedChange.changeDetails.stopWorkRequestDate}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, stopWorkRequestDate: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Cluent's start date?:
                </span>
                <input
                  type="date"
                  value={change.changeDetails.stopWorkStartDate}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Length of time as client?:
                </span>
                <input
                  type="text"
                  value={change.changeDetails.stopWorkTimeAsClient}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
            </>
          ) : ('')}
          {/*  DELAY BILLING  */}
          {updatedChange.changeType === 'delay' ? (
            <>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                Planned Launch Date:
                </span>
                <input
                  type="date"
                  value={updatedChange.changeDetails.delayBillingRequestDate}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, delayBillingRequestDate: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                When should invoicing commence:
                </span>
                <input
                  type="date"
                  value={updatedChange.changeDetails.delayBillingStartDate}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, delayBillingStartDate: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                {change.changeType} Reason:
                </span>
                <textarea
                  rows={6}
                  value={updatedChange.changeDetails.delayBillingReason}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, delayBillingReason: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                Additional Information:
                </span>
                <textarea
                  rows={6}
                  value={updatedChange.changeDetails.delayBillingComments}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, delayBillingComments: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                Length of time as Client:
                </span>
                <input
                  type="text"
                  value={updatedChange.changeDetails.delayTimeAsClient}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
            </>
          ) : ('')}
          {/*  REDUCE BILLING  */}
          {updatedChange.changeType === 'reduce' ? (
            <>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                What is the new budget? 
                </span>
                <input
                  type="text"
                  value={updatedChange.changeDetails.reduceBillingNewBudget}
                  onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, reduceBillingNewBudget: e.target.value } })}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                  <span className="text-[#001C44] dark:text-white">
                  Effective Date for Reduced Budget:
                  </span>
                  <input
                    type="date"
                    value={updatedChange.changeDetails.reduceBillingStartDate}
                    onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, reduceBillingStartDate: e.target.value } })}
                    className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                  />
                </div>
                <div className="flex flex-col gap-1.5">
                  <span className="text-[#001C44] dark:text-white">
                  What is the reason for the reduction:
                  </span>
                  <textarea
                    rows={6}
                    value={updatedChange.changeDetails.reduceBillingReason}
                    onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, reduceBillingReason: e.target.value } })}
                    className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                  />
                </div>
                <div className="flex flex-col gap-1.5">
                  <span className="text-[#001C44] dark:text-white">
                  Current Budget:
                  </span>
                  <input
                    type="text"
                    value={change.changeDetails.reduceBillingBudget}
                    className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                  />
                </div>
                <div className="flex flex-col gap-1.5">
                  <span className="text-[#001C44] dark:text-white">
                  Length of time as Client:
                  </span>
                  <input
                    type="text"
                    value={change.changeDetails.reduceTimeAsClient}
                    //onChange={(e) => setAmount(Number(e.target.value))}
                    className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                  />
                </div>
            </>
          ) : ('')}
          {/*  CASH REFUND  */}
          {updatedChange.changeType === 'refund' ? (
          <>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            What is the amount to refund? (excluding GST):
            </span>
            <input
              type="text"
              value={updatedChange.changeDetails.refundAmount}
              onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, refundAmount: e.target.value } })}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            What is the reason for the refund?:
            </span>
            <textarea
              rows={6}
              value={updatedChange.changeDetails.refundReason}
              onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, refundReason: e.target.value } })}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            Current Budget:
            </span>
            <input
              type="text"
              value={change.changeDetails.refundBudget}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
            />
          </div>
          </>
          ):('')}
          {/*  RESTART  */}
          {updatedChange.changeType === 'restart' ? (
            <>
            <div className="flex flex-col gap-1.5">
              <span className="text-[#001C44] dark:text-white">
              When does the account turn back on?:
              </span>
              <ReactSelect
                  options={[
                    {
                      value: 'Treat as if never turned off',
                      label: 'Treat as if never turned off',
                    },
                    {
                      value: 'Specific Date',
                      label: 'Specific Date (Must be 1st or 15th)',
                    },
                  ]}
                  value={updatedChange.changeDetails.restartCategory ? 
                    { value: updatedChange.changeDetails.restartCategory, label: updatedChange.changeDetails.restartCategory } : null}
                    handleOnChange={(selectedOption) => {
                    setUpdatedChange((prev) => ({
                      ...prev,
                      changeDetails: {
                        ...prev.changeDetails,
                        restartCategory: selectedOption ? selectedOption.value : ''
                      }
                    }));
                  }}
                />
            </div>
            {updatedChange.changeDetails.restartCategory == 'Specific Date' ?
              <>
                <div className="flex flex-col gap-1.5">
                  <span className="text-[#001C44] dark:text-white">
                  Specified Restart Date:
                  </span>
                  <input
                    type="date"
                    value={updatedChange.changeDetails.restartDate}
                    onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, restartDate: e.target.value } })}
                    className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                  />
                </div>
              </>
            : ''}
            <div className="flex flex-col gap-1.5">
              <span className="text-[#001C44] dark:text-white">
              What did you do to save the account?:
              </span>
              <textarea
                rows={6}
                value={updatedChange.changeDetails.restartSave}
                onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, restartSave: e.target.value } })}
                className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <span className="text-[#001C44] dark:text-white">
              Additional Information:
              </span>
              <textarea
                rows={6}
                value={updatedChange.changeDetails.restartInfo}
                onChange={(e) => setUpdatedChange({ ...updatedChange, changeDetails: { ...updatedChange.changeDetails, restartInfo: e.target.value } })}
                className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
              />
            </div>
            </>
            
          ):('')}
          {/* FLAG FOR REVIEW */}
          {change.changeType === 'flagged' ? (
          <>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            {change.changeType} Reason:
            </span>
            <input
              type="text"
              value={change.changeDetails.flaggedReason}
              //onChange={(e) => setAmount(Number(e.target.value))}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
            />
          </div>
          {change.changeDetails.flaggedReason == 'Traffic Drop' ?
          <>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            What is the extent of the drop (% Deficit):
            </span>
            <textarea
              rows={6}
              value={change.changeDetails.flaggedDeficit}
              //onChange={(e) => setReason(e.target.value)}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            Time Period when traffic was performing as expected:
            </span>
            <textarea
              rows={6}
              value={change.changeDetails.flaggedTimePeriod}
              //onChange={(e) => setReason(e.target.value)}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            Top 3 landing pages of concern:
            </span>
            <textarea
              rows={6}
              value={change.changeDetails.flaggedPages}
              //onChange={(e) => setReason(e.target.value)}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          </>
          :''}
          {change.changeDetails.flaggedReason == 'Ranking Drop' ?
          <>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            Flagged Keywords (1-5 Highest Priority Declining Keywords):
            </span>
            <textarea
              rows={6}
              value={change.changeDetails.flaggedKeywords}
              //onChange={(e) => setReason(e.target.value)}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          </>
          :''}
          {change.changeDetails.flaggedReason == 'Work Quality' ?
          <>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            Sales Brief / Information Around Quality Decline:
            </span>
            <textarea
              rows={6}
              value={change.changeDetails.flaggedQuality}
              //onChange={(e) => setReason(e.target.value)}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            Document that best describes the work quality issue:
            </span>
            <textarea
              rows={6}
              value={change.changeDetails.flaggedDocuments}
              //onChange={(e) => setReason(e.target.value)}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          </>
          :''}
          {change.changeDetails.flaggedReason == 'Performance' ?
          <>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            What is going wrong with the campaign:
            </span>
            <textarea
              rows={6}
              value={change.changeDetails.flaggedNoteComments}
              //onChange={(e) => setReason(e.target.value)}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          </>
          :''}
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            Length of time as Client:
            </span>
            <input
              type="text"
              value={change.changeDetails.flaggedTimeAsClient}
              //onChange={(e) => setAmount(Number(e.target.value))}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            Who flagged the issue:
            </span>
            <input
              type="text"
              value={change.changeDetails.flaggedFrom}
              //onChange={(e) => setAmount(Number(e.target.value))}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
            />
          </div>
          </>
          ):('')}
        </div>
        <div className="flex gap-8 bottom-0  w-full py-6  dark:bg-gray-800">
        {hasChanges ? (
            <>
                <button onClick={handleSave} className={`p-3 w-full text-white bg-[#009f4f] border border-[#009f4f] rounded-lg dark:bg-[#009f4f]`}>
                    Save Changes
                </button>
                <button onClick={handleCancel} className={`p-3 w-full text-white bg-[#6c6c6c] border border-[#6c6c6c] rounded-lg dark:bg-[#6c6c6c]`}>
                    Cancel Changes
                </button>
            </>
        ) : (
            <>
                <button
                    className={`p-3 w-full text-white bg-[#005C9F] border border-[#005C9F] rounded-lg dark:bg-[#005C9F]`}
                    onClick={() => setConfirmationModalApprove(true)}
                >
                    Approve
                </button>
                <button
                    className={`p-3 w-full text-white bg-[#D92D20] border border-[#D92D20] rounded-lg dark:border-gray-600`}
                    onClick={() => setConfirmationModalDecline(true)}
                >
                    Decline
                </button>
            </>
        )}
        </div>
      </div>
    </motion.div>
  );
};

export default ChangeRequestModal;
