import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../assets/icons/SvgComponent';
import CustomModal from '../../components/CustomModal';
import Report from '../../components/Modals/Report';
import useReportStore from '../../store/useReportStore';
import DatePicker from 'react-datepicker';
import SearchDropdown from '../../components/SearchDropdown';
import ButtonIcon from '../../components/ButtonIcon';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import useClientStore from '../../store/useClientStore';
import useUserStore from '../../store/useUserStore';
import ImageIcon from '../../components/ImageIcon';
import ExportModal from '../../components/Modals/Report/ExportModal';
import ClientUserSearchBar from '../../components/ClientUserSearch';
import TansStackTableV2 from '../../components/TansStackTableV2';
import {
  isValidatorUser,
  isApprovalUser,
  getTypePrefix,
} from '../../utils/report';
import { useThemeStore } from '../../store/useThemeStore';
import { getUserReportType } from '../../utils/report';
import ReportPreview from '../../components/Modals/Report/ReportPreview';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  clientsObj,
  clientStatusObj,
  cycleObj,
  healthStatusObj,
  missingOption,
  missingOptions,
  seoMissingOptions,
  statusObj,
  typeObj,
  clientsOptions,
  typeOptions,
  cycleOptions,
  clientStatusOptions,
  HealthStatuOptions,
} from '../../components/ReportTanStackTable/staticData';
import useAuthStore from '../../store/useAuthStore';
import ReportInsightV2 from '../../components/Modals/Report/ReportInsightV2';
import ConfrimReport from '../../components/Modals/Report/ConfirmReport';
import ToolTipV2 from '../../components/TooltipV2';
import Tooltip from '../../components/HelpTooltip';
import TanStackTable from '../../components/TanStackTable';
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox';
import useVisibilityStore from '../../store/useVisibilityStore';
import ImportCSVModal from '../../components/Modals/Report/ImportCSVModal';
import { getRoleName, isStaging } from '../../utils';


type ParameterValue = {
  label: string;
  id: string | boolean;
};
type ParameterObject = {
  [key: string]: ParameterValue;
};

interface MonthlyReportClientUserFilterStorage {
  type: string;
  options: any[]; // Adjust this type according to your actual data structure
}

const ReportsV2: React.FC = () => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const location = useLocation();
  const navigate = useNavigate();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [rowState, setRowState] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const { openVisibility, setOpenVisibility } = useVisibilityStore();
  const [openReportInsight, setOpenReportInsight] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState<any>(false);
  const [pendingChangesModal, setPendingChangesModal] = useState(false);
  const [pendingChanges, setPendingChanges] = useState(false);
  const [uploadModal, setUploadModal] = useState<any>(false);
  const [selectedReport, setSelectedReport] = useState({ name: '', id: 0 });
  const [monthlyClientUserFilterStorage, setMonthlyClientUserFilterStorage] =
    useState<MonthlyReportClientUserFilterStorage>({
      type: '',
      options: [],
    });
  const today = moment().format('MM-yyyy');
  const { user } = useAuthStore((state) => state);
  const maxDate = moment(today, 'MM-yyyy')
    .subtract(1, 'month')
    .endOf('month')
    .toDate();
  const { fetchReports, reports, loading, fetchReportSummary, setFilters } =
    useReportStore((state) => state);
  const { setClient, fetchClients, clients } = useClientStore(
    (state: any) => state
  );
  const { fetchUsers, users } = useUserStore((state: any) => state);
  const {
    searchText,
    cycle,
    type,
    reportStatus,
    reportDate,
    selectedClientStatus,
    all,
    select,
    sortBy,
    sortOrder,
    healthStatus,
  } = useReportStore((state) => state.filters);

  //update statue
  const isDraft = reportStatus.id === 'draft';
  const isProgress = selectedItems?.every(
    (item: any) => item.status === 'in_progress'
  );
  const isValidated = selectedItems?.every(
    (item: any) => item.status === 'validated'
  );
  const isRejected = selectedItems?.every(
    (item: any) => item.status === 'rejected'
  );
  const isReview = selectedItems?.every(
    (item: any) => item.status === 'review'
  );
  const isSent = selectedItems?.every((item: any) => item.status === 'sent');
  const isApproved = selectedItems?.every(
    (item: any) => item.status === 'approved'
  );
  const isScheduled = selectedItems?.every(
    (item: any) => item.status === 'scheduled'
  );
  const isCanceled = selectedItems?.every(
    (item: any) => item.status === 'canceled'
  );


  const tableData = reports?.data || [];
  const isAllType = type?.id === 'all';
  const isSeoType = type?.id?.includes('seo');
  const [page, setPage] = useState(1);

  const [sortingDirection, setSortingDirection] = useState('');
  const [sortingColumn, setSortingColumn] = useState('');

  const handleSortTable = (sortColumn, sortDirection) => {
    let newSortDirection = '';

    if (sortingDirection === '') {
      if (sortDirection === 'desc') {
        newSortDirection = 'asc';
      } else {
        newSortDirection = 'desc';
      }
    } else if (sortingDirection === 'asc') {
      newSortDirection = 'desc';
    } else if (sortingDirection === 'desc') {
      newSortDirection = null;
    } else if (sortingDirection === null) {
      newSortDirection = 'asc';
    }

    if (sortingColumn !== sortColumn) {
      newSortDirection = 'asc';
    } else if (sortingDirection === 'asc') {
      newSortDirection = 'desc';
    } else if (sortingDirection === 'desc') {
      newSortDirection = null;
    } else if (sortingDirection === null) {
      newSortDirection = 'asc';
    }

    if (sortDirection === null) {
      setSortingColumn('');
    }

    setSortingDirection(newSortDirection);
    setSortingColumn(sortColumn);

    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('sortDirection', newSortDirection);
    newUrl.searchParams.set('sortColumn', sortColumn);
    window.history.pushState({}, '', newUrl);
  };

  const commonColumns = [
    {
      id: 'Select',
      header: ({ table }: any) => {
        return (
          <div className="flex items-center justify-center w-fit ml-4">
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: !isDraft
                  ? table.getToggleAllRowsSelectedHandler()
                  : null,
              }}
            />
          </div>
        );
      },
      cell: ({ row }: any) => {
        return (
          <div className="flex items-center justify-center  w-fit ml-4">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: !isDraft ? row.getToggleSelectedHandler() : null,
              }}
            />
          </div>
        );
      },
    },
    {
      id: 'Client',
      header: () => (
        <div
          className={`flex justify-start w-[160px] sm:w-[16vw] text-sm font-[600] font-inter`}
        >
          Client
        </div>
      ),
      accessorKey: 'name',
      enableHiding: false,
      cell: (props: any) => {
        const reportAtIndex = 1;
        const isAllType = type?.id === 'all';
        return (
          <div className="w-[160px] sm:w-[16vw] relative">
            <Link
              className="max-w-[160px] sm:max-w-[16vw]"
              target="_blank"
              onClick={() => {
                navToClientDetails(reportAtIndex);
              }}
              to={`/client/details/users/${props.row.original?.client_id}`}
            >
              <Tooltip content={props.getValue()} position="right">
                <div className="flex gap-2">
                  <div
                    className={`truncate max-w-[140px] sm:max-w-[10vw] overflow-hidden font-[600]`}
                  >
                    {props.getValue()}
                  </div>

                  {(type.id.includes('seo') ||
                    type.id.includes('seo_bing')) && (
                    <div className="text-[8px] rounded-full border border-[#345578] text-[#345578] dark:text-white px-[4px] py-0">
                      {props.row.original?.cycle}
                    </div>
                  )}
                </div>
              </Tooltip>
              <div className="text-xs">
                {isAllType && getTypePrefix(props.row.original.report_type)}
              </div>
            </Link>
          </div>
        );
      },
    },
    {
      id: 'HealthStatus',
      header: ({ column }: any) => {
        return (
          <div
            className="flex items-center justify-center w-[100px] sm:w-[5vw] gap-x-2 cursor-pointer text-sm font-[600] font-inter"
            onClick={() => column.toggleSorting(column.getIsSorted())}
          >
            Health
            <div
              className={` cursor-pointer w-3 h-3 rounded-lg border-[0.3px] border-black dark:border-white ${`bg-${
                healthStatus.id || 'gray'
              }-500`}`}
            >
              <select
                name="healthStatus"
                id="healthStatus"
                value={healthStatus.id}
                style={{
                  opacity: 0,
                  border: 'none',
                  outline: 'none',
                  backgroundColor: 'white',
                  cursor: 'pointer',
                }}
                onChange={(e) =>
                  handleFiltersChange('healthStatus', { id: e.target.value })
                }
              >
                {HealthStatuOptions?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      },
      accessorKey: 'health_status',
      enableSorting: false,
      cell: (props: any) => {
        return (
          <div className="flex items-center gap-x-1 justify-center  w-[100px] sm:w-[5vw] text-sm font-normal font-inter">
            <ToolTipV2
              tooltip={`${props.getValue()[0] === undefined ? '' : `Role: ${getRoleName(props.getValue()[0]?.role)}\nName: ${props.getValue()[0]?.name}`}`}
            >
              <div className="relative cursor-default w-fit">
                <ImageIcon
                  data={props.getValue()[0]?.name || ''}
                  size={7}
                  textSize={'xs'}
                />
                {props.getValue()[0]?.name ? (
                  <div
                    className={`absolute bottom-[-2px] right-0 w-2.5 h-2.5 rounded-lg border border-white ${
                      props.getValue()[0]?.val
                        ? `bg-${props.getValue()[0]?.val}-500`
                        : 'bg-gray-300'
                    }`}
                  />
                ) : null}
              </div>
            </ToolTipV2>
            <ToolTipV2
              tooltip={`${props.getValue()[1] === undefined ? '' : `Role: ${getRoleName(props.getValue()[1]?.role)}\nName: ${props.getValue()[1]?.name}`}`}
            >
              <div className="relative cursor-default w-fit">
                <ImageIcon
                  data={props.getValue()[1]?.name || ''}
                  size={7}
                  textSize={'xs'}
                />
                {props.getValue()[1]?.name ? (
                  <div
                    className={`cursor-pointer absolute bottom-[-2px] right-0 w-2.5 h-2.5 rounded-lg border border-white ${
                      props.getValue()[1]?.val
                        ? `bg-${props.getValue()[1]?.val}-500`
                        : 'bg-gray-300'
                    }`}
                  />
                ) : null}
              </div>
            </ToolTipV2>
          </div>
        );
      },
    },
    {
      id: 'AM',
      header: () => (
        <div
          className="w-[160px] sm:w-[8vw] text-sm font-[600] font-inter"
          onClick={() => {
            handleSortTable('account_manager', 'asc');
          }}
        >
          {sortingColumn === 'account_manager' ? (
            <div className="flex w-[8vw] justify-center gap-2 cursor-pointer">
              <div className="text-sm font-inter">AM Name</div>
              {sortingDirection === 'asc' && <Icon name="Arrowup" />}
              {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
              {sortingDirection === null && <Icon name="Sort" />}
            </div>
          ) : (
            <div
              className="flex w-[160px] sm:w-[8vw] justify-center gap-2 cursor-pointer"
              onClick={() => {
                handleSortTable('account_manager', 'asc');
              }}
            >
              <div className="text-sm font-inter">AM Name</div>
              <Icon name="Sort" />
            </div>
          )}
        </div>
      ),
      accessorKey: 'account_manager',
      cell: (props: any) => {
        return (
          <div className="flex items-center justify-center gap-x-1 text-xs w-[160px] sm:w-[8vw] font-[500]">
            <div className="truncate overflow-hidden  w-[160px] sm:w-[8vw] text-center">
              {props.getValue()}
            </div>
          </div>
        );
      },
    },
    {
      id: 'Consultants',
      header: () => (
        <div
          className="w-[160px] sm:w-[8vw] text-sm font-[600] font-inter"
          onClick={() => {
            handleSortTable('consultant', 'asc');
          }}
        >
          {sortingColumn === 'consultant' ? (
            <div className="flex w-[8vw] justify-center gap-2 cursor-pointer">
              <div className="text-sm font-inter">Consultant</div>
              {sortingDirection === 'asc' && <Icon name="Arrowup" />}
              {sortingDirection === 'desc' && <Icon name="ArrowDown" />}
              {sortingDirection === null && <Icon name="Sort" />}
            </div>
          ) : (
            <div
              className="flex w-[160px] sm:w-[8vw] justify-center gap-2 cursor-pointer"
              onClick={() => {
                handleSortTable('consultant', 'asc');
              }}
            >
              <div className="text-sm font-inter">Consultant</div>
              <Icon name="Sort" />
            </div>
          )}
        </div>
      ),
      accessorKey: 'consultant',
      cell: (props: any) => {
        return (
          <div className="flex items-center gap-x-1 justify-center text-xs w-[160px] sm:w-[8vw] font-[500]">
            {props.getValue()}
          </div>
        );
      },
    },
    {
      id: 'Overview',
      header: () => (
        <div className="text-center w-[160px] sm:w-[10vw] text-sm font-[600] font-inter">
          Overview
        </div>
      ),
      accessorKey: 'report.overview',
      cell: (props: any) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = props.getValue() || '';
        const tooltipText = tempDiv.textContent || '';

        return (
          <div className="flex items-center gap-x-1 justify-center w-[160px] sm:w-[10vw]">
            <ToolTipV2 tooltip={tooltipText}>
              {props.getValue() === null ? (
                <Icon name="minus" />
              ) : (
                <Icon name="lightCheck" />
              )}
            </ToolTipV2>
          </div>
        );
      },
    },
    {
      id: 'Opportunities',
      header: () => (
        <div className="text-center w-[160px] sm:w-[10vw] text-sm font-[600] font-inter">
          Opportunities
        </div>
      ),
      accessorKey: 'report.opportunities',

      cell: (props: any) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = props.getValue() || '';
        const tooltipText = tempDiv.textContent || '';
        return (
          <div className="flex items-center gap-x-1 justify-center w-[160px] sm:w-[10vw]">
            <ToolTipV2 tooltip={tooltipText}>
              {props.getValue() === null ? (
                <Icon name="minus" />
              ) : (
                <Icon name="lightCheck" />
              )}
            </ToolTipV2>
          </div>
        );
      },
    },
    {
      id: 'WorkCompleted',
      header: () => (
        <div className="text-center w-[160px] sm:w-[10vw] text-sm font-[600] font-inter">
          Work Completed
        </div>
      ),
      accessorKey: 'report.work_completed',

      cell: (props: any) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = props.getValue() || '';
        const tooltipText = tempDiv.textContent || '';
        return (
          <div className="flex items-center gap-x-1 justify-center w-[160px] sm:w-[10vw]">
            <ToolTipV2 tooltip={tooltipText}>
              {props.getValue() === null ? (
                <Icon name="minus" />
              ) : (
                <Icon name="lightCheck" />
              )}
            </ToolTipV2>
          </div>
        );
      },
    },
    {
      id: 'WorkPlanned',
      header: () => (
        <div className="text-center w-[160px] sm:w-[10vw] text-sm font-[600] font-inter">
          Work Planned
        </div>
      ),
      accessorKey: 'report.work_planned',

      cell: (props: any) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = props.getValue() || '';
        const tooltipText = tempDiv.textContent || '';
        return (
          <div className="flex items-center gap-x-1 justify-center  w-[160px] sm:w-[10vw]">
            <ToolTipV2 tooltip={tooltipText}>
              {props.getValue() === null ? (
                <Icon name="minus" />
              ) : (
                <Icon name="lightCheck" />
              )}
            </ToolTipV2>
          </div>
        );
      },
    },
    {
      id: 'Status',
      header: () => (
        <div className="text-center w-[160px] sm:w-[8vw] text-sm font-[600] font-inter">
          Status
        </div>
      ),
      accessorKey: 'status',
      enableHiding: false,

      cell: (props: any) => {
        const comments = props.row.original.comments || [];
        const sortedComments = comments.sort(
          (a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        const latestComment = sortedComments[0]?.comment || 'No comments available';

        let color;
        let bg;
        if (props.getValue() === 'review' || props.getValue() === 'draft') {
          color = '#CF5C00';
          bg = '#FFF2E8';
        } else if (
          props.getValue() === 'rejected' ||
          props.getValue() === 'canceled'
        ) {
          color = '#8F2929';
          bg = '#FCDADA';
        } else if (
          props.getValue() === 'validated' ||
          props.getValue() === 'sent' ||
          props.getValue() === 'approved'
        ) {
          color = '#00375F';
          bg = '#E6EFF5';
        } else if (props.getValue() === 'in_progress') {
          color = '#CCA200';
          bg = '#FFFAE6';
        }
        return (
          <div className="flex items-center font-[500] justify-center w-[160px] sm:w-[8vw]">
          <Tooltip content={latestComment} position="left">
              <span
                style={{
                  padding: '2px 8px',
                  borderRadius: 16,
                  backgroundColor: `${bg}`,
                  color: `${color}`,
                  border: `1px solid ${bg}`,
                  fontSize: 12,
                }}
              >
                {props
                  .getValue()
                  .replace(/_/g, ' ')
                  .replace(/\b\w/g, (char: string) => char.toUpperCase())}
              </span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      id: 'Action',
      header: () => (
        <div className="text-center w-[80px] sm:w-[6vw] text-sm font-[600] font-inter">
          Action
        </div>
      ),
      accessorKey: 'action',
      enableHiding: false,

      cell: (cell: any) => {
        return (
          <div className="flex items-center gap-x-4 justify-center w-[80px] sm:w-[6vw]">
            <button onClick={() => reportPreview(cell?.row.original)}>
              <Icon name="eye" />
            </button>
            <button onClick={() => editClientReport(cell?.row.original)}>
              <Icon name="edit" />
            </button>
          </div>
        );
      },
    },
  ];

  const seoColumns = [
    {
      id: 'TrafficConversion',
      header: () => (
        <div className="text-center w-[160px] sm:w-[10vw] text-sm font-[600] font-inter">
          Traffic & Conversion
        </div>
      ),
      accessorKey: 'report.traffic_and_conversion',
      cell: (cell: any) => {
        const traffic = cell?.row.original?.report?.traffic_and_conversion;
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = traffic || '';
        const tooltipText = tempDiv.textContent || '';
        return (
          <div className="flex items-center gap-x-1 justify-center w-[160px] sm:w-[10vw]">
            <ToolTipV2 tooltip={tooltipText}>
              {traffic === null ? (
                <Icon name="minus" />
              ) : (
                <Icon name="lightCheck" />
              )}
            </ToolTipV2>
          </div>
        );
      },
    },
    {
      id: 'KeywordsRanking',
      header: () => (
        <div className="text-center w-[160px] sm:w-[10vw] text-sm font-[600] font-inter">
          Keywords Ranking
        </div>
      ),
      accessorKey: 'report.keywords_ranking',
      cell: (cell: any) => {
        const ranking = cell?.row.original?.report?.keywords_ranking;
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = ranking || '';
        const tooltipText = tempDiv.textContent || '';
        return (
          <div className="flex items-center gap-x-1 justify-center  w-[160px] sm:w-[10vw]">
            <ToolTipV2 tooltip={tooltipText}>
              {ranking === null ? (
                <Icon name="minus" />
              ) : (
                <Icon name="lightCheck" />
              )}
            </ToolTipV2>
          </div>
        );
      },
    },
  ];

  const ppcColumns = [
    {
      id: 'Performance',
      header: () => (
        <div className="text-center w-[8vw] text-sm font-[600] font-inter">
          Performance
        </div>
      ),
      accessorKey: 'report.performance',
      cell: (props: any) => {
        const performance = props?.row.original?.report?.performance;
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = props.getValue() || '';
        const tooltipText = tempDiv.textContent || '';
        return (
          <div className="flex items-center gap-x-1 justify-center w-[8vw]">
            <ToolTipV2 tooltip={tooltipText}>
              {props.getValue() === null ? (
                <Icon name="minus" />
              ) : (
                <Icon name="lightCheck" />
              )}
            </ToolTipV2>
          </div>
        );
      },
    },
  ];
  const nameColumns = ['name', 'health_check', 'account_manager', 'consultant'];
  const actionStatusColumns = ['actions', 'status'];
  const columns = useMemo(() => {
    let startColumns = commonColumns.filter((col: any) =>
      nameColumns.includes(col.id)
    );
    startColumns = isDraft
      ? startColumns.filter((col: any) => col.id !== 'health')
      : startColumns;
    const endColumns = commonColumns.filter((col: any) =>
      actionStatusColumns.includes(col.id)
    );

    const middleColumns: any[] = commonColumns.filter(
      (col: any) => ![...nameColumns, ...actionStatusColumns].includes(col.id)
    );

    if (type?.id.includes('seo')) {
      const workPlannedIndex = middleColumns.findIndex(
        (col: any) => col.id === 'WorkPlanned'
      );
      middleColumns.splice(workPlannedIndex + 1, 0, ...seoColumns);
    } else if (type?.id && /^(ppc|social|display)/.test(type.id)) {
      const workPlannedIndex = middleColumns.findIndex(
        (col: any) => col.id === 'WorkPlanned'
      );
      middleColumns.splice(workPlannedIndex + 1, 0, ...ppcColumns);
    }

    return [...startColumns, ...middleColumns, ...endColumns];
  }, [
    type,
    sortBy,
    sortOrder,
    healthStatus,
    isAllType,
    isDraft,
    sortingColumn,
    sortingDirection,
  ]);

  const searchParams = new URLSearchParams(location.search);
  const getURLParameter = (
    parameter: string,
    defaultValue: any,
    parameterObj: ParameterObject | null = null
  ): any => {
    const paramValue = searchParams.get(parameter);
    if (parameterObj && paramValue) {
      return parameterObj[paramValue];
    }
    return defaultValue;
  };

  const dateRegex = /^(0[1-9]|1[0-2])-(20)\d{2}$/;
  const isDateValid = (date: any) => dateRegex.test(date);
  const setFiltersFromURL = () => {
    const urlClientStatus = getURLParameter(
      'clientStatus',
      selectedClientStatus,
      clientStatusObj
    );
    const urlStatus = getURLParameter('status', reportStatus, statusObj);
    const urlType = getURLParameter('type', type, typeObj);
    const urlCycle = getURLParameter('cycle', cycle, cycleObj);
    const urlDate = isDateValid(searchParams.get('date'))
      ? searchParams.get('date')
      : reportDate;
    const urlAll = getURLParameter('all', all, clientsObj);
    const URLSelect = getURLParameter('select', select, missingOption);
    const urlHealthStatus = getURLParameter(
      'healthStatus',
      healthStatus,
      healthStatusObj
    );
    const defaultType =
      urlStatus.id === 'draft' && urlType.id === 'all'
        ? getUserReportType(user)
        : urlType;
    return {
      cycle: urlCycle,
      type: defaultType,
      reportDate: urlDate,
      selectedClientStatus: urlClientStatus,
      reportStatus: urlStatus,
      healthStatus: urlHealthStatus,
      all: urlAll ? urlAll : '',
      select:
        !urlType.id?.includes('seo') && URLSelect.id === 'missing_links'
          ? seoMissingOptions[0]
          : URLSelect,
    };
  };

  useEffect(() => {
    const usersStorage = localStorage.getItem('monthly-report-filter-storage');
    setMonthlyClientUserFilterStorage(JSON.parse(usersStorage));

    const handleStorage = (event) => {
      const getPerformanceFilter = JSON.parse(
        event?.target?.localStorage?.getItem('monthly-report-filter-storage')
      );

      setMonthlyClientUserFilterStorage(getPerformanceFilter);
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);



  useEffect(() => {
    const filters = {
      date: reportDate,
      type: type.id,
      cycle: cycle.id,
      all_users: all.id,
      missing_field: select?.id,
      report_basis: 'monthly',
      sortBy: sortingColumn,
      sortOrder: sortingDirection,
    };

    if (isMounted) {
      handleFetchReports();
      fetchReportSummary(filters);
    }

    setSelectedItems([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cycle,
    reportDate,
    type,
    page,
    selectedClientStatus,
    sortingColumn,
    sortingDirection,
    reportStatus,
    all,
    select,
    sortBy,
    sortOrder,
    healthStatus,
    monthlyClientUserFilterStorage,
  ]);



  useEffect(() => {
    setIsMounted(true);
    setPage(1);
    const filters = setFiltersFromURL();
    setFilters(filters);
    const defaultType =
      filters?.select?.id === 'draft' && filters?.type?.id === 'all'
        ? getUserReportType(user)
        : filters?.type?.id;
    if (isMounted) {
      handleFetchReports(
        filters.reportStatus?.id,
        false,
        defaultType,
        filters.cycle?.id,
        filters?.reportDate,
        filters.selectedClientStatus?.id,
        filters.all?.id,
        filters.select?.id,
        filters.healthStatus?.id
      );

      const summaryFilters = {
        date: filters?.reportDate,
        type: filters.type?.id,
        cycle: filters.cycle?.id,
        all_users: filters.all?.id,
        missing_field: filters.select?.id,
        report_basis: 'monthly',
      };

      fetchReportSummary(summaryFilters);
    }
  }, [location]);

  
  const handleFetchReports = (
    status?: any,
    clear?: any,
    ptype?: any,
    pcycle?: any,
    pdate?: any,
    pclientStatus?: any,
    pall?: any,
    pselect?: any,
    phealthStatus?: any
  ) => {
    let formattedMonthlyReportClientFilter = [];
    let formattedMonthlyReportUserFilter = [];

  

    if (monthlyClientUserFilterStorage?.type === 'clients') {
      formattedMonthlyReportClientFilter =
        monthlyClientUserFilterStorage?.options
          ? monthlyClientUserFilterStorage.options.map((option) => option.id)
          : [];
    } else {
      formattedMonthlyReportUserFilter = monthlyClientUserFilterStorage?.options
        ? monthlyClientUserFilterStorage.options.map((option) => option.id)
        : [];
    }
    const textToSearch = clear ? '' : searchText;
    const filters = {
      cycle: isSeoType ? pcycle || cycle.id : '',
      search: textToSearch,
      date: pdate || reportDate,
      page: page,
      limit: 20,
      type: ptype || type?.id,
      client_status: pclientStatus || selectedClientStatus.id,
      status: reportStatus.id === 'all' ? '' : status || reportStatus.id,
      all: pall === undefined ? (all.id ? all.id : '') : pall ? pall : '',
      select: pselect || select?.id,
      sortBy: sortingDirection === null ? '' : sortingColumn,
      sortOrder: sortingDirection === null ? '' : sortingDirection,
      healthStatus: phealthStatus || healthStatus.id,
      clients: formattedMonthlyReportClientFilter,
      users: formattedMonthlyReportUserFilter,
    };

    fetchReports(filters);
  };

  // on edit reportPreview modal
  const reportPreview = (report: any) => {
    setSelectedReport(report);
    setOpenPreviewModal(true);
  };
  // on edit client report modal
  const editClientReport = (report: any) => {
    setSelectedReport(report);
    setOpenEditModal(true);
  };

  //handle search dropdown
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ searchText: e.target.value });
    if (e.target.value === '') {
      handleFetchReports(false, true);
    }
  };
  // clear search bar
  const ClearSearchBar = () => {
    setFilters({ searchText: '' });
    handleFetchReports(false, true);
  };

  const handleFiltersChange = (type: any, val: any) => {
    let value = val?.id;
    if (type === 'date') value = moment(val).format('MM-YYYY');
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(type, value);
    const updatedSearch = searchParams?.toString();
    location.search = updatedSearch;
    navigate(location);
  };

  const statusUpdateOptions = [
    {
      label: isRejected ? 'Resubmit' : 'Submit',
      id: 'review',
      icon: 'Submit',
      display: isProgress || isRejected,
      enabled: true,
      color: '#005C9F',
    },
    {
      label: 'Validate',
      id: 'validate',
      icon: 'Validate',
      display: isReview,
      enabled: isValidatorUser(user),
      color: '#005C9F',
    },
    {
      label: 'Approve',
      id: 'approve',
      icon: 'Approve',
      display: isValidated,
      enabled: isApprovalUser(user),
      color: '#005C9F',
    },
    {
      label: isSent ? 'Resend' : 'Send',
      id: 'send',
      icon: 'Send',
      display: isApproved || isSent || isScheduled,
      enabled: isValidatorUser(user),
      color: '#005C9F',
    },
    {
      label: 'Schedule',
      id: 'schedule',
      icon: 'Schedule',
      display: isApproved || isScheduled,
      enabled: isValidatorUser(user),
      color: '#005C9F',
    },
    {
      label: 'Revoke',
      id: 'revoke',
      icon: 'Revoke',
      display: isReview || isCanceled,
      enabled: true,
      color: '#FF061C',
    },
    {
      label: 'Reject',
      id: 'reject',
      icon: 'Reject',
      display: isValidated || isReview || isProgress,
      enabled: isValidatorUser(user),
      color: '#FF061C',
    },
    {
      label: 'Invalidate',
      id: 'invalidate',
      icon: 'Invalidate',
      display: isValidated,
      enabled: true,
      color: '#FF061C',
    },
    {
      label: 'Cancel',
      id: 'cancel',
      icon: 'Cancel',
      display: isProgress || isReview || isRejected,
      enabled: true,
      color: '#FF061C',
    },
  ];

  const mixedStatus = statusUpdateOptions.every(
    (item) => item.display === false
  );

  const navToClientDetails = ({ client }: any) => {
    setClient(client);
  };

  return (
    <>
      <div className="bg-[#D2EEFF] mt-12 px-2 sm:mt-0 sm:py-3 sm:px-[32px] overflow-hidden min-h-[93dvh]   rounded-md dark:bg-[#191919] font-inter scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8 rounded-lg">
        <div className="bg-[#D2EEFF] pt-2 sm:pt-0 rounded-lg dark:bg-[#0C111D]">
          <div className="flex items-center justify-between mt-4 px-2 py-4 sm:mt-0 sm:pt-14 lg:p-4 bg-white dark:bg-gray-800 lg:sticky top-0 z-10 rounded-t-lg">
            <div className="flex flex-wrap items-center gap-2 sm:gap-4 relative">
              <div className=" relative w-[250px] sm:w-[380px] z-[20]">
                <ClientUserSearchBar
                  roleOptions={[
                    { value: 'clients', label: 'Clients' },
                    { value: 'users', label: 'Users' },
                  ]}
                  useFilterStorage={monthlyClientUserFilterStorage}
                  storageKey="monthly-report-filter-storage"
                />
              </div>
              <div className="w-[80px] sm:w-[100px] z-40 font-inter">
                <DatePicker
                  selected={moment(reportDate, 'MM-yyyy').toDate()}
                  onChange={(date) => {
                    if (date !== null) {
                      handleFiltersChange('date', date);
                    }
                  }}
                  dateFormat="MMM yyyy"
                  maxDate={maxDate}
                  showMonthYearPicker
                  className="react-datepicker__month-container text-center h-[38px] border border-[#D1D5DB] cursor-pointer myDatePicker dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                />
              </div>

              {!selectedItems.length ? (
                <>
                  <SearchDropdown
                    customStyle={{ width: 130 }}
                    labelKey="label"
                    valueKey="id"
                    onSelect={(item) => handleFiltersChange('all', item)}
                    defaultValue={all.label}
                    placeholder={all.label}
                    options={clientsOptions || []}
                  />
                  <SearchDropdown
                    customStyle={{ width: 150 }}
                    labelKey="label"
                    valueKey="id"
                    onSelect={(type) => handleFiltersChange('type', type)}
                    defaultValue={type.label}
                    placeholder={type.label}
                    options={typeOptions || []}
                  />
                  {isSeoType && (
                    <SearchDropdown
                      customStyle={{ width: 120 }}
                      labelKey="label"
                      valueKey="id"
                      onSelect={(value) => handleFiltersChange('cycle', value)}
                      defaultValue={cycle.label}
                      placeholder={cycle.label}
                      options={cycleOptions || []}
                    />
                  )}

                  {isDraft && (
                    <SearchDropdown
                      customStyle={{ width: 120 }}
                      labelKey="label"
                      valueKey="id"
                      onSelect={(status) =>
                        handleFiltersChange('clientStatus', status)
                      }
                      defaultValue={selectedClientStatus.label}
                      placeholder={selectedClientStatus.label}
                      options={clientStatusOptions || []}
                    />
                  )}
                  {!isDraft && (
                    <SearchDropdown
                      customStyle={{ width: 165 }}
                      labelKey="label"
                      valueKey="id"
                      onSelect={(type) => handleFiltersChange('select', type)}
                      defaultValue={select?.label}
                      placeholder={select?.label}
                      options={
                        type.id.includes('seo')
                          ? seoMissingOptions
                          : missingOptions
                      }
                    />
                  )}
                </>
              ) : (
                <></>
              )}
              {/* mobile view */}
              <div className="flex sm:hidden items-center gap-2">
                {(isProgress || isDraft) && (
                  <button
                    className="p-2 rounded-md border"
                    onClick={() => setUploadModal(true)}
                  >
                    <Icon
                      name="upload"
                      color={mode === 'dark' ? '#fff' : iconColor}
                      size={16}
                    />
                  </button>
                )}
  
                {isStaging() && (
                  <button
                    className="p-2 rounded-md border"
                    onClick={() => setShowExportModal(true)}
                  >
                    <Icon
                      name="Download"
                      size={16}
                      color={mode === 'dark' ? '#fff' : iconColor}
                    />
                  </button>
                )}
              </div>
              {/* end mobile view */}
              <div
                style={{
                  display: 'flex',
                  marginLeft: 10,
                }}
              >
                {selectedItems?.length ? (
                  <>
                    {statusUpdateOptions?.map((item, idx) => {
                      const opacity = item.enabled ? 1 : 0.5;
                      if (item.display)
                        return (
                          <ButtonIcon
                            key={idx}
                            icon={item.icon}
                            color={item.color}
                            onClick={() =>
                              item.enabled && setConfirmationModal(item.id)
                            }
                            style={{ marginLeft: 10, opacity: opacity }}
                            variant="contained"
                          >
                            {item.label}
                          </ButtonIcon>
                        );
                    })}
                  </>
                ) : null}
              </div>
            </div>
            <div className="hidden sm:flex items-center gap-x-2">
              {(isProgress || isDraft) && (
                <button
                  className="p-2 rounded-md border"
                  onClick={() => setUploadModal(true)}
                >
                  <Icon
                    name="upload"
                    color={mode === 'dark' ? '#fff' : iconColor}
                    size={16}
                  />
                </button>
              )}
              {/* <button
                className="min-w-[122px] p-2 border border-[#005C9F] bg-[#005C9F] text-white rounded-lg text-sm"
                onClick={() => window.open('/insights', '_blank')}
              >
                Report Insight
              </button> */}
              {isStaging() && (
                <button
                  className="p-2 rounded-md border"
                  onClick={() => setShowExportModal(true)}
                >
                  <Icon
                    name="Download"
                    size={16}
                    color={mode === 'dark' ? '#fff' : iconColor}
                  />
                </button>
              )}
            </div>
          </div>
          <div className="relative z-[9]">
            <TanStackTable
              data={tableData}
              columns={columns}
              openVisibility={openVisibility}
              setOpenVisibility={setOpenVisibility}
              page={page}
              setPage={setPage}
              totalPages={reports.total_pages}
              loading={loading}
              setSelectedRow={setSelectedItems}
              totalDocs={reports?.total_docs}
              stickyColumn="Client"
              setRowState={setRowState}
              rowState={rowState}
              status={reportStatus}
              tableHeight="h-[64dvh] sm:h-[69.8dvh]"
            />
          </div>
        </div>
        <CustomModal
          open={openPreviewModal}
          onClose={() => setOpenPreviewModal(false)}
        >
          <ReportPreview
            onClose={() => setOpenPreviewModal(false)}
            clientReport={selectedReport}
          />
        </CustomModal>
        <CustomModal
          open={openEditModal}
          onClose={() => {
            if (pendingChanges) {
              setPendingChangesModal(true);
            } else {
              setPendingChangesModal(false);
              setOpenEditModal(false);
              localStorage.removeItem('reportForm');
            }
          }}
        >
          <Report
            onClose={() => {
              setPendingChangesModal(false);
              setPendingChanges(false);
              setOpenEditModal(false);
            }}
            clientReport={selectedReport}
            handleFetchReports={handleFetchReports}
            pendingChangesModal={pendingChangesModal}
            setPendingChangesModal={setPendingChangesModal}
            setPendingChanges={setPendingChanges}
          />
        </CustomModal>
        <CustomModal
          open={openReportInsight}
          onClose={() => setOpenReportInsight(false)}
        >
          <ReportInsightV2 onClose={() => setOpenReportInsight(false)} />
        </CustomModal>
        <CustomModal
          open={confirmationModal}
          onClose={(update) => {
            if (update === true) {
              handleFetchReports();
            }
            setRowState(false);
            setConfirmationModal(false);
          }}
        >
          <ConfrimReport
            selectedItems={selectedItems}
            confirmationType={confirmationModal}
            onClose={(update) => {
              if (update === true) {
                handleFetchReports();
              }
              setRowState(false);
              setConfirmationModal(false);
            }}
            setSelectedItems={setSelectedItems}
          />
        </CustomModal>
        <CustomModal open={uploadModal} onClose={() => setUploadModal(false)}>
          <ImportCSVModal
            reportDate={reportDate}
            tableData={tableData}
            reportStatus={reportStatus}
            handleFetchReports={handleFetchReports}
            onClose={() => setUploadModal(false)}
            type={type}
          />
        </CustomModal>
        <CustomModal
          open={showExportModal}
          onClose={() => {
            setRowState(false);
            setShowExportModal(false);
          }}
        >
          <ExportModal
            closeModal={() => {
              setRowState(false);
              setShowExportModal(false);
            }}
            selectDate={reportDate}
          />
        </CustomModal>
        {mixedStatus ? (
          <div className="absolute top-2 right-2 z-[999] w-[295px] bg-[#FFE8ED]  p-2 rounded-lg border-l-4 border-l-[#FF003B] font-inter">
            <span className="text-sm font-[600]  text-[#FF003B] flex justify-between">
              <Icon name="AlertCircle" />
              Action not possible with mixed status.{' '}
            </span>
            <span className="text-xs text-[#FF003B] font-[400]">
              Please select reports with same status.
            </span>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ReportsV2;