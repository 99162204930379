import Icon from '../../../assets/icons/SvgComponent';

interface IProps {
  withIcon?: boolean;
  periodHaveBorder?: boolean;
  type?: 'period' | 'year';
  title?: string;
  value: string;
  size?: 'small' | 'medium' | 'large';
  themeStyle?: 'light' | 'dark';
}

const TrendTxt = ({
  withIcon = true,
  periodHaveBorder = true,
  type,
  title,
  themeStyle,
  value,
  size,
}: IProps) => {
  const valueTrend = value?.includes('+')
    ? true
    : value?.includes('-')
      ? false
      : true;
  let txtSize =
    size === 'medium'
      ? 'text-base'
      : size === 'large'
        ? 'text-lg font-semibold'
        : 'text-[12px]';

  if (!value) return null;

  return (
    <div
      className={`w-full flex align-center mb-2  ${
        type === 'period'
          ? periodHaveBorder
            ? 'text-[#005C9F] border-b-[2px] border-[#005C9F]'
            : 'text-[#005C9F]'
          : 'text-[#005C9F]'
      } ${txtSize} font-normal pb-2 md:mt-2`}
    >
      {title && (
        <span
          className={`flex flex-col justify-center font-[400] text-[11px] text-[#808DA1] dark:text-[#eee]`}
        >
          {title}
        </span>
      )}
      {valueTrend ? (
        <div className="trend-up m-0 py-[2px]  rounded-full ml-2 dark:bg-white">
          {withIcon ? (
            <div className="flex items-center">
              {value !== '0' && <Icon name="trendUp" />}
              <span className="block text-[9px]">
                {value === '0'
                  ? '0%'
                  : `${value.includes('%') ? `+${value}` : `+${value}%`}`}
              </span>
            </div>
          ) : (
            <span className="block">{value ?? 0}</span>
          )}
        </div>
      ) : (
        <div className="trend-down m-0 py-[2px]  rounded-full ml-2 dark:bg-white">
          {withIcon ? (
            <div className="flex items-center">
              {value !== '0' && <Icon name="trendDown" />}
              <span className="block text-[9px]">
                {value === '0'
                  ? '0%'
                  : `${value.includes('%') ? `${value}` : `${value}%`}`}
              </span>
            </div>
          ) : (
            <span className="block">{value ?? 0}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default TrendTxt;
