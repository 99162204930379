import { useMemo } from "react";
import InfoTooltip from "../../AnalyticsSections/AnalyticsInfoTooltip";
import Tooltip from "../../HelpTooltip";
import Icon from '../../../assets/icons/SvgComponent';
import { formatNumberWithCommas } from "../../../utils/common";
import AnalyticsTable from "../../AnalyticsSections/AnalyticsTable";
import moment from "moment";

interface IProps {
  data?: any;
  isLoading?: boolean;
}

const AhrefBacklinks = ({
  data,
  isLoading
}: IProps) => {
  
  const backlinksResponse = data?.backlinks ?? [];

  const backlinksReportData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Url From',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Url From' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[20vw] h-fit pl-4">
                <div>Link</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Url From' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[20vw] h-fit pl-4">
                <div>Link</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Url From' || headerId === 'Url From') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[20vw] h-fit pl-4">
                <div>Link</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'url_from',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[180px] sm:w-[20vw] pl-4 h-fit dark:text-white">
            <Tooltip content={props.getValue()} position="right">
              <div className="truncate w-[150px] sm:max-w-[20vw] overflow-hidden">
                {props.getValue()}
              </div>
            </Tooltip>
          </div>
        ),
      },
      {
        id: 'Anchor',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Anchor' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[120px] sm:w-[15vw] h-fit">
                <div>Anchor</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Anchor' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[120px] sm:w-[15vw] h-fit">
                <div>Anchor</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Anchor' || headerId === 'Anchor') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[120px] sm:w-[15vw] h-fit">
                <div>Anchor</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'anchor',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[120px] sm:w-[15vw] h-fit dark:text-white">
            <Tooltip content={props.getValue()} position="right">
              <div className="truncate w-[110px] sm:max-w-[15vw] overflow-hidden">
                {props.getValue()}
              </div>
            </Tooltip>
          </div>
        ),
      },
      {
        id: 'Domain Rating Source',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Domain Rating Source' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[12vw] h-fit">
                <div>Domain Rating Source</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Domain Rating Source' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[12vw] h-fit">
                <div>Domain Rating Source</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Domain Rating Source' ||
            headerId === 'Domain Rating Source'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[12vw] h-fit">
                <div>Domain Rating Source</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'domain_rating_source',
        cell: (props: any) => {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[12vw] h-fit dark:text-white">
              <div className="w-[35px] text-center">{props.getValue()}</div>
            </div>
          );
        },
      },
      {
        id: 'Url Rating Source',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Url Rating Source' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Url Rating Source</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Url Rating Source' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Url Rating Source</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Url Rating Source' ||
            headerId === 'Url Rating Source'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Url Rating Source</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'url_rating_source',
        cell: (props: any) => {
          // const progressValue = Number(props.getValue());
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit dark:text-white">
              <div className="w-[35px] text-center">{props.getValue()}</div>
            </div>
          );
        },
      },
      {
        id: 'Last Seen',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Last Seen' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Last Crawled</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Last Seen' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Last Crawled</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Last Seen' || headerId === 'Last Seen') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Last Crawled</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'last_seen',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit dark:text-white">
            {moment(props.getValue()).fromNow() === 'Invalid date'
              ? 'N/A'
              : moment(props.getValue()).fromNow()}
          </div>
        ),
      },
    ];

    const data = !backlinksResponse?.error && (backlinksResponse || [])?.map((resource: any) => {
      return {
        url_from: resource?.url_from,
        anchor: resource?.anchor,
        domain_rating_source: Number(resource?.domain_rating_source),
        url_rating_source: Number(resource?.url_rating_source),
        last_seen: resource?.last_seen,
      };
    });
    return { tableHeader, data };
  }, [backlinksResponse]);
  
  return (
    <div className="relative">
      <AnalyticsTable
        data={backlinksReportData?.data ? backlinksReportData?.data : []}
        columns={backlinksReportData?.tableHeader}
        loading={isLoading}
        totalDocs={
          backlinksReportData?.data ? backlinksReportData?.data.length : 'N/A'
        }
        sortByColumnId="Domain Rating Source"
        pagination={true}
        // error={ahrefsLinks.error}
        // retryMethod={retryFetchAhrefLinks}
      />
    </div>
  )
}

export default AhrefBacklinks;