import React, { useState, useEffect, useMemo, useRef } from 'react';
import BarCharts from '../../components/Insights/BarChart/positionOpportunity';
import PieChart from '../../components/Insights/PieChart/positionOpportunity';
import 'react-datepicker/dist/react-datepicker.css';
import useToolStore from '../../store/useToolStore';
import useAuthStore from '../../store/useAuthStore';
import useUserStore from '../../store/useUserStore';
import ReactSelect from '../../components/ReactSelect';
import { useLocation, useParams } from 'react-router-dom';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import ReactDOM from 'react-dom';

interface StackedBarChartProps {
  data: {
      name: string;
      '1-3': number;
      '4-10': number;
      '11-20': number;
      '21-50': number;
      '51-100': number;
  }[];
}

const StackedBarChart: React.FC<StackedBarChartProps> = ({ data }) => {
  return (
      <ResponsiveContainer width="60%" height={300}>
          <BarChart data={data} style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
              <XAxis dataKey="name" style={{ fontFamily: 'Arial, Helvetica, sans-serif' }} />
              <YAxis style={{ fontFamily: 'Arial, Helvetica, sans-serif' }} />
              <Tooltip contentStyle={{ fontFamily: 'Arial, Helvetica, sans-serif' }} />
              <Legend wrapperStyle={{ fontFamily: 'Arial, Helvetica, sans-serif' }} />
              <Bar dataKey="1-3" stackId="a" fill="#fdc23d" />
              <Bar dataKey="4-10" stackId="a" fill="#016dc9" />
              <Bar dataKey="11-20" stackId="a" fill="#008ff8" />
              <Bar dataKey="21-50" stackId="a" fill="#2cb3ff" />
              <Bar dataKey="51-100" stackId="a" fill="#8fcdff" radius={[10, 10, 0, 0]}/>
          </BarChart>
      </ResponsiveContainer>
  );
};

const ContactBuilder = () => {
  const [activeTab, setActiveTab] = useState('potentialLeads');
  const { currentUser } = useAuthStore((state) => state);
  const { fetchReports, fetchCompetitorData, fetchQuotas, loading } = useToolStore((state) => state);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [reportData, setReportData] = useState({});
  const location = useLocation();
  const client_id = location.pathname.split('/')[4];
  const [payload, setPayload] = useState({
    role: '',
    force: false,
    hubspot_id: '',
    report_id : client_id,
    duplicate: false,
    duplicateData: {},
    data: [],
    full_data: {},
    userEmail: '',
    emailData:{},
    emailLoading: false,
    keyword: '',
    database: '',
    databaseLabel: '',
    balance: '',
    semBalance: '',
    resets: '',
    selectedItem: null,
    emailItem: null,
    showModal: false,
    showEmailModal: false,
    chartData: [],
    fourtoten: null,
    intent1:{},
    intent2:{},
    intent3:{},
    showEmailGenerator: false,
    emailSubject: '',
    emailContent: '',
  });

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const formatMonthYear = (month, year) => `${year}-${String(month + 1).padStart(2, '0')}`;
  const previousMonthYear = formatMonthYear(currentMonth === 0 ? 11 : currentMonth - 1, currentMonth === 0 ? currentYear - 1 : currentYear);

  const { 
    data, 
    full_data,
    report_id,
    force,
    hubspot_id,
    duplicate,
    duplicateData,
    userEmail,
    emailData, 
    emailLoading,
    keyword, 
    database, 
    databaseLabel, 
    balance, 
    semBalance, 
    resets, 
    selectedItem, 
    emailItem, 
    showModal, 
    showEmailModal, 
    chartData, 
    fourtoten,
    intent1,
    intent2,
    intent3 } = payload;

  const dbOptions = [
    { label: 'United States', value: 'US' },
    { label: 'United Kingdom', value: 'GB' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'Australia', value: 'AU' },
    { label: 'Canada', value: 'CA' },
    { label: 'India', value: 'IN' },
  ];

  const [companyFilters, setCompanyFilters] = useState({
    currentClient: true,
    pastClient: true,
    newProspect: true
  });

  const [selectedContacts, setSelectedContacts] = useState([]);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [statusFilteredData, setStatusFilteredData] = useState([]);
  const [statusFlags, setStatusFlags] = useState({
    inCRM: true,
    notInCRM: true,
    qualifiedLead: true,
    newLead: true,
    otherStatus: true,
    hasActiveDeal: true,
    doNotContact: true
  });

  useEffect(() => {
    setCompanyFilters({
      currentClient: true,
      pastClient: true,
      newProspect: true
    });
    
    if (data) {
      setStatusFilteredData(data);
      console.log("Initial data loaded:", data.length);
    }
  }, [data]);

  const applyStatusFilters = (filters) => {
    if (!data) return;
    console.log("Applying company filters:", filters);
    const filtered = data.filter(contact => {
      const status = contact.companyStatus || contact.status || '';
      const isCurrentClient = status.toLowerCase().includes('current');
      const isPastClient = status.toLowerCase().includes('past');
      const isNewProspect = status.toLowerCase().includes('prospect') || 
                            (!isCurrentClient && !isPastClient);
      
      if (isCurrentClient && !filters.currentClient) return false;
      if (isPastClient && !filters.pastClient) return false;
      if (isNewProspect && !filters.newProspect) return false;
      
      return true;
    });
    
    // After applying company filters, apply status flag filters
    applyStatusFlagFilters(filtered);
  };

  // Completely rewritten function to apply status flag filters
  const applyStatusFlagFilters = (companyFilteredData) => {
    if (!companyFilteredData) return;
    
    console.log("Applying status flag filters:", statusFlags);
    
    // Create a deep copy of the data to avoid mutation issues
    const processedData = companyFilteredData.map(company => {
      // Clone the company object
      const companyCopy = { ...company };
      
      // Filter the contacts based on status flags
      companyCopy.contacts = company.contacts.filter(contact => {
        // Check if contact should be visible based on status flags
        if (contact.isInCRM && !statusFlags.inCRM) return false;
        if (!contact.isInCRM && !statusFlags.notInCRM) return false;
        if (contact.leadStatus === 'Qualified' && !statusFlags.qualifiedLead) return false;
        if (contact.leadStatus === 'New' && !statusFlags.newLead) return false;
        if (contact.leadStatus && 
            contact.leadStatus !== 'Qualified' && 
            contact.leadStatus !== 'New' && 
            !statusFlags.otherStatus) return false;
        if (contact.hasDeal && !statusFlags.hasActiveDeal) return false;
        
        return true;
      });
      
      return companyCopy;
    }).filter(company => company.contacts.length > 0); // Remove companies with no visible contacts
    
    console.log("After filtering, showing", processedData.length, "companies with matching contacts");
    setStatusFilteredData(processedData);
  };

  // Update the useEffect to handle both filters
  useEffect(() => {
    if (data) {
      // Apply company filters first
      const companyFiltered = data.filter(company => {
        const status = company.companyStatus || company.status || '';
        const isCurrentClient = status.toLowerCase().includes('current');
        const isPastClient = status.toLowerCase().includes('past');
        const isNewProspect = status.toLowerCase().includes('prospect') || 
                              (!isCurrentClient && !isPastClient);
      
        if (isCurrentClient && !companyFilters.currentClient) return false;
        if (isPastClient && !companyFilters.pastClient) return false;
        if (isNewProspect && !companyFilters.newProspect) return false;
        
        return true;
      });
      
      // Then apply status flag filters
      applyStatusFlagFilters(companyFiltered);
    }
  }, [data, companyFilters, statusFlags]);

  const EmailModal = ({ item, onClose }) => {
    // Move useRef to the top level of the component
    const emailChartRef = useRef(null);
    
    if (!item) return null;
    const options = (emailData as { competitors: { keywords: { Keyword: string; Position: string; 'Search Volume': string; CPC: string; Competition: string; }[] } }).competitors?.keywords?.map(kw => ({
      value: kw, 
      label: kw.Keyword
    })) || [];
    
    console.log("EmailModal - All available keywords for dropdown:", options.map(opt => opt.label));
    
    // Get original keyword info
    const originalKeyword = (emailData as { originalKeyword?: string })?.originalKeyword || '';
    const originalKeywordFound = (emailData as { originalKeywordFound?: boolean })?.originalKeywordFound || false;
    
    console.log("EmailModal - Original keyword:", originalKeyword);
    console.log("EmailModal - Found in results:", originalKeywordFound);
    console.log("EmailModal - First dropdown value:", intent1);
    
    if (emailLoading || !fourtoten) {
      return (
      <>
      <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={onClose}
        />
        <div style={{top: '4rem', width: '75%'}} className="fixed right-0 h-full bg-white dark:bg-gray-800 shadow-lg z-50 p-4 overflow-x-auto">
          <div className="flex justify-between items-center mb-4">
            <div>
                <h2 className="text-xl font-bold">Email Template</h2>
                {emailLoading && <p className="text-sm text-gray-500">Loading data, please wait...</p>}
            </div>
              <button 
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 ml-6"
              >
                ✕
              </button>
            </div>
            
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        </div>
      </>
      );
    }
    
    return (
      <>
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={onClose}
        />
        <div style={{top: '4rem', width: '75%'}} className="fixed right-0 h-full bg-white dark:bg-gray-800 shadow-lg z-50 p-4 overflow-x-auto">
          <div className="flex justify-between items-center mb-4">
            <div>
              <h2 className="text-xl font-bold">Email Template</h2>
            </div>
            <div className="flex items-center">


            </div>
            <button 
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 ml-2"
              >
                ✕
              </button>
          </div>
          
          <div className="flex justify-between items-center mb-4">

            <div className="flex space-x-4">
              <div>
                  <ReactSelect
                    options={[
                      { value: null, label: "Clear selection" },
                      ...options
                    ]}
                    value={options.find(option => option.value === intent1) || null}
                    handleOnChange={(selectedOption: any) => {
                      setPayload(prevPayload => ({
                        ...prevPayload,
                        intent1: selectedOption && selectedOption.value !== null ? selectedOption.value : {},
                      }));
                    }}
                    placeholder="Select a 1st Intent Keyword"
                  />
                </div>
                <div>
                  <ReactSelect
                    options={[
                      { value: null, label: "Clear selection" },
                      ...options
                    ]}
                    value={options.find(option => option.value === intent2) || null}
                    handleOnChange={(selectedOption: any) => {
                      setPayload(prevPayload => ({
                        ...prevPayload,
                        intent2: selectedOption && selectedOption.value !== null ? selectedOption.value : {},
                      }));
                    }}
                    placeholder="Select a 2nd Intent Keyword"
                  />
                </div>
                <div>
                  <ReactSelect
                    options={[
                      { value: null, label: "Clear selection" },
                      ...options
                    ]}
                    value={options.find(option => option.value === intent3) || null}
                    handleOnChange={(selectedOption: any) => {
                      setPayload(prevPayload => ({
                        ...prevPayload,
                        intent3: selectedOption && selectedOption.value !== null ? selectedOption.value : {},
                      }));
                    }}
                    placeholder="Select a 3rd Intent Keyword"
                  />
              </div>
              <button 
                className={`bg-gradient-to-r from-blue-600 to-indigo-700 hover:from-blue-700 hover:to-indigo-800 text-white py-2 px-4 rounded-md text-sm font-medium transition-all duration-200 flex items-center justify-center shadow-md hover:shadow-lg transform hover:-translate-y-0.5 ${Object.keys(intent1 || {}).length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={async (event) => {
                  // Don't proceed if no keywords are selected
                  if (Object.keys(intent1 || {}).length === 0) {
                    alert("Please select at least the first keyword before generating the email.");
                    return;
                  }
                  
                  try {
                    // Set button to loading state
                    const button = event.currentTarget as HTMLButtonElement;
                    const originalText = button.innerHTML;
                    button.innerHTML = `<svg class="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg> Processing...`;
                    button.disabled = true;

                    // Build email subject
                    const emailSubject = "You're not being found for RELEVANT_CLIENT_SERVICE";
                    
                    // Get the SVG element
                    if (emailChartRef.current) {
                      const svgElement = emailChartRef.current.querySelector('svg');
                      if (svgElement) {
                        // Create a canvas with 2x resolution for better quality
                        const svgRect = svgElement.getBoundingClientRect();
                        const canvas = document.createElement('canvas');
                        const scale = 2;
                        canvas.width = svgRect.width * scale;
                        canvas.height = svgRect.height * scale;
                        const ctx = canvas.getContext('2d');
                        
                        // Set white background
                        ctx.fillStyle = 'white';
                        ctx.fillRect(0, 0, canvas.width, canvas.height);
                        
                        // Scale the context to improve resolution
                        ctx.scale(scale, scale);
                        
                        // Convert SVG to a data URL
                        const svgData = new XMLSerializer().serializeToString(svgElement);
                        const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
                        const url = URL.createObjectURL(svgBlob);
                        
                        // Create an image from the SVG
                        const img = new Image();
                        img.onload = async function() {
                          // Draw the original chart image
                          ctx.drawImage(img, 0, 0);
                          
                          // Convert original chart to PNG data URL
                          const chartPngDataUrl = canvas.toDataURL('image/png');
                          
                          // Create a second canvas for the keyword ranking visualization
                          const keywordCanvas = document.createElement('canvas');
                          const keywordCtx = keywordCanvas.getContext('2d');
                          
                          // Set dimensions for the keyword visualization
                          const keywordCanvasWidth = canvas.width;
                          const keywordCanvasHeight = 400; // Height for the keyword table
                          keywordCanvas.width = keywordCanvasWidth;
                          keywordCanvas.height = keywordCanvasHeight;
                          
                          // Fill with white background
                          keywordCtx.fillStyle = '#FFFFFF';
                          keywordCtx.fillRect(0, 0, keywordCanvas.width, keywordCanvas.height);
                          
                          // Draw the keyword ranking visualization header
                          keywordCtx.fillStyle = '#F9FAFB';
                          keywordCtx.fillRect(0, 0, keywordCanvas.width, 50);
                          keywordCtx.fillStyle = '#1F2937';
                          keywordCtx.font = 'bold 18px Arial';
                          keywordCtx.textAlign = 'left';
                          keywordCtx.fillText('Keyword Ranking Visualization', 20, 30);
                          
                          // Draw the keyword table
                          const tableTop = 60;
                          const rowHeight = 60;
                          const headerHeight = 40;
                          const colWidths = [
                            keywordCanvasWidth * 0.1, // Number column
                            keywordCanvasWidth * 0.4, // Keyword column
                            keywordCanvasWidth * 0.2, // Search Volume column
                            keywordCanvasWidth * 0.15, // Position column
                            keywordCanvasWidth * 0.15  // Page column
                          ];
                          
                          // Calculate column positions
                          const colPos = [0];
                          for (let i = 0; i < colWidths.length; i++) {
                            colPos.push(colPos[i] + colWidths[i]);
                          }
                          
                          // Draw table header
                          keywordCtx.fillStyle = '#F3F4F6';
                          keywordCtx.fillRect(0, tableTop, keywordCanvas.width, headerHeight);
                          
                          // Draw header text
                          keywordCtx.fillStyle = '#6B7280';
                          keywordCtx.font = 'bold 14px Arial';
                          keywordCtx.textAlign = 'center';
                          keywordCtx.fillText('KEYWORD', colPos[0] + colWidths[0] + colWidths[1]/2, tableTop + headerHeight/2 + 5);
                          keywordCtx.fillText('SEARCH VOLUME', colPos[0] + colWidths[0] + colWidths[1] + colWidths[2]/2, tableTop + headerHeight/2 + 5);
                          keywordCtx.fillText('POSITION', colPos[0] + colWidths[0] + colWidths[1] + colWidths[2] + colWidths[3]/2, tableTop + headerHeight/2 + 5);
                          keywordCtx.fillText('PAGE', colPos[0] + colWidths[0] + colWidths[1] + colWidths[2] + colWidths[3] + colWidths[4]/2, tableTop + headerHeight/2 + 5);
                          
                          // Draw header dividers
                          keywordCtx.strokeStyle = '#E5E7EB';
                          keywordCtx.lineWidth = 1;
                          keywordCtx.beginPath();
                          for (let i = 1; i < colPos.length; i++) {
                            keywordCtx.moveTo(colPos[i], tableTop);
                            keywordCtx.lineTo(colPos[i], tableTop + headerHeight);
                          }
                          keywordCtx.stroke();
                          
                          // Draw horizontal line below header
                          keywordCtx.beginPath();
                          keywordCtx.moveTo(0, tableTop + headerHeight);
                          keywordCtx.lineTo(keywordCanvas.width, tableTop + headerHeight);
                          keywordCtx.stroke();
                          
                          // Function to draw a keyword row
                          const drawKeywordRow = (index, keyword, volume, position, page, rowY, circleColor) => {
                            // Draw row background
                            keywordCtx.fillStyle = '#FFFFFF';
                            keywordCtx.fillRect(0, rowY, keywordCanvas.width, rowHeight);
                            
                            // Draw circle with number
                            keywordCtx.fillStyle = circleColor;
                            keywordCtx.beginPath();
                            keywordCtx.arc(colPos[0] + colWidths[0]/2, rowY + rowHeight/2, 20, 0, 2 * Math.PI);
                            keywordCtx.fill();
                            
                            // Draw number in circle
                            keywordCtx.fillStyle = '#FFFFFF';
                            keywordCtx.font = 'bold 16px Arial';
                            keywordCtx.textAlign = 'center';
                            keywordCtx.fillText(index.toString(), colPos[0] + colWidths[0]/2, rowY + rowHeight/2 + 5);
                            
                            // Draw keyword
                            keywordCtx.fillStyle = '#000000';
                            keywordCtx.font = '16px Arial';
                            keywordCtx.textAlign = 'left';
                            keywordCtx.fillText(`"${keyword}"`, colPos[1] + 15, rowY + rowHeight/2 + 5);
                            
                            // Draw search volume
                            keywordCtx.textAlign = 'center';
                            keywordCtx.fillStyle = '#000000';
                            keywordCtx.font = '16px Arial';
                            keywordCtx.fillText(volume, colPos[2] + colWidths[2]/2, rowY + rowHeight/2 - 5);
                            keywordCtx.font = '12px Arial';
                            keywordCtx.fillStyle = '#6B7280';
                            keywordCtx.fillText('monthly', colPos[2] + colWidths[2]/2, rowY + rowHeight/2 + 15);
                            
                            // Draw position
                            keywordCtx.font = '16px Arial';
                            keywordCtx.fillStyle = '#000000';
                            keywordCtx.fillText(position, colPos[3] + colWidths[3]/2, rowY + rowHeight/2 + 5);
                            
                            // Draw page with colored background
                            const pageWidth = 70;
                            const pageHeight = 30;
                            const pageX = colPos[4] + colWidths[4]/2 - pageWidth/2;
                            const pageY = rowY + rowHeight/2 - pageHeight/2;
                            
                            let pageColor = '#FEE2E2'; // Default red for page 3+
                            let textColor = '#B91C1C';
                            
                            if (page === '1') {
                              pageColor = '#DCFCE7'; // Green for page 1
                              textColor = '#166534';
                            } else if (page === '2') {
                              pageColor = '#FEF9C3'; // Yellow for page 2
                              textColor = '#854D0E';
                            }
                            
                            // Draw page pill
                            keywordCtx.fillStyle = pageColor;
                            keywordCtx.beginPath();
                            // Use arc for rounded corners if roundRect is not available
                            const radius = 5;
                            keywordCtx.moveTo(pageX + radius, pageY);
                            keywordCtx.lineTo(pageX + pageWidth - radius, pageY);
                            keywordCtx.arcTo(pageX + pageWidth, pageY, pageX + pageWidth, pageY + radius, radius);
                            keywordCtx.lineTo(pageX + pageWidth, pageY + pageHeight - radius);
                            keywordCtx.arcTo(pageX + pageWidth, pageY + pageHeight, pageX + pageWidth - radius, pageY + pageHeight, radius);
                            keywordCtx.lineTo(pageX + radius, pageY + pageHeight);
                            keywordCtx.arcTo(pageX, pageY + pageHeight, pageX, pageY + pageHeight - radius, radius);
                            keywordCtx.lineTo(pageX, pageY + radius);
                            keywordCtx.arcTo(pageX, pageY, pageX + radius, pageY, radius);
                            keywordCtx.fill();
                            
                            // Draw page text
                            keywordCtx.fillStyle = textColor;
                            keywordCtx.font = 'bold 14px Arial';
                            keywordCtx.textAlign = 'center';
                            keywordCtx.fillText(`Page ${page}`, colPos[4] + colWidths[4]/2, rowY + rowHeight/2 + 5);
                            
                            // Draw row divider
                            keywordCtx.strokeStyle = '#E5E7EB';
                            keywordCtx.beginPath();
                            keywordCtx.moveTo(0, rowY + rowHeight);
                            keywordCtx.lineTo(keywordCanvas.width, rowY + rowHeight);
                            keywordCtx.stroke();
                            
                            // Draw column dividers
                            keywordCtx.beginPath();
                            for (let i = 1; i < colPos.length; i++) {
                              keywordCtx.moveTo(colPos[i], rowY);
                              keywordCtx.lineTo(colPos[i], rowY + rowHeight);
                            }
                            keywordCtx.stroke();
                          };
                          
                          // Draw keyword rows
                          let currentRowY = tableTop + headerHeight;
                          
                          if (Object.keys(intent1 || {}).length > 0) {
                            drawKeywordRow(
                              1, 
                              (intent1 as { Keyword: string }).Keyword, 
                              (intent1 as { 'Search Volume': string })['Search Volume'], 
                              (intent1 as { Position: string }).Position, 
                              Math.ceil(Number((intent1 as { Position: string }).Position) / 10).toString(),
                              currentRowY,
                              '#4F46E5' // Blue color for first row
                            );
                            currentRowY += rowHeight;
                          }
                          
                          if (Object.keys(intent2 || {}).length > 0) {
                            drawKeywordRow(
                              2, 
                              (intent2 as { Keyword: string }).Keyword, 
                              (intent2 as { 'Search Volume': string })['Search Volume'], 
                              (intent2 as { Position: string }).Position, 
                              Math.ceil(Number((intent2 as { Position: string }).Position) / 10).toString(),
                              currentRowY,
                              '#A855F7' // Purple color for second row
                            );
                            currentRowY += rowHeight;
                          }
                          
                          if (Object.keys(intent3 || {}).length > 0) {
                            drawKeywordRow(
                              3, 
                              (intent3 as { Keyword: string }).Keyword, 
                              (intent3 as { 'Search Volume': string })['Search Volume'], 
                              (intent3 as { Position: string }).Position, 
                              Math.ceil(Number((intent3 as { Position: string }).Position) / 10).toString(),
                              currentRowY,
                              '#10B981' // Green color for third row
                            );
                            currentRowY += rowHeight;
                          }
                          
                          // Convert keyword visualization to PNG data URL
                          const keywordPngDataUrl = keywordCanvas.toDataURL('image/png');
                          
                          // Create a rich HTML email with both images
                          const richHtmlEmail = `<!DOCTYPE html>
              <html>
              <head>
              <meta charset="UTF-8">
              <title>Email Template</title>
              <style>
                body { font-family: Arial, sans-serif; line-height: 1.6; }
                p { margin-bottom: 16px; }
                .chart-image { max-width: 100%; margin: 15px 0; }
                strong { font-weight: bold; }

              </style>
              </head>
              <body>
              <p>Hey ${(emailData as { contact?: { firstName: string } }).contact?.firstName || 'there'},</p>

              <p>I hope you're well and don't mind me reaching out.</p>

              <p>We've worked with <strong>RELEVANT_CLIENTS</strong> and many others.</p>

              <p>Our team are running free online performance audits on the lead up to <strong>UPCOMING_EVENT</strong> and would love to take a look at the opportunities available to <strong>${item.displayLink}</strong> from an SEO/Ads perspective, free of charge. It's essentially a digital health check to ensure all your campaigns are performing optimally.</p>

              <p>As it stands, <strong>${item.displayLink} is ranking for ${(emailData as { competitors?: { keywordTotals: Array<{ "Organic Keywords": number }> } }).competitors?.keywordTotals?.[0]?.["Organic Keywords"] || 0} keywords</strong> which suggests a lot of room for growth and improvement in terms of online visibility. Of these keywords, <strong>${fourtoten && fourtoten['4-10'] ? fourtoten['4-10'] : 0} are in positions 4-10 on Google which is within striking distance of where you want them to be.</strong></p>

              <ul>
                ${Object.keys(intent1 || {}).length > 0 ? 
                  `<li><strong>"${(intent1 as { Keyword: string; 'Search Volume': string; Position: string }).Keyword}"</strong> is searched <strong>${(intent1 as { 'Search Volume': string })['Search Volume']}</strong> times per month and you're on <strong>page ${Math.ceil(Number((intent1 as { Position: string }).Position) / 10)}</strong> of Google</li>` : ''}
                ${Object.keys(intent2 || {}).length > 0 ? 
                  `<li><strong>"${(intent2 as { Keyword: string; 'Search Volume': string; Position: string }).Keyword}"</strong> is searched <strong>${(intent2 as { 'Search Volume': string })['Search Volume']}</strong> times per month and you're on <strong>page ${Math.ceil(Number((intent2 as { Position: string }).Position) / 10)}</strong> of Google</li>` : ''}
                ${Object.keys(intent3 || {}).length > 0 ? 
                  `<li><strong>"${(intent3 as { Keyword: string; 'Search Volume': string; Position: string }).Keyword}"</strong> is searched <strong>${(intent3 as { 'Search Volume': string })['Search Volume']}</strong> times per month and you're on <strong>page ${Math.ceil(Number((intent3 as { Position: string }).Position) / 10)}</strong> of Google</li>` : ''}
              </ul>

              <!-- Keyword Ranking Visualization Image -->
              <img src="${keywordPngDataUrl}" alt="Keyword Ranking Visualization for ${item.displayLink}" class="chart-image" style="width: 100%; max-width: 600px;" />

              <p>There is a lot of opportunity to direct more traffic and sales to your site. Plus, there are hundreds of additional high performing keywords which you're not yet ranking for.</p>
              
              <!-- Original Chart Image -->
              <img src="${chartPngDataUrl}" alt="Keyword Chart for ${item.displayLink}" class="chart-image" style="width: 100%; max-width: 600px;" />
              
              <p>If you're working with an agency at the moment or managing your marketing efforts inhouse, this will analyse how your campaigns are performing and we'll offer suggestions for improvement going forward.</p>

              <p><strong>RELEVANT_CASE_STUDY</strong></p>

              <p>Keen to connect if you have some time later today, otherwise this / next week? Let me know if you have any questions.</p>

              <a href="https://meetings.hubspot.com/omg-operations/sales-round-robin-meeting-link" style="color:blue;font-size:18px;font-weight:bold;" class="meeting-button">Book A Meeting Here</a>

              </body>
              </html>`;

                          // Create a temporary div to hold the rich HTML content
                          const tempDiv = document.createElement('div');
                          tempDiv.innerHTML = richHtmlEmail;
                          document.body.appendChild(tempDiv);
                          
                          // Select the content
                          const range = document.createRange();
                          range.selectNodeContents(tempDiv);
                          
                          const selection = window.getSelection();
                          selection.removeAllRanges();
                          selection.addRange(range);
                          
                          // Copy the rich HTML content to clipboard
                          try {
                            document.execCommand('copy');
                            
                            // Clean up
                            document.body.removeChild(tempDiv);
                            URL.revokeObjectURL(url);
                            
                            // Change button to success state
                            button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                            </svg> Copied!`;
                            button.disabled = false;
                            button.className = button.className.replace('from-blue-600 to-indigo-700 hover:from-blue-700 hover:to-indigo-800', 'from-green-500 to-green-600 hover:from-green-600 hover:to-green-700');
                            
                            // Reset button after 2 seconds
                            setTimeout(() => {
                              button.innerHTML = originalText;
                              button.className = button.className.replace('from-green-500 to-green-600 hover:from-green-600 hover:to-green-700', 'from-blue-600 to-indigo-700 hover:from-blue-700 hover:to-indigo-800');
                            }, 2000);
                          } catch (err) {
                            console.error("Copy command failed:", err);
                          }
                        };
                        img.src = url;
                      } else {
                        // Reset button state if SVG not found
                        button.innerHTML = originalText;
                        button.disabled = false;
                        alert("Couldn't find chart to copy. Please try again.");
                      }
                    } else {
                      // Reset button state if chart ref not found
                      button.innerHTML = originalText;
                      button.disabled = false;
                      alert("Couldn't find chart to copy. Please try again.");
                    }
                  } catch (err) {
                    console.error("Error:", err);
                    alert("An error occurred. Please try again.");
                    
                    // Reset button state on error
                    const button = event.currentTarget as HTMLButtonElement;
                    button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                    </svg> Copy To Clipboard`;
                    button.disabled = false;
                  }
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                </svg>
                Copy To Clipboard
              </button>
            </div>
                         

          </div>
           {/* Display warning about original keyword not found in a prominent location */}
           {originalKeyword && !originalKeywordFound && (
              <div className="mb-4 p-1 bg-amber-50 border border-amber-200 rounded-md">
                <span className="text-amber-600 font-medium text-sm">
                  Warning: Primary keyword "{originalKeyword}" not found in SEMRush data
                </span>
              </div>
            )}
          
          <div className="space-y-4">
            <div>
              <h3 className="font-semibold"></h3>
              <p><strong>Subject:</strong> </p>
              <p>You're not being found for <strong>{originalKeyword}</strong></p>
              <hr className="mt-4 mb-4"/>
              <p className="mb-4">Hey {(emailData as { contact?: { firstName: string } }).contact?.firstName || 'there'},</p>
              <p className="mb-4">I hope you're well and don't mind me reaching out.</p>
              <p className="mb-4">We've worked with <strong>RELEVANT_CLIENTS</strong> and many others.</p>
              <p className="mb-4">Our team are running free online performance audits on the lead up to <strong>UPCOMING_EVENT</strong> and would love to take a look at the opportunities available to <strong>{item.displayLink}</strong> from an SEO/Ads perspective, free of charge. It's essentially a digital health check to ensure all your campaigns are performing optimally.</p>
              <p className="mb-4">As it stands, <strong>{item.displayLink} is ranking for {(emailData as { competitors?: { keywordTotals: Array<{ "Organic Keywords": number }> } }).competitors?.keywordTotals?.[0]?.["Organic Keywords"] || 0} keywords</strong> which suggests a lot of room for growth and improvement in terms of online visibility. Of these keywords, <strong>{fourtoten && fourtoten['4-10'] ? fourtoten['4-10'] : 0} are in positions 4-10 on Google which is within striking distance of where you want them to be.</strong> </p>
              
              {/* Original keyword list - keeping this intact */}
              <ul className="mb-4">
                <li key={1}>
                  {Object.keys(intent1 || {}).length > 0 ? (
                    <>
                      <strong>&quot;{(intent1 as { Keyword: string; 'Search Volume': string; Position: string }).Keyword}&quot;</strong> is searched <strong>{(intent1 as { 'Search Volume': string })['Search Volume']}</strong> times per month and you're on <strong>page {Math.ceil(Number((intent1 as { Position: string }).Position) / 10)}</strong> of Google
                    </>
                  ) : (
                    <strong style={{ color: 'red' }}>Waiting for keyword 1 selection...</strong>
                  )}
                </li>
                <li key={2}>
                  {Object.keys(intent2 || {}).length > 0 ? (
                    <>
                      <strong>&quot;{(intent2 as { Keyword: string; 'Search Volume': string; Position: string }).Keyword}&quot;</strong> is searched <strong>{(intent2 as { 'Search Volume': string })['Search Volume']}</strong> times per month and you're on <strong>page {Math.ceil(Number((intent2 as { Position: string }).Position) / 10)}</strong> of Google
                    </>
                  ) : (
                    <strong style={{ color: 'red' }}>Waiting for keyword 2 selection...</strong>
                  )}
                </li>
                <li key={3}>
                  {Object.keys(intent3 || {}).length > 0 ? (
                    <>
                      <strong>&quot;{(intent3 as { Keyword: string; 'Search Volume': string; Position: string }).Keyword}&quot;</strong> is searched <strong>{(intent3 as { 'Search Volume': string })['Search Volume']}</strong> times per month and you're on <strong>page {Math.ceil(Number((intent3 as { Position: string }).Position) / 10)}</strong> of Google
                    </>
                  ) : (
                    <strong style={{ color: 'red' }}>Waiting for keyword 3 selection...</strong>
                  )}
                </li>
              </ul>
              
              {/* New enhanced visual representation - designed to be email-friendly */}
              <div className="mb-6" style={{
                fontFamily: 'Arial, sans-serif',
                border: '1px solid #e5e7eb',
                borderRadius: '8px',
                padding: '16px',
                backgroundColor: '#f9fafb'
              }}>
                <h3 style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  marginBottom: '12px',
                  color: '#1f2937'
                }}>Keyword Ranking Visualization</h3>
                
                {/* Email-friendly table */}
                <table style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  marginBottom: '16px',
                  border: '1px solid #e5e7eb',
                  borderRadius: '6px',
                  overflow: 'hidden',
                  backgroundColor: 'white'
                }}>
                  <thead>
                    <tr style={{ backgroundColor: '#f3f4f6' }}>
                      <th style={{ padding: '10px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#6b7280', textTransform: 'uppercase' }}>Keyword</th>
                      <th style={{ padding: '10px', textAlign: 'center', fontSize: '12px', fontWeight: '600', color: '#6b7280', textTransform: 'uppercase' }}>Search Volume</th>
                      <th style={{ padding: '10px', textAlign: 'center', fontSize: '12px', fontWeight: '600', color: '#6b7280', textTransform: 'uppercase' }}>Position</th>
                      <th style={{ padding: '10px', textAlign: 'center', fontSize: '12px', fontWeight: '600', color: '#6b7280', textTransform: 'uppercase' }}>Page</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Keyword 1 */}
                    {Object.keys(intent1 || {}).length > 0 ? (
                      <tr style={{ borderTop: '1px solid #e5e7eb' }}>
                        <td style={{ padding: '12px', fontSize: '14px', fontWeight: '500', color: '#1f2937' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ 
                              width: '24px', 
                              height: '24px', 
                              borderRadius: '50%', 
                              backgroundColor: '#dbeafe', 
                              display: 'flex', 
                              alignItems: 'center', 
                              justifyContent: 'center',
                              marginRight: '8px'
                            }}>
                              <span style={{ color: '#2563eb', fontWeight: 'bold' }}>1</span>
                            </div>
                            "{(intent1 as { Keyword: string }).Keyword}"
                          </div>
                        </td>
                        <td style={{ padding: '12px', textAlign: 'center' }}>
                          <div style={{ fontSize: '14px', fontWeight: '500', color: '#1f2937' }}>{(intent1 as { 'Search Volume': string })['Search Volume']}</div>
                          <div style={{ fontSize: '12px', color: '#6b7280' }}>monthly</div>
                        </td>
                        <td style={{ padding: '12px', textAlign: 'center' }}>
                          <div style={{ fontSize: '14px', fontWeight: '500', color: '#1f2937' }}>{(intent1 as { Position: string }).Position}</div>
                        </td>
                        <td style={{ padding: '12px', textAlign: 'center' }}>
                          <div style={{ 
                            display: 'inline-block',
                            padding: '4px 8px',
                            borderRadius: '9999px',
                            fontSize: '12px',
                            fontWeight: '600',
                            backgroundColor: Number((intent1 as { Position: string }).Position) <= 10 ? '#dcfce7' : 
                                            Number((intent1 as { Position: string }).Position) <= 20 ? '#fef9c3' : '#fee2e2',
                            color: Number((intent1 as { Position: string }).Position) <= 10 ? '#166534' : 
                                   Number((intent1 as { Position: string }).Position) <= 20 ? '#854d0e' : '#b91c1c'
                          }}>
                            Page {Math.ceil(Number((intent1 as { Position: string }).Position) / 10)}
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr style={{ borderTop: '1px solid #e5e7eb' }}>
                        <td colSpan={4} style={{ padding: '12px', textAlign: 'center', color: '#ef4444', fontSize: '14px' }}>
                          Please select your first keyword
                        </td>
                      </tr>
                    )}
                    
                    {/* Keyword 2 */}
                    {Object.keys(intent2 || {}).length > 0 ? (
                      <tr style={{ borderTop: '1px solid #e5e7eb' }}>
                        <td style={{ padding: '12px', fontSize: '14px', fontWeight: '500', color: '#1f2937' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ 
                              width: '24px', 
                              height: '24px', 
                              borderRadius: '50%', 
                              backgroundColor: '#f3e8ff', 
                              display: 'flex', 
                              alignItems: 'center', 
                              justifyContent: 'center',
                              marginRight: '8px'
                            }}>
                              <span style={{ color: '#9333ea', fontWeight: 'bold' }}>2</span>
                            </div>
                            "{(intent2 as { Keyword: string }).Keyword}"
                          </div>
                        </td>
                        <td style={{ padding: '12px', textAlign: 'center' }}>
                          <div style={{ fontSize: '14px', fontWeight: '500', color: '#1f2937' }}>{(intent2 as { 'Search Volume': string })['Search Volume']}</div>
                          <div style={{ fontSize: '12px', color: '#6b7280' }}>monthly</div>
                        </td>
                        <td style={{ padding: '12px', textAlign: 'center' }}>
                          <div style={{ fontSize: '14px', fontWeight: '500', color: '#1f2937' }}>{(intent2 as { Position: string }).Position}</div>
                        </td>
                        <td style={{ padding: '12px', textAlign: 'center' }}>
                          <div style={{ 
                            display: 'inline-block',
                            padding: '4px 8px',
                            borderRadius: '9999px',
                            fontSize: '12px',
                            fontWeight: '600',
                            backgroundColor: Number((intent2 as { Position: string }).Position) <= 10 ? '#dcfce7' : 
                                            Number((intent2 as { Position: string }).Position) <= 20 ? '#fef9c3' : '#fee2e2',
                            color: Number((intent2 as { Position: string }).Position) <= 10 ? '#166534' : 
                                   Number((intent2 as { Position: string }).Position) <= 20 ? '#854d0e' : '#b91c1c'
                          }}>
                            Page {Math.ceil(Number((intent2 as { Position: string }).Position) / 10)}
                          </div>
                        </td>
                      </tr>
                    ) : null}
                    
                    {/* Keyword 3 */}
                    {Object.keys(intent3 || {}).length > 0 ? (
                      <tr style={{ borderTop: '1px solid #e5e7eb' }}>
                        <td style={{ padding: '12px', fontSize: '14px', fontWeight: '500', color: '#1f2937' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ 
                              width: '24px', 
                              height: '24px', 
                              borderRadius: '50%', 
                              backgroundColor: '#dcfce7', 
                              display: 'flex', 
                              alignItems: 'center', 
                              justifyContent: 'center',
                              marginRight: '8px'
                            }}>
                              <span style={{ color: '#16a34a', fontWeight: 'bold' }}>3</span>
                            </div>
                            "{(intent3 as { Keyword: string }).Keyword}"
                          </div>
                        </td>
                        <td style={{ padding: '12px', textAlign: 'center' }}>
                          <div style={{ fontSize: '14px', fontWeight: '500', color: '#1f2937' }}>{(intent3 as { 'Search Volume': string })['Search Volume']}</div>
                          <div style={{ fontSize: '12px', color: '#6b7280' }}>monthly</div>
                        </td>
                        <td style={{ padding: '12px', textAlign: 'center' }}>
                          <div style={{ fontSize: '14px', fontWeight: '500', color: '#1f2937' }}>{(intent3 as { Position: string }).Position}</div>
                        </td>
                        <td style={{ padding: '12px', textAlign: 'center' }}>
                          <div style={{ 
                            display: 'inline-block',
                            padding: '4px 8px',
                            borderRadius: '9999px',
                            fontSize: '12px',
                            fontWeight: '600',
                            backgroundColor: Number((intent3 as { Position: string }).Position) <= 10 ? '#dcfce7' : 
                                            Number((intent3 as { Position: string }).Position) <= 20 ? '#fef9c3' : '#fee2e2',
                            color: Number((intent3 as { Position: string }).Position) <= 10 ? '#166534' : 
                                   Number((intent3 as { Position: string }).Position) <= 20 ? '#854d0e' : '#b91c1c'
                          }}>
                            Page {Math.ceil(Number((intent3 as { Position: string }).Position) / 10)}
                          </div>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
                
                {/* Visual position indicator - email friendly */}
                <div style={{ marginBottom: '12px' }}>
                  <div style={{ fontSize: '14px', fontWeight: '500', marginBottom: '8px', color: '#4b5563' }}>Google Search Position</div>
                  <div style={{ display: 'flex', height: '24px', borderRadius: '4px', overflow: 'hidden' }}>
                    {/* Page 1 */}
                    <div style={{ 
                      flex: '1', 
                      backgroundColor: '#dcfce7', 
                      position: 'relative',
                      borderTopLeftRadius: '4px',
                      borderBottomLeftRadius: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <span style={{ fontSize: '11px', fontWeight: '600', color: '#166534' }}>Page 1</span>
                      
                      {Object.keys(intent1 || {}).length > 0 && Number((intent1 as { Position: string }).Position) <= 10 && (
                        <div style={{ 
                          position: 'absolute',
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#2563eb',
                          borderRadius: '50%',
                          border: '2px solid white',
                          left: `${(Number((intent1 as { Position: string }).Position) - 1) * 10}%`
                        }}></div>
                      )}
                      
                      {Object.keys(intent2 || {}).length > 0 && Number((intent2 as { Position: string }).Position) <= 10 && (
                        <div style={{ 
                          position: 'absolute',
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#9333ea',
                          borderRadius: '50%',
                          border: '2px solid white',
                          left: `${(Number((intent2 as { Position: string }).Position) - 1) * 10}%`
                        }}></div>
                      )}
                      
                      {Object.keys(intent3 || {}).length > 0 && Number((intent3 as { Position: string }).Position) <= 10 && (
                        <div style={{ 
                          position: 'absolute',
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#16a34a',
                          borderRadius: '50%',
                          border: '2px solid white',
                          left: `${(Number((intent3 as { Position: string }).Position) - 1) * 10}%`
                        }}></div>
                      )}
                    </div>
                    
                    {/* Page 2 */}
                    <div style={{ 
                      flex: '1', 
                      backgroundColor: '#fef9c3', 
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <span style={{ fontSize: '11px', fontWeight: '600', color: '#854d0e' }}>Page 2</span>
                      
                      {Object.keys(intent1 || {}).length > 0 && Number((intent1 as { Position: string }).Position) > 10 && Number((intent1 as { Position: string }).Position) <= 20 && (
                        <div style={{ 
                          position: 'absolute',
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#2563eb',
                          borderRadius: '50%',
                          border: '2px solid white',
                          left: `${(Number((intent1 as { Position: string }).Position) - 11) * 10}%`
                        }}></div>
                      )}
                      
                      {Object.keys(intent2 || {}).length > 0 && Number((intent2 as { Position: string }).Position) > 10 && Number((intent2 as { Position: string }).Position) <= 20 && (
                        <div style={{ 
                          position: 'absolute',
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#9333ea',
                          borderRadius: '50%',
                          border: '2px solid white',
                          left: `${(Number((intent2 as { Position: string }).Position) - 11) * 10}%`
                        }}></div>
                      )}
                      
                      {Object.keys(intent3 || {}).length > 0 && Number((intent3 as { Position: string }).Position) > 10 && Number((intent3 as { Position: string }).Position) <= 20 && (
                        <div style={{ 
                          position: 'absolute',
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#16a34a',
                          borderRadius: '50%',
                          border: '2px solid white',
                          left: `${(Number((intent3 as { Position: string }).Position) - 11) * 10}%`
                        }}></div>
                      )}
                    </div>
                    
                    {/* Page 3+ */}
                    <div style={{ 
                      flex: '1', 
                      backgroundColor: '#fee2e2', 
                      position: 'relative',
                      borderTopRightRadius: '4px',
                      borderBottomRightRadius: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <span style={{ fontSize: '11px', fontWeight: '600', color: '#b91c1c' }}>Page 3+</span>
                      
                      {Object.keys(intent1 || {}).length > 0 && Number((intent1 as { Position: string }).Position) > 20 && (
                        <div style={{ 
                          position: 'absolute',
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#2563eb',
                          borderRadius: '50%',
                          border: '2px solid white',
                          left: `${Math.min((Number((intent1 as { Position: string }).Position) - 21) * 3.3, 90)}%`
                        }}></div>
                      )}
                      
                      {Object.keys(intent2 || {}).length > 0 && Number((intent2 as { Position: string }).Position) > 20 && (
                        <div style={{ 
                          position: 'absolute',
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#9333ea',
                          borderRadius: '50%',
                          border: '2px solid white',
                          left: `${Math.min((Number((intent2 as { Position: string }).Position) - 21) * 3.3, 90)}%`
                        }}></div>
                      )}
                      
                      {Object.keys(intent3 || {}).length > 0 && Number((intent3 as { Position: string }).Position) > 20 && (
                        <div style={{ 
                          position: 'absolute',
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#16a34a',
                          borderRadius: '50%',
                          border: '2px solid white',
                          left: `${Math.min((Number((intent3 as { Position: string }).Position) - 21) * 3.3, 90)}%`
                        }}></div>
                      )}
                    </div>
                  </div>
                </div>
                
                {/* Legend - email friendly */}
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px', fontSize: '12px', color: '#6b7280' }}>
                  {Object.keys(intent1 || {}).length > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: '10px', height: '10px', backgroundColor: '#2563eb', borderRadius: '50%', marginRight: '4px' }}></div>
                      <span>"{(intent1 as { Keyword: string }).Keyword}"</span>
                    </div>
                  )}
                  
                  {Object.keys(intent2 || {}).length > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: '10px', height: '10px', backgroundColor: '#9333ea', borderRadius: '50%', marginRight: '4px' }}></div>
                      <span>"{(intent2 as { Keyword: string }).Keyword}"</span>
                    </div>
                  )}
                  
                  {Object.keys(intent3 || {}).length > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: '10px', height: '10px', backgroundColor: '#16a34a', borderRadius: '50%', marginRight: '4px' }}></div>
                      <span>"{(intent3 as { Keyword: string }).Keyword}"</span>
                    </div>
                  )}
                </div>
              </div>
              
              <p className="mb-4">There is a lot of opportunity to direct more traffic and sales to your site. Plus, there are hundreds of additional high performing keywords which you're not yet ranking for.</p>
              <p className="mb-4">
                <div className="space-y-4 mt-4 mb-4" ref={emailChartRef}>
                  {payload.chartData && payload.chartData.length > 0 ? (
                    <StackedBarChart data={payload.chartData} />
                  ) : (
                    <div className="text-center p-4 text-gray-500">No chart data available</div>
                  )}
                </div> 
              </p>
              <p className="mb-4">If you're working with an agency at the moment or managing your marketing efforts inhouse, this will analyse how your campaigns are performing and we'll offer suggestions for improvement going forward.</p>
              <p className="mb-4"><strong>RELEVANT_CASE_STUDY</strong></p>
              <p className="mb-4">Keen to connect if you have some time later today, otherwise this / next week? Let me know if you have any questions.</p>
              <p className="mb-12">
                <a 
                  href="https://meetings.hubspot.com/omg-operations/sales-round-robin-meeting-link" 
                  style={{
                    backgroundColor: '#2563eb',
                    color: 'white',
                    padding: '8px 16px',
                    borderRadius: '6px',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    display: 'inline-block',
                    textAlign: 'center',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                  }}
                >
                  Book A Meeting
                </a>
              </p>  
     
              <p className="mb-6">&nbsp;</p> 
            </div>
            <p style={{marginTop: '8rem', display: 'block'}}>&nbsp;</p>
          </div>
        </div>
      </>
    );
  };

  const handleFetchEmailReport = (item, contact, position) => {
    setError('');
    setPayload(prevPayload => ({
        ...prevPayload,
        emailItem: item,
        showEmailModal: true,
        emailLoading: true
    }));

    console.log('Fetching email report for:', item, contact);
    console.log('Original search keyword from full_data:', (full_data as { keyword?: string })?.keyword);
    
    // Get the primary keyword from full_data and replace spaces with + symbols
    let primaryKeyword = (full_data as { keyword?: string })?.keyword || '';
    primaryKeyword = primaryKeyword.replace(/ /g, '+'); // Replace all spaces with + symbols
    
    fetchCompetitorData({ 
      domain: item.displayLink || contact.domain || '', 
      database: database,
      primaryKeyword: primaryKeyword,
      position: position
    })
    .then(competitors => {
        console.log('Raw API response:', competitors);
        
        if (competitors.success === false) {
            setError(competitors.message);
            setPayload(prevPayload => ({
                ...prevPayload,
                emailLoading: false
            }));
            return;
        }

        const originalKeyword = (full_data as { keyword?: string })?.keyword || '';
        console.log('Looking for original keyword:', originalKeyword);
        console.log('All keywords from API:', competitors.data.keywords.map(k => k.Keyword));
        
        const originalKeywordData = competitors.data.keywords.find(
            kw => kw.Keyword.toLowerCase() === originalKeyword.toLowerCase()
        );
        
        console.log("Original keyword:", originalKeyword);
        console.log("Found in results:", !!originalKeywordData);
        if (originalKeywordData) {
            console.log("Original keyword data:", originalKeywordData);
        } else {
            console.log("Original keyword not found in results");
        }
        
        // If found, move it to the front of the array for better visibility in dropdown
        let modifiedKeywords = [...competitors.data.keywords];
        if (originalKeywordData) {
            // Remove it from its current position
            modifiedKeywords = modifiedKeywords.filter(
                kw => kw.Keyword.toLowerCase() !== originalKeyword.toLowerCase()
            );
            // Add it to the front
            modifiedKeywords.unshift(originalKeywordData);
        }
        
        // Update the competitors data with the reordered keywords
        const modifiedCompetitors = {
            ...competitors.data,
            keywords: modifiedKeywords,
            originalKeyword: originalKeyword,
            originalKeywordFound: !!originalKeywordData // Flag to indicate if original keyword was found
        };

        const aggregatedData: { [key: string]: { [key: string]: number } } = {};

        competitors.data.keywordHistory.forEach(entry => {
            const { year, month, Position } = entry;
            const monthYear = `${year}-${month}`;

            let category;
            if (Position >= 1 && Position <= 3) {
                category = '1-3';
            } else if (Position >= 4 && Position <= 10) {
                category = '4-10';
            } else if (Position >= 11 && Position <= 20) {
                category = '11-20';
            } else if (Position >= 21 && Position <= 50) {
                category = '21-50';
            } else if (Position >= 51 && Position <= 100) {
                category = '51-100';
            } else {
                return; // Skip if position is out of range
            }

            if (!aggregatedData[monthYear]) {
                aggregatedData[monthYear] = {
                    '1-3': 0,
                    '4-10': 0,
                    '11-20': 0,
                    '21-50': 0,
                    '51-100': 0
                };
            }

            aggregatedData[monthYear][category] += 1;
        });

        const chartFormatter = Object.entries(aggregatedData).map(([monthYear, categories]) => ({
            name: monthYear,
            '1-3': categories['1-3'] || 0,
            '4-10': categories['4-10'] || 0,
            '11-20': categories['11-20'] || 0,
            '21-50': categories['21-50'] || 0,
            '51-100': categories['51-100'] || 0
        }));
                    
        const availableMonths = chartFormatter.map(item => item.name);            
        let foundFourtoten;
        
        // Check if the requested month is available
        if (availableMonths.includes(previousMonthYear)) {
            foundFourtoten = chartFormatter.find(item => item.name === previousMonthYear);
        } else {
            // If not found, get the latest available month
            foundFourtoten = chartFormatter[chartFormatter.length - 1]; // Get the last item in the array
        }

        setPayload(prevPayload => ({
            ...prevPayload,
            emailData: { 
                contact: contact, 
                competitors: modifiedCompetitors,
                originalKeyword: originalKeyword,
                originalKeywordFound: !!originalKeywordData
            },
            fourtoten: foundFourtoten,
            chartData: chartFormatter.reverse(),
            emailLoading: false, // Set loading to false after data is received
            // Pre-select the original keyword if found
            intent1: originalKeywordData || {}
        }));
    })
    .catch(err => {
        console.error("Error fetching competitor data:", err);
        setError('An error occurred while fetching data.');
        setPayload(prevPayload => ({
            ...prevPayload,
            emailLoading: false // Set loading to false on error
        }));
    });
  };

useEffect(() => {
    if (client_id == '') {
        setPayload(prevPayload => ({
            ...prevPayload,
            loading: true
        }));
    } else {
        fetchReports(payload)
        .then((response) => {
            console.log('::::: ', response)
            setPayload(prevPayload => ({
                ...prevPayload,
                data: response?.data?.data?.items ? response.data.data.items : [],
                full_data: response.data,
                database: response.data.region,
                loading: false
            }));
        })
        .catch(error => {
            console.error('Error fetching quotas:', error);
            setPayload(prevPayload => ({
                ...prevPayload,
                loading: false,
                error: 'Failed to load report'
            }));
        });
        
    }
}, []);

  // Filter data based on company status
  const getFilteredData = () => {
    // If no filters are selected, return all data
    const anyFilterSelected = Object.values(companyFilters).some(value => value);
    if (!anyFilterSelected) {
      return data;
    }
    
    return data.filter(item => {
      // Check if any contacts in this item match any of the selected filters
      return item.contacts.some(contact => {
        if (companyFilters.currentClient && contact.companyStatus === 'Current Client') {
          return true;
        }
        if (companyFilters.pastClient && contact.companyStatus === 'Past Client') {
          return true;
        }
        if (companyFilters.newProspect && contact.companyStatus === 'New Prospect') {
          return true;
        }
        return false;
      });
    });
  };

  // Use the filtered data in your rendering
  const filteredData = getFilteredData();

  // Add these states to track overall processing status
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingStatus, setProcessingStatus] = useState({});
  const [processedCount, setProcessedCount] = useState(0);
  const [keywordDataProcessed, setKeywordDataProcessed] = useState(false);

  // Function to process all contacts
  const processAllContacts = async () => {
    // Only run if we haven't processed the data yet
    if (keywordDataProcessed || !filteredData || filteredData.length === 0) return;
    
    console.log("Starting keyword calculation for all contacts");
    
    // Set overall processing state to true
    setIsProcessing(true);
    setProcessedCount(0);
    
    // Create a copy of the current status object
    const newStatus = { ...processingStatus };
    
    // Mark all contacts as loading
    filteredData.forEach(contact => {
      newStatus[contact.id] = { loading: true, data: null, error: null };
    });
    
    // Update the status state
    setProcessingStatus(newStatus);
    
    // Process each contact sequentially
    for (const [index, contact] of filteredData.entries()) {
      try {
        // Find the domain for this contact
        const item = { displayLink: contact.displayLink || '' };
        
        // Skip if no domain is available
        if (!item.displayLink) {
          newStatus[contact.id] = { 
            loading: false, 
            data: null, 
            error: 'No domain available' 
          };
          setProcessingStatus({ ...newStatus });
          setProcessedCount(index + 1);
          continue;
        }
        
        // Fetch competitor data
        const competitors = await fetchCompetitorData({ 
          domain: item.displayLink, 
          database: database 
        });
        
        if (competitors.success === false) {
          newStatus[contact.id] = { 
            loading: false, 
            data: null, 
            error: competitors.message 
          };
          setProcessingStatus({ ...newStatus });
          setProcessedCount(index + 1);
          continue;
        }
        
        // Process the data as before
        const aggregatedData = {};
        
        competitors.data.keywordHistory.forEach(entry => {
          const { year, month, Position } = entry;
          const monthYear = `${year}-${month}`;
          
          let category;
          if (Position >= 1 && Position <= 3) {
            category = '1-3';
          } else if (Position >= 4 && Position <= 10) {
            category = '4-10';
          } else if (Position >= 11 && Position <= 20) {
            category = '11-20';
          } else if (Position >= 21 && Position <= 50) {
            category = '21-50';
          } else if (Position >= 51 && Position <= 100) {
            category = '51-100';
          } else {
            return; // Skip if position is out of range
          }
          
          if (!aggregatedData[monthYear]) {
            aggregatedData[monthYear] = {
              '1-3': 0,
              '4-10': 0,
              '11-20': 0,
              '21-50': 0,
              '51-100': 0
            };
          }
          
          aggregatedData[monthYear][category] += 1;
        });
        
        const chartFormatter = Object.entries(aggregatedData).map(([monthYear, categories]) => ({
          name: monthYear,
          '1-3': categories['1-3'] || 0,
          '4-10': categories['4-10'] || 0,
          '11-20': categories['11-20'] || 0,
          '21-50': categories['21-50'] || 0,
          '51-100': categories['51-100'] || 0
        }));
        
        const availableMonths = chartFormatter.map(item => item.name);
        let foundFourtoten;
        
        // Check if the requested month is available
        if (availableMonths.includes(previousMonthYear)) {
          foundFourtoten = chartFormatter.find(item => item.name === previousMonthYear);
        } else {
          // If not found, get the latest available month
          foundFourtoten = chartFormatter[chartFormatter.length - 1]; // Get the last item in the array
        }
        
        // Store the processed data for this contact
        newStatus[contact.id] = {
          loading: false,
          data: {
            competitors: competitors.data,
            fourtoten: foundFourtoten,
            chartData: chartFormatter.reverse()
          },
          error: null
        };
        
        // Update the status state after each contact is processed
        setProcessingStatus({ ...newStatus });
        setProcessedCount(index + 1);
        
      } catch (err) {
        console.error("Error processing contact:", err);
        // Handle errors for this specific contact
        newStatus[contact.id] = { 
          loading: false, 
          data: null, 
          error: 'An error occurred while processing this contact' 
        };
        setProcessingStatus({ ...newStatus });
        setProcessedCount(index + 1);
      }
    }
    
    // Set overall processing state to false when complete
    setIsProcessing(false);
    setKeywordDataProcessed(true);
    console.log("Keyword calculation completed for all contacts");
  };

  // Trigger processing only once when the component mounts and filteredData is available
  useEffect(() => {
    if (filteredData && filteredData.length > 0 && !keywordDataProcessed) {
      console.log("Table data loaded, starting keyword calculation");
      processAllContacts();
    }
  }, [filteredData]);

  // Apply initial filters when component mounts and tableData is available
  useEffect(() => {
    if (data && companyFilters) {
      applyStatusFilters(companyFilters);
    }
  }, [data, companyFilters]);

  // Add this debugging output
  useEffect(() => {
    console.log("Status filtered data updated:", statusFilteredData?.length);
  }, [statusFilteredData]);

  useEffect(() => {
    console.log("Company filters updated:", companyFilters);
  }, [companyFilters]);

  return (
    <div className="p-4 kpis">
      <h1 className="lg:text-[22px] hidden md:block font-bold font-inter text-[#001C44] dark:text-white whitespace-nowrap mb-2">
        Prospects Found for <span style={{color:'blue'}}>"{(full_data as { keyword?: string })?.keyword}"</span>
      </h1>
      {success ? <div className="mb-2 tools-submission-success">{success}</div> : null}
      {error ? <div className="mb-2 tools-submission-danger">{error}</div> : null}
      {!loading && data.length ? 
        <>
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 mb-6">
          {/* Tabs with improved styling */}
          <div className="flex mb-4 border-b border-gray-200 dark:border-gray-700">
            <button
              className={`py-2 px-4 font-medium text-sm focus:outline-none ${
                activeTab === 'potentialLeads' 
                  ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400' 
                  : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300'
              }`}
              onClick={() => setActiveTab('potentialLeads')}
            >
              Potential Leads
            </button>
            <button
              className={`py-2 px-4 font-medium text-sm focus:outline-none ${
                activeTab === 'competitors' 
                  ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400' 
                  : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300'
              }`}
              onClick={() => setActiveTab('competitors')}
            >
              Top 30 Competitors
            </button>
          </div>
          
          <div className="space-y-4">
            {/* Top row with filters and contact summary */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
              {/* Checkbox Filters Column */}
              <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-3 w-full md:col-span-1">
                <h3 className="font-medium text-gray-800 dark:text-white mb-2 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                  </svg>
                  Client Status
                </h3>
                <div className="flex flex-col space-y-2">
                  <label className="inline-flex items-center bg-white dark:bg-gray-800 px-3 py-2 rounded-md shadow-sm">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-blue-600 rounded"
                      checked={companyFilters.currentClient}
                      onChange={() => {
                        const newFilters = { 
                          ...companyFilters, 
                          currentClient: !companyFilters.currentClient 
                        };
                        setCompanyFilters(newFilters);
                        applyStatusFilters(newFilters);
                      }}
                    />
                    <span className="ml-2 text-sm text-gray-700 dark:text-gray-300">Current Clients</span>
                  </label>
                  <label className="inline-flex items-center bg-white dark:bg-gray-800 px-3 py-2 rounded-md shadow-sm">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-blue-600 rounded"
                      checked={companyFilters.pastClient}
                      onChange={() => {
                        const newFilters = { 
                          ...companyFilters, 
                          pastClient: !companyFilters.pastClient 
                        };
                        setCompanyFilters(newFilters);
                        applyStatusFilters(newFilters);
                      }}
                    />
                    <span className="ml-2 text-sm text-gray-700 dark:text-gray-300">Past Clients</span>
                  </label>
                  <label className="inline-flex items-center bg-white dark:bg-gray-800 px-3 py-2 rounded-md shadow-sm">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-blue-600 rounded"
                      checked={companyFilters.newProspect}
                      onChange={() => {
                        const newFilters = { 
                          ...companyFilters, 
                          newProspect: !companyFilters.newProspect 
                        };
                        setCompanyFilters(newFilters);
                        applyStatusFilters(newFilters);
                      }}
                    />
                    <span className="ml-2 text-sm text-gray-700 dark:text-gray-300">New Prospects</span>
                  </label>
                </div>
              </div>
              
              {/* Contact Status Legend */}
              <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-3 w-full md:col-span-1">
                <h3 className="font-medium text-gray-800 dark:text-white mb-2 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Contact Status Legend
                </h3>
                <div className="grid grid-cols-2 gap-2">
                  <div 
                    className={`flex items-center p-1 rounded cursor-pointer transition-all ${!statusFlags.inCRM ? 'opacity-50 bg-gray-200 dark:bg-gray-600' : 'hover:bg-gray-100 dark:hover:bg-gray-600'}`}
                    onClick={() => setStatusFlags(prev => ({ ...prev, inCRM: !prev.inCRM }))}
                  >
                    <div className="h-3 w-3 rounded-full bg-orange-500 mr-2"></div>
                    <span className="text-sm text-gray-700 dark:text-gray-300">In HubSpot CRM</span>
                  </div>
                  <div 
                    className={`flex items-center p-1 rounded cursor-pointer transition-all ${!statusFlags.notInCRM ? 'opacity-50 bg-gray-200 dark:bg-gray-600' : 'hover:bg-gray-100 dark:hover:bg-gray-600'}`}
                    onClick={() => setStatusFlags(prev => ({ ...prev, notInCRM: !prev.notInCRM }))}
                  >
                    <div className="h-3 w-3 rounded-full bg-gray-300 dark:bg-gray-500 mr-2"></div>
                    <span className="text-sm text-gray-700 dark:text-gray-300">Not in CRM</span>
                  </div>
                  <div 
                    className={`flex items-center p-1 rounded cursor-pointer transition-all ${!statusFlags.qualifiedLead ? 'opacity-50 bg-gray-200 dark:bg-gray-600' : 'hover:bg-gray-100 dark:hover:bg-gray-600'}`}
                    onClick={() => setStatusFlags(prev => ({ ...prev, qualifiedLead: !prev.qualifiedLead }))}
                  >
                    <div className="h-3 w-3 rounded-full bg-green-500 mr-2"></div>
                    <span className="text-sm text-gray-700 dark:text-gray-300">Qualified Lead</span>
                  </div>
                  <div 
                    className={`flex items-center p-1 rounded cursor-pointer transition-all ${!statusFlags.newLead ? 'opacity-50 bg-gray-200 dark:bg-gray-600' : 'hover:bg-gray-100 dark:hover:bg-gray-600'}`}
                    onClick={() => setStatusFlags(prev => ({ ...prev, newLead: !prev.newLead }))}
                  >
                    <div className="h-3 w-3 rounded-full bg-yellow-500 mr-2"></div>
                    <span className="text-sm text-gray-700 dark:text-gray-300">New Lead</span>
                  </div>
                  <div 
                    className={`flex items-center p-1 rounded cursor-pointer transition-all ${!statusFlags.otherStatus ? 'opacity-50 bg-gray-200 dark:bg-gray-600' : 'hover:bg-gray-100 dark:hover:bg-gray-600'}`}
                    onClick={() => setStatusFlags(prev => ({ ...prev, otherStatus: !prev.otherStatus }))}
                  >
                    <div className="h-3 w-3 rounded-full bg-purple-500 mr-2"></div>
                    <span className="text-sm text-gray-700 dark:text-gray-300">Other Status</span>
                  </div>
                  <div 
                    className={`flex items-center p-1 rounded cursor-pointer transition-all ${!statusFlags.hasActiveDeal ? 'opacity-50 bg-gray-200 dark:bg-gray-600' : 'hover:bg-gray-100 dark:hover:bg-gray-600'}`}
                    onClick={() => setStatusFlags(prev => ({ ...prev, hasActiveDeal: !prev.hasActiveDeal }))}
                  >
                    <div className="h-3 w-3 rounded-full bg-red-500 mr-2"></div>
                    <span className="text-sm text-gray-700 dark:text-gray-300">Has Active Deal</span>
                  </div>
                  <div 
                    className={`flex items-center p-1 rounded cursor-pointer transition-all ${!statusFlags.doNotContact ? 'opacity-50 bg-gray-200 dark:bg-gray-600' : 'hover:bg-gray-100 dark:hover:bg-gray-600'}`}
                    onClick={() => setStatusFlags(prev => ({ ...prev, doNotContact: !prev.doNotContact }))}
                  >
                    <div className="h-3 w-3 rounded-full bg-black mr-2"></div>
                    <span className="text-sm text-gray-700 dark:text-gray-300">Do Not Contact</span>
                  </div>
                </div>
                <div className="mt-2 text-xs text-gray-500">
                  Click on a status to toggle visibility
                </div>
              </div>
              
              {/* Selected Contacts Summary */}
              <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-3 w-full md:col-span-1" style={{display: 'none'}}>
                <h3 className="font-medium text-gray-800 dark:text-white mb-2 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                  Selected Contacts
                </h3>
                <div className="text-center">
                  <div className="text-2xl font-bold text-blue-600 dark:text-blue-400">
                    {selectedContacts?.length || 0}
                  </div>
                  <div className="text-sm text-gray-600 dark:text-gray-400">
                    contacts selected
                  </div>
                  {selectedContacts?.length > 0 && (
                    <div className="mt-2 space-y-2">
                      <button 
                        className="w-full bg-blue-600 hover:bg-blue-700 text-white py-1 px-3 rounded-md text-sm font-medium transition-colors duration-150 flex items-center justify-center"
                        onClick={() => {/* Handle send to HubSpot action */}}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                        </svg>
                        Send Email to Prospects
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            
            {/* Remove the bottom row with status legend since we moved it up */}
          </div>
        </div>
        {activeTab === 'competitors' ? (
            <>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 font-inter">
            <thead>
                <tr>
                <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                    SERP Position
                </th>
                <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                    Page Title
                </th>
                <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                    Domain
                </th>
                <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                    Contacts Found
                </th>
                <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                    Hubspot Found
                </th>
                <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                    Full URL
                </th>
                </tr>
            </thead>
                <tbody>
                {statusFilteredData && statusFilteredData.length > 0 ? (
                  statusFilteredData.map((contact, index) => (
                    <tr key={index} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                      <td className='z-10  p-2 min-w-fit'>{index + 1}</td>
                      <td className='z-10  p-2 min-w-fit'>{contact.title}</td>
                      <td className='z-10  p-2 min-w-fit'>{contact.displayLink}</td>
                      <td className='z-10 p-2' style={{ minWidth: '300px', width: '300px' }}>
                        <div className="max-h-40 overflow-y-auto">
                          {contact.contacts.length > 0 ? (
                            contact.contacts.map((contact, idx) => (
                              <div key={idx} className="flex items-start mb-1 text-sm">
                                <input 
                                  type="checkbox" 
                                  style={{display: 'none'}}
                                  className="h-4 w-4 mr-2 mt-1 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedContacts(prev => [...prev, contact]);
                                    } else {
                                      setSelectedContacts(prev => prev.filter(c => c.email !== contact.email));
                                    }
                                  }}
                                  checked={selectedContacts.some(c => c.email === contact.email)}
                                />
                                <div className="relative group flex-1">
                                  <div className="flex justify-between items-center w-full">
                                    <span 
                                      className="text-blue-600 hover:text-blue-800 cursor-pointer truncate mr-2"
                                      style={{ maxWidth: '180px' }}
                                      onMouseEnter={(e) => {
                                        const rect = e.currentTarget.getBoundingClientRect();
                                        setTooltipPosition({
                                          top: rect.top - 10,
                                          left: rect.left
                                        });
                                        setActiveTooltip(contact);
                                      }}
                                      onMouseLeave={() => {
                                        setActiveTooltip(null);
                                      }}
                                      onClick={() => {
                                        // Open the email generator with the current item and this specific contact
                                        handleFetchEmailReport(
                                          { ...contact.item, displayLink: contact.item?.displayLink || contact.domain || '' }, 
                                          contact,
                                          (index + 1)
                                        );
                                        setPayload(prevPayload => ({
                                          ...prevPayload,
                                          showEmailGenerator: true,
                                          emailItem: { ...contact.item, displayLink: contact.item?.displayLink || contact.domain || '' }
                                        }));
                                      }}
                                      title={contact.email}
                                    >
                                      {contact.email}
                                    </span>
                                    
                                    {/* Right-aligned status indicators */}
                                    <div className="flex space-x-1 flex-shrink-0">
                                      {/* CRM Status */}
                                      <div 
                                        className={`h-3 w-3 rounded-full ${
                                          contact.isInCRM 
                                            ? 'bg-orange-500' 
                                            : 'bg-gray-300'
                                        }`}
                                        title={contact.isInCRM ? "In HubSpot CRM" : "Not in HubSpot CRM"}
                                      ></div>
                                      
                                      {/* Lead Status */}
                                      {contact.leadStatus && (
                                        <div 
                                          className={`h-3 w-3 rounded-full ${
                                            contact.leadStatus === 'Qualified' ? 'bg-green-500' :
                                            contact.leadStatus === 'New' ? 'bg-yellow-500' :
                                            contact.leadStatus === 'DoNotContact' ? 'bg-black' :
                                            'bg-purple-500'
                                          }`}
                                            title={`Lead Status: ${contact.leadStatus}`}
                                        ></div>
                                      )}
                                      
                                      {/* Has Deal */}
                                      {contact.hasDeal && (
                                        <div 
                                          className="h-3 w-3 rounded-full bg-red-500"
                                          title="Has Active Deal"
                                        ></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <span className="text-gray-500">No contacts found</span>
                          )}
                        </div>
                      </td>
                      <td className='z-10  p-2 min-w-fit'>{
                      contact.contacts?.reduce((count, contact) => {
                          return count + (contact.isInCRM?.isInCRM ? 1 : 0)
                      }, 0)
                      }
                      </td>
                      <td className='z-10  p-2 min-w-fit'><a style={{color: '#005C9F'}} href={contact.link} target="_blank">{contact.link}</a></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center py-4 text-gray-500">
                      No contacts match the selected filters
                    </td>
                  </tr>
                )}
                </tbody>
            </table>
            </>
            ) : (
            <>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 font-inter">
            <thead>
                  <tr>
                  <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                        SERP Position
                    </th>
                    <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                        Page Title
                    </th>
                    <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                        Domain
                    </th>
                    <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                        Company Details
                    </th>
                    <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                        Contacts Found
                    </th>
                </tr>
            </thead>
            <tbody>
                {statusFilteredData && statusFilteredData.length > 0 ? (
                  statusFilteredData.map((contact, index) => (
                    <tr key={index} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                      <td className='z-10  p-2 min-w-fit'>{index + 30}</td>
                      <td className='z-10  p-2 min-w-fit'>{contact.title}</td>
                      <td className='z-10  p-2 min-w-fit'>{contact.displayLink}</td>
                      <td className='z-10  p-2 min-w-fit'>{contact.companyStatus}
                      </td>
                      <td className='z-10 p-2' style={{ minWidth: '200px', width: '350px' }}>
                        <div className="max-h-40 overflow-y-auto">
                          {contact.contacts.length > 0 ? (
                            contact.contacts.map((cont, idx) => (
                              <div key={idx} className="flex items-start mb-1 text-sm">
                                <input 
                                  type="checkbox" 
                                  style={{display: 'none'}}
                                  className="h-4 w-4 mr-2 mt-1 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedContacts(prev => [...prev, cont]);
                                    } else {
                                      setSelectedContacts(prev => prev.filter(c => c.email !== cont.email));
                                    }
                                  }}
                                  checked={selectedContacts.some(c => c.email === cont.email)}
                                />
                                <div className="relative group flex-1">
                                  <div className="flex justify-between items-center w-full">
                                    <span 
                                      className="text-blue-600 hover:text-blue-800 cursor-pointer truncate mr-2"
                                      style={{ maxWidth: '180px' }}
                                      onMouseEnter={(e) => {
                                        const rect = e.currentTarget.getBoundingClientRect();
                                        setTooltipPosition({
                                          top: rect.top - 10,
                                          left: rect.left
                                        });
                                        setActiveTooltip(cont);
                                      }}
                                      onMouseLeave={() => {
                                        setActiveTooltip(null);
                                      }}
                                      onClick={() => {
                                        // Open the email generator with the current item and this specific contact
                                        handleFetchEmailReport(
                                          { ...contact.item, displayLink: contact.displayLink || contact.domain || '' }, 
                                          cont,
                                          (index + 30)
                                        );
                                        setPayload(prevPayload => ({
                                          ...prevPayload,
                                          showEmailGenerator: true,
                                          emailItem: { ...contact.item, displayLink: contact.displayLink || contact.domain || '' }
                                        }));
                                      }}
                                      title={cont.email}
                                    >
                                      {cont.email}
                                    </span>
                                    
                                    {/* Right-aligned status indicators */}
                                    <div className="flex space-x-1 flex-shrink-0">
                                      {/* CRM Status */}
                                      <div 
                                        className={`h-3 w-3 rounded-full ${
                                          cont.isInCRM 
                                            ? 'bg-orange-500' 
                                            : 'bg-gray-300'
                                        }`}
                                        title={cont.isInCRM ? "In HubSpot CRM" : "Not in HubSpot CRM"}
                                      ></div>
                                      
                                      {/* Lead Status */}
                                      {cont.leadStatus && (
                                        <div 
                                          className={`h-3 w-3 rounded-full ${
                                            cont.leadStatus === 'Qualified' ? 'bg-green-500' :
                                            cont.leadStatus === 'New' ? 'bg-yellow-500' :
                                            cont.leadStatus === 'DoNotContact' ? 'bg-black' :
                                            'bg-purple-500'
                                          }`}
                                            title={`Lead Status: ${cont.leadStatus}`}
                                        ></div>
                                      )}
                                      
                                      {/* Has Deal */}
                                      {cont.hasDeal && (
                                        <div 
                                          className="h-3 w-3 rounded-full bg-red-500"
                                          title="Has Active Deal"
                                        ></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <span className="text-gray-500">No contacts found</span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center py-4 text-gray-500">
                      No contacts match the selected filters
                    </td>
                  </tr>
                )}
                </tbody>
            </table>
            </>
        )}
        </>
      : 
        <div className="flex items-center p-4">
            <div className="ml-4 text-sm text-red-500">No Report for the ID provided.</div>
        </div>
      }
      {emailItem ? 
        <EmailModal item={emailItem} onClose={() => setPayload(prevPayload => ({
          ...prevPayload,
          emailItem: null,
          showEmailModal: false,
          intent1: {},
          intent2: {},
          intent3: {}, 
        }))} /> 
      : null}
      {activeTooltip && ReactDOM.createPortal(
        <div 
          className="bg-white dark:bg-gray-800 p-3 rounded shadow-lg border border-gray-200 dark:border-gray-700"
          style={{
            position: 'fixed',
            top: `${tooltipPosition.top}px`,
            left: `${tooltipPosition.left}px`,
            zIndex: 10000,
            transform: 'translateY(-100%)',
            width: '250px'
          }}
        >
          <div className="grid grid-cols-2 gap-1 text-xs">
            <div className="font-semibold">Name:</div>
            <div>{activeTooltip.firstName && activeTooltip.lastName ? `${activeTooltip.firstName} ${activeTooltip.lastName}` : 'N/A'}</div>
            
            <div className="font-semibold">Position:</div>
            <div>{activeTooltip.position || 'N/A'}</div>
            
            <div className="font-semibold">In CRM:</div>
            <div>
              <span className={`px-1 py-0.5 rounded-full ${
                activeTooltip.isInCRM 
                  ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100' 
                  : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300'
              }`}>
                {activeTooltip.isInCRM ? 'Yes' : 'No'}
              </span>
            </div>
            
            <div className="font-semibold">Status:</div>
            <div>{activeTooltip.status || 'N/A'}</div>
            
            <div className="font-semibold">Type:</div>
            <div className={`${
              activeTooltip.contactType && 
              (activeTooltip.contactType.toLowerCase().includes('do not contact') || 
               activeTooltip.contactType.toLowerCase().includes('do not call')) 
                ? 'text-red-600 font-medium' 
                : ''
            }`}>
              {activeTooltip.contactType || 'N/A'}
            </div>
            
            <div className="font-semibold">Lifecycle:</div>
            <div>{activeTooltip.lifecycleStage || 'N/A'}</div>
            
            {activeTooltip.hasDeal && (
              <>
                <div className="font-semibold">Deal:</div>
                <div className="text-orange-600 font-medium">Active</div>
              </>
            )}
          </div>
          
          {/* Arrow pointer */}
          <div 
            className="absolute w-3 h-3 bg-white dark:bg-gray-800 transform rotate-45"
            style={{
              bottom: '-6px',
              left: '10px',
              borderRight: '1px solid #e2e8f0',
              borderBottom: '1px solid #e2e8f0'
            }}
          ></div>
        </div>,
        document.body
      )}
      {/* Processing overlay */}
      {isProcessing && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full shadow-xl">
            <div className="flex flex-col items-center">
              <svg className="animate-spin h-10 w-10 text-blue-500 mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">Calculating Keywords</h3>
              <p className="text-gray-600 dark:text-gray-300 text-center mb-4">
                Processing contacts and analyzing keyword data...
              </p>
              <div className="w-full bg-gray-200 rounded-full h-2.5 mb-2">
                <div 
                  className="bg-blue-600 h-2.5 rounded-full transition-all duration-300" 
                  style={{ width: `${(processedCount / (filteredData?.length || 1)) * 100}%` }}
                ></div>
              </div>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {processedCount} of {filteredData?.length || 0} contacts processed
              </p>
            </div>
          </div>
        </div>
      )}
      {/* Debugging output */}
      <div className="text-xs text-gray-500 mb-2">
        Debug: Filters: {JSON.stringify(companyFilters)} | 
        Table data: {data?.length || 0} | 
        Filtered data: {statusFilteredData?.length || 0}
      </div>
    </div>
  );
};

export default ContactBuilder;


