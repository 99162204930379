import { useEffect, useState } from 'react';
import {
  Grid,
  Col,
  Tab,
  TabGroup,
  TabList,
  TabPanels,
  TabPanel,
} from '@tremor/react';
import ReactSelect from '../../ReactSelect';
import usePerformanceStore from '../../../store/usePerformanceStore';
import { getServiceLabel, formatNumber } from '../../../utils/common';
import moment from 'moment';
import DatePicker from 'react-datepicker';

const INITIAL_LIMIT = 5;
const INCREMENT = 5;

const TopAndUnderServicePerformance = () => {
  const { fetchInsightsOverall, insightsOverall } = usePerformanceStore(
    (state) => state
  );
  const [topAndUnderPerformance, setTopAndUnderPerformance] = useState({
    label: 'Google SEO',
    value: 'seo_google',
  });
  const [limit, setLimit] = useState(INITIAL_LIMIT);
  const [loading, setLoading] = useState(false);

  const lastMonth = moment().subtract(1, 'month').startOf('month').toDate();
  const maxDate = moment(lastMonth, 'MM-yyyy').endOf('month').toDate();
  const [topPerformanceDate, setTopPerformanceDate] = useState(lastMonth);

  useEffect(() => {
    handleFetchInsightsOverall();
  }, [topAndUnderPerformance, topPerformanceDate]);

  const handleFetchInsightsOverall = async () => {
    setLoading(true);
    const payload = {
      report_date: moment(topPerformanceDate).format('MM-YYYY'),
      service: topAndUnderPerformance?.value,
      limit: 10,
    };
    await fetchInsightsOverall(payload);
    setLoading(false);
  };

  const handleShowMore = () => setLimit((prevLimit) => prevLimit + INCREMENT);
  const handleShowLess = () => setLimit(INITIAL_LIMIT);

  const leadGenTopData = insightsOverall?.['Lead Gen']?.top || [];
  const leadGenUnderPerformanceData = insightsOverall?.['Lead Gen']?.low || [];
  const ecommerceTopData = insightsOverall?.['Ecommerce']?.top || [];
  const ecommerceUnderPerformanceData = insightsOverall?.['Ecommerce']?.low || [];

  return (
    <div>
      <div className="flex justify-between items-center border-y py-6">
        <div className="font-bold text-[18px] text-[#001C44] dark:text-gray-300">
          Overall Performance
        </div>
        <div className="flex gap-4 w-fit">
          <div className="w-[200px]">
            <DatePicker
              selected={moment(topPerformanceDate, 'YYYY-MM').toDate()}
              onChange={(date) => date && setTopPerformanceDate(date)}
              dateFormat="MMM yyyy"
              maxDate={maxDate}
              showMonthYearPicker
              className="react-datepicker__month-container text-center h-[38px] border border-[#808EA2] cursor-pointer dark:bg-[#374151] dark:text-white font-inter rounded-lg text-[14px] text-[#525252]"
            />
          </div>
          <div className="w-[150px]">
            <ReactSelect
              value={topAndUnderPerformance}
              options={[
                { value: 'seo_google', label: 'Google SEO' },
                { value: 'ppc_google', label: 'Google Ads' },
                { value: 'social_meta', label: 'Meta' },
              ]}
              handleOnChange={(selectedOption: any) => setTopAndUnderPerformance(selectedOption)}
              placeholder="All Services"
            />
          </div>
        </div>
      </div>

      <div className="dark:bg-[#222732] p-4 rounded-lg mt-2">
        <TabGroup>
          <TabList variant="line">
            <Tab value="1">Lead Gen</Tab>
            <Tab value="2">Ecommerce</Tab>
          </TabList>

          <TabPanels>
            {['Lead Gen', 'Ecommerce'].map((category, index) => {
              const topData = category === 'Lead Gen' ? leadGenTopData : ecommerceTopData;
              const underData = category === 'Lead Gen' ? leadGenUnderPerformanceData : ecommerceUnderPerformanceData;

              const canShowMore = limit < topData.length || limit < underData.length;
              const canShowLess = limit > INITIAL_LIMIT;

              return (
                <TabPanel key={index}>
                  {loading ? (
                    <div className="flex justify-center items-center h-[300px]">
                      <div className="loader border-t-4 border-blue-500 rounded-full w-10 h-10 animate-spin"></div>
                    </div>
                  ) : (
                    <Grid numItems={2} className="gap-8">
                      <Col>
                        <div className="font-bold text-[18px] dark:text-white my-4">
                          Top Performance
                        </div>
                        {topData.length > 0 ? (
                          topData.slice(0, limit).map((data, i) => (
                            <div
                              key={i}
                              className="border-b flex w-full justify-between items-center animate-fade-in transition-all duration-300"
                            >
                              <div className="w-[30vw] py-2">
                                <p className="font-semibold text-sm dark:text-white truncate" title={data?.client?.name}>
                                  {data?.client?.name}
                                </p>
                                <p className="font-light text-[#6B7280] text-sm">
                                  {getServiceLabel(data.service)?.label}
                                </p>
                              </div>
                              <div className={data.performance?.score > 50 ? 'text-[#3CCB7F] text-sm' : 'text-[#FF0000] text-sm'}>
                                {formatNumber(data.performance?.score)}%
                              </div>
                              <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                                {data.category_type}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="w-full flex justify-center items-center text-gray-500 h-[300px]">
                            <div>No data</div>
                          </div>
                        )}
                      </Col>

                      <Col>
                        <div className="font-bold text-[18px] dark:text-white my-4">
                          Under Performance
                        </div>
                        {underData.length > 0 ? (
                          underData.slice(0, limit).map((data, i) => (
                            <div
                              key={i}
                              className="border-b flex w-full justify-between items-center animate-fade-in transition-all duration-300"
                            >
                              <div className="w-[30vw] py-2">
                                <p className="font-semibold text-sm dark:text-white truncate" title={data?.client?.name}>
                                  {data?.client?.name}
                                </p>
                                <p className="font-light text-[#6B7280] text-sm">
                                  {getServiceLabel(data.service)?.label}
                                </p>
                              </div>
                              <div className={data.performance?.score > 50 ? 'text-[#3CCB7F] text-sm' : 'text-[#FF0000] text-sm'}>
                                {formatNumber(data.performance?.score)}%
                              </div>
                              <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                                {data.category_type}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="w-full flex justify-center items-center text-gray-500 h-[300px]">
                            <div>No data</div>
                          </div>
                        )}
                      </Col>

                      <Col numColSpan={2} className="flex justify-center mt-4">
                        {limit > INITIAL_LIMIT ? (
                          <button
                            onClick={handleShowLess}
                            className="text-gray-900 bg-gray-200 border border-gray-300 rounded-lg text-sm px-5 py-2.5"
                          >
                            Show Less
                          </button>
                        ) : (
                          <button
                            onClick={handleShowMore}
                            className="text-gray-900 bg-white border border-gray-300 rounded-lg text-sm px-5 py-2.5"
                          >
                            Show More
                          </button>
                        )}
                      </Col>

                    </Grid>
                  )}
                </TabPanel>
              );
            })}
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  );
};

export default TopAndUnderServicePerformance;