import moment from 'moment';
import Icon from '../../assets/icons/SvgComponent';
import Tooltip from '../../components/HelpTooltip';
import ImageIcon from '../../components/ImageIcon';

// Define the props for the generateColumns function
interface GenerateColumnsProps {
  sortingColumn: any;
  sortingDirection: any;
  navigate: any;
  generateServiceImage: any;
  handleSortTable: any;
  isMonthly?: boolean;
  selectedWeek?: any;
}

// Function to generate columns
export const generateColumns = ({
  sortingColumn,
  sortingDirection,
  navigate,
  handleSortTable,
  generateServiceImage,
  isMonthly,
  selectedWeek
}: GenerateColumnsProps) => [
  // name column
  {
    id: 'report_date',
    header: () => (
      <div className="w-[300px] sm:w-[25vw] h-[55px] flex items-center">
        <div
          className="flex w-[300px] sm:w-[25vw] justify-start items-center gap-2 cursor-pointer"
          onClick={() => handleSortTable('report_date', 'asc')}
        >
          <div className="text-left text-sm font-inter w-fit">Subject</div>
          <div>
            {sortingColumn === 'report_date' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    cell: (cell: any) => {
      return (
        <div
          className="w-[300px] sm:w-[25vw] flex justify-start items-center cursor-pointer h-[55px]"
        >
          <div className="text-xs sm:text-sm max-w-[300px] sm:max-w-[25vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF] overflow-hidden line-clamp-2">
            {isMonthly ? 'Your Monthly' : 'Your Weekly'}{' '}
            {generateServiceImage(cell?.row?.original?.report_type).label}{' '}
            Report |{' '}
            {isMonthly ? moment(cell?.row?.original?.report_date).format('MMM DD, YYYY') : selectedWeek?.label} 
          </div>
        </div>
      );
    },
  },
  {
    id: 'domain',
    header: () => (
      <div className="w-[200px] sm:w-[25vw] h-[55px] flex items-center px-4">
        <div
          className="flex w-[200px] sm:w-[25vw] justify-center items-center gap-2 cursor-pointer"
          onClick={() => handleSortTable('domain', 'asc')}
        >
          <div className="text-left text-sm font-inter w-fit">Domain</div>
          <div>
            {sortingColumn === 'domain' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'domain',
    cell: (props: any) => {
      return (
        <div className="w-[200px] sm:w-[25vw] flex justify-center items-center cursor-pointer px-4 h-[55px]">
          <div className="text-sm max-w-[200px] sm:max-w-[25vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF] text-center">
            {props.getValue()}
          </div>
        </div>
      );
    },
  },
  {
    id: 'Account Manager',
    header: () => (
      <div className="w-[180px] sm:w-[25vw] h-[55px] flex items-center px-4">
        <div
          className="flex w-[180px] sm:w-[25vw] justify-center items-center gap-2 cursor-pointer"
          onClick={() => handleSortTable('account_manager', 'asc')}
        >
          <div className="text-left text-sm font-inter w-fit">
            Account Manager
          </div>
          <div>
            {sortingColumn === 'account_manager' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'account_manager',
    cell: (props: any) => {
      return (
        <div className="w-[180px] sm:w-[25vw] flex justify-center items-center cursor-pointer px-4 h-[55px]">
          <Tooltip content={props?.getValue()} position="right">
            <div className="text-sm max-w-[180px] sm:max-w-[25vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF] overflow-hidden line-clamp-2">
              <ImageIcon data={props?.getValue()} size={9} textSize={'xs'} />
            </div>
          </Tooltip>
        </div>
      );
    },
  },
  {
    id: 'Action',
    header: () => <></>,
    accessorKey: 'id',
    cell: (cell: any) => {
      return (
        <div className="w-[150px] sm:w-[12vw] flex justify-center items-center cursor-pointer px-4 h-[55px]">
          <div
            className="text-sm max-w-[150px] sm:max-w-[12vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF] overflow-hidden line-clamp-2"
            onClick={() =>
              isMonthly ? 
              navigate(
                `/reports/details/monthly/${cell?.row?.original?.client_id?.id}`,
                {
                  state: cell?.row?.original,
                }
              ) :  navigate(
                `/reports/details/weekly/${cell?.row?.original?.client_id?.id}`,
                {
                  state: {
                    ...cell?.row?.original,
                    selectedWeek: selectedWeek,
                  },
                }
              )
            }
          >
            Read more
          </div>
        </div>
      );
    },
  },
];
